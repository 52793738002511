import React, {Fragment} from "react";
import {TableCell, Typography, makeStyles, CircularProgress} from "@material-ui/core";
import {ReactComponent, EntityRendererProps, inject} from "js-react-components";
import {ProductQuantityAlteration, wrap} from "../../../domain/product-quantity-alteration";
import moment from "moment";
import AsyncEntityLoader from "../../auxiliary/async-entity-loader/async-entity-loader.component";
import servicesContext from "../../../contexts/services.context";
import {typesToText} from "./auxiliary";

const useStyles = makeStyles(theme => ({
    load: {
        color: "green"
    },
    unload: {
        color: "red"
    }
}));

/**
 * @type {ReactComponent<EntityRendererProps<ProductQuantityAlteration>>} 
 */
const TableRenderer = ({element}) => {
    const classes = useStyles();
    const isUnload = wrap(element).isUnload();
    return <Fragment>
        <TableCell key="product">
            <AsyncEntityLoader
                id={1}
                onGet={k => Promise.resolve(element.product)}
                Renderer={({entity}) => <Typography component="span" variant="body1">{entity ? entity.name : "?"}</Typography>}
                LoadingComponent={CircularProgress}
            />
        </TableCell>
        <TableCell key="type">
            <Typography component="span" variant="body1" className={isUnload ? classes.unload : classes.load}>
                {typesToText[element.type.toLowerCase()]}
            </Typography>
        </TableCell>
        <TableCell key="qty">
            <Typography component="span" variant="body1" className={isUnload ? classes.unload : classes.load}>
                {(isUnload ? "-" : "+") + element.quantity}
            </Typography>
        </TableCell>
        <TableCell key="date">
            {moment(element.date).format("DD-MM-YYYY")}
        </TableCell>
    </Fragment>;
};

export default TableRenderer;