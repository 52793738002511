import {createContext} from "react";
import {Image} from "../../../../domain/image";

/**
 * @type {{
 *      selectedImages: Image[]
 * }}
 */
const imageSearchContextArgs = {
    selectedImages: null
};

export default createContext(imageSearchContextArgs);