import makeAttributeBaseComponent from "../attribute-base-component";
import {EntityService} from "js-generic-utilities";
import {ReactComponent, ComposerProps} from "js-react-components";
import {Grid, Container, Box} from "@material-ui/core";
import AttributeSearchCard from "../entity-renderers/attribute-card-renderer.component";
import {Attribute} from "../../../domain/attribute";

/**
 * @type {ReactComponent<ComposerProps>} 
 */
const SearchComposer = ({collection, paginator, searchBar, loader}) => {
    return <Box position="relative">
        {loader}
        <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center" justify="space-between">
                <Grid item>{paginator}</Grid>
                <Grid item>{searchBar}</Grid>
            </Grid>
            <Grid item xs={12} data-testid="attribute-search-collection-wrapper">
                {collection}
            </Grid>
        </Grid>
    </Box>
}

/**
 * @param {{
 *      service: EntityService<"get">,
 *      onSelect: (value: Attribute) => void
 * }} param0 
 */
export default function makeAttributeSearchComponent({service, composer, defaultView, ListEntityRenderer}) {
    let Component = makeAttributeBaseComponent({
        service, 
        composer: composer || SearchComposer,
        GridRenderer: AttributeSearchCard,
        ListEntityRenderer
    });
    return ({onSelect}) => <Component
        defaultView={defaultView || "grid"}
        operations={[
            {
                name: "select",
                label: "Seleziona",
                handler: onSelect
            }
        ]}
    />;
}