import React, { useState, useEffect } from "react";
import {
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    ButtonGroup, 
    Button, 
    Typography, 
    TypographyProps
} from "@material-ui/core";
import ConfirmationDialogService from "./confirmation-dialog.service";

/**
 * @typedef {{
 *      service: ConfirmationDialogService,
 *      title: string,
 *      confirmLabel: string,
 *      closeLabel: string,
 *      titleTypographyProps: TypographyProps,
 *      contentTypographyProps: TypographyProps,
 *      maxWidth: "sm" | "md" | "lg" | "xl"
 * }} ConfirmationDialogInnerProps
 * 
 * @typedef {{
 *      service: ConfirmationDialogService
 * }} ConfirmationDialogOuterProps
 * 
 * @typedef {{
 *      onConfirm: Function,
 *      message: string
 * }} ConfirmationDialogState
 */

/**
 * @return {ConfirmationDialogState}
 */ 
const getState = () => ({onConfirm: null, message: null});

/**
 * @param {ConfirmationDialogInnerProps} param0 
 */
const Component = ({
    service, title, confirmLabel, closeLabel, titleTypographyProps, contentTypographyProps, testID, maxWidth
}) => {

    const [state, setState] = useState(getState());
    const {message, onConfirm} = state;

    const onShow = stuff => setState(stuff);
    const onClose = () => setState(s => ({...s, onConfirm: null}));
    const onDialogConfirm = () => setState(s => {
        onConfirm && s.onConfirm();
        return {...s, onConfirm: null};
    });

    useEffect(() => {
        service.onShow(onShow);
        service.onHide(onClose);
        return () => {
            service.removeHideHandler(onClose);
            service.removeShowHandler(onShow);
        }
    }, []);

    titleTypographyProps = titleTypographyProps || {};
    contentTypographyProps = contentTypographyProps || {};

    const open = Boolean(message && onConfirm);
    const testIDAppendix = open ? "open" : "closed";

    return <Dialog open={open} maxWidth={maxWidth}>
        <DialogTitle disableTypography>
            <Typography 
                variant="h3" 
                {...titleTypographyProps} 
                component="header"
                data-testid={`${testID}-title-${testIDAppendix}`}
            >
                {title}
            </Typography>
        </DialogTitle>
        <DialogContent disableTypography>
            <Typography 
                variant="body1" 
                {...contentTypographyProps} 
                component="p"
                data-testid={`${testID}-content-${testIDAppendix}`}
                gutterBottom
            >
                {message}
            </Typography>
        </DialogContent>
        <DialogActions>
            <ButtonGroup>
                <Button 
                    variant="text" 
                    color="primary" 
                    onClick={onDialogConfirm}
                    data-testid={`${testID}-confirm-${testIDAppendix}`}
                >
                    {confirmLabel}
                </Button>
                <Button 
                    variant="text" 
                    color="secondary" 
                    onClick={onClose}
                    data-testid={`${testID}-close-${testIDAppendix}`}
                >
                    {closeLabel}
                </Button>
            </ButtonGroup>
        </DialogActions>
    </Dialog>
};

const makeConfirmationDialog = ({
    title, 
    confirmLabel, 
    closeLabel, 
    titleTypographyProps,
    contentTypographyProps,
    maxWidth,
    testID
}) => {
    return props => <Component 
        {...props} 
        title={title} 
        closeLabel={closeLabel}
        confirmLabel={confirmLabel}
        maxWidth={maxWidth}
        titleTypographyProps={titleTypographyProps}
        contentTypographyProps={contentTypographyProps}
        testID={testID}
    />;
};

export {Component as ConfirmationDialog, makeConfirmationDialog};