const data = [
    /** stock */
    {product_id: 1, name: "stock", date: "2020-11-11", value: 11},
    {product_id: 1, name: "stock", date: "2020-11-13", value: 9},
    {product_id: 1, name: "stock", date: "2020-11-17", value: 21},
    {product_id: 1, name: "stock", date: "2020-11-21", value: 12},
    {product_id: 1, name: "stock", date: "2020-11-22", value: 7},
    {product_id: 1, name: "stock", date: "2020-11-29", value: 5},
    {product_id: 1, name: "stock", date: "2020-12-12", value: 1},
    {product_id: 9, name: "stock", date: "2020-11-22", value: 11},
    {product_id: 9, name: "stock", date: "2020-12-01", value: 12},

    /** sales */
    {product_id: 1, name: "sales", date: "2020-11-11", value: 1},
    {product_id: 1, name: "sales", date: "2020-11-13", value: 5},
    {product_id: 1, name: "sales", date: "2020-11-17", value: 3},
    {product_id: 1, name: "sales", date: "2020-11-21", value: 7},
    {product_id: 1, name: "sales", date: "2020-11-22", value: 3},
    {product_id: 1, name: "sales", date: "2020-11-29", value: 8},
    {product_id: 1, name: "sales", date: "2020-12-12", value: 5},
    {product_id: 9, name: "sales", date: "2020-11-22", value: 3},
    {product_id: 9, name: "sales", date: "2020-12-01", value: 2},

    /** most sold variants */
    {product_id: 1, name: "top_variants", variant_name: "Variant1", value: 111},
    {product_id: 1, name: "top_variants", variant_name: "Variant2", value: 56},
    {product_id: 1, name: "top_variants", variant_name: "Variant3", value: 32},
    {product_id: 1, name: "top_variants", variant_name: "Variant4", value: 11}
];

export default data;