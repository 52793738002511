import makeEditor from "../../auxiliary/base-entity-editor.component";
import AttributeValueMeta, {validate} from "../../../domain/attribute-value";
import {FolderOpen} from "@material-ui/icons";
import AsyncEntityLoader from "../../auxiliary/async-entity-loader/async-entity-loader.component";
import {inject} from "js-react-components";
import ServicesContext from "../../../contexts/services.context";
import {CircularProgress} from "@material-ui/core";
import EntityValuePrinter from "../../auxiliary/entity-value-printer.component";

const AttributeRenderer = inject(
    ServicesContext,
    ["attribute"],
    ({value, attribute}) => <AsyncEntityLoader
        id={value}
        onGet={v => attribute.serve("get", null, {filterAttribute: "id", filterValue: v})}
        Renderer={({entity}) => entity && <EntityValuePrinter label="Attributo" value={entity.name} />}
        LoadingComponent={CircularProgress}
    />
);

const Editor = makeEditor({
    meta: AttributeValueMeta,
    entityName: "Valore attributo",
    icon: <FolderOpen />,
    attributes: [
        {
            id: "variant_feature_id",
            label: "Codice attributo",
            Renderer: AttributeRenderer
        },
        {
            id: "value",
            label: "Valore",
            type: "text",
            helperText: "Valore mancante o non valido",
            GridProps: {
                style: {display: "flex"}
            },
            TextFieldProps: {
                style: {alignSelf: "flex-end"}
            }
        }
    ],
    config: {
        onValidate: validate,
        isValid: e => validate(["value"], e),
        noCurrentDetails: true
    }
});

export default Editor;