import {EntityEditWrapperInnerComponentProps, ReactComponent, wrapEdit} from "js-react-components";
import {Container, Button, Grid, Typography, makeStyles, Divider, Avatar, TextFieldProps} from "@material-ui/core";
import LabelledInput from "./inputs/labelled-input.component";
import EntityValuePrinter from "./entity-value-printer.component";
import React, {Fragment, ReactNode} from "react";
import {EntityMeta} from "js-generic-utilities";
import LoadingComponent from "./loading.component";

const Loader = () => <LoadingComponent size="3em" label="Caricamento..." />
const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: "1em"
    },
    container: {
        margin: "1em 0"
    }
}));

/**
 * @type {ReactComponent<EntityEditWrapperInnerComponentProps<Category>>}
 */
const Editor = ({
    onInputChange, onSubmit, element, originalElement, 
    attributes, icon, entityName, noCurrentDetails,
    onValidate, isValid, noHeader, loading, title
}) => {
    const classes = useStyles();
    const onFormSubmit = evt => {
        evt.preventDefault();
        onSubmit();
    };
    const isMod = originalElement && (typeof(originalElement.id) === "number") && (typeof(originalElement.permalink) === "string");
    return element ? <Container
        disableGutters
        maxWidth="sm" 
        component="form" 
        onSubmit={onFormSubmit}
        data-testid={isMod ? `${entityName}-${originalElement.id}-editor` : `new-${entityName}-editor`}
        style={{position: "relative"}}
    >
        {loading && <Loader />}
        <Grid container spacing={2}>
            <Grid item xs={12} container spacing={1} component="article">
                <Grid item container xs={12} alignItems="center" justify="flex-start">
                    <Grid item>
                        <Typography variant="h2" gutterBottom>
                            {title || (isMod ? `Modifica ${entityName}` : `Aggiunta ${entityName}`)}
                        </Typography>
                    </Grid>
                </Grid>
                {isMod && !noCurrentDetails && <Fragment>
                    <Grid item xs={12} container spacing={1}>
                        {attributes.map(({label, id, GridProps, type}) => <Grid item xs={12} sm={6} {...(GridProps || {})}>
                            <EntityValuePrinter label={label} value={originalElement[id] || "---"} />
                        </Grid>)}
                    </Grid>    
                </Fragment>}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {/*isMod && !noHeader && <Grid item xs={12}>
                <Typography component="header" variant="h3">
                    Modifica dati
                </Typography>
                </Grid>*/}
            {attributes.map(({label, id, type, helperText, TextFieldProps, GridProps, Renderer}) => {
                const valid = !onValidate || onValidate(id, element);
                return <Grid item xs={12} sm={6} {...(GridProps || {})}>
                    {!Renderer ? <LabelledInput
                        TextFieldProps={{
                            ...TextFieldProps,
                            error: !valid,
                            helperText: !valid && helperText
                        }}
                        inputLabel
                        label={label}
                        type={type || "text"}
                        id={isMod ? `${entityName}-${originalElement.id}-${id}-edit` : `new-${entityName}-${id}-edit`}
                        defaultValue={element[id]}
                        onChange={v => onInputChange(id, v)}
                    /> : <Renderer 
                        key={element[id]}
                        value={element[id]} 
                        element={element}
                        originalElement={originalElement}
                        onChange={v => onInputChange(id, v)} 
                    />}
                </Grid>;
            })}
            <Grid item xs={12}>
                <Button 
                    fullWidth 
                    type="submit" 
                    variant="outlined" 
                    color="primary" 
                    disabled={isValid && !isValid(element)}
                    data-testid={!isMod ? `new-${entityName}-edit-submit` : `${entityName}-${originalElement.id}-edit-submit`}
                >
                    Conferma
                </Button>
            </Grid>
        </Grid>
    </Container> : null;
};

/**
 * @typedef {{
 *      id: string,
 *      label: string,
 *      type: string,
 *      helperText?: string,
 *      TextFieldProps?: TextFieldProps 
 * }} BaseEntityEditorAttribute
 */

/**
 * @template Entity
 * @param {{
 *      meta: EntityMeta<Entity>,
 *      config: {
 *          noCurrentDetails?: boolean,
 *          onValidate: (name: string, element: Entity) => boolean,
 *          isValid: (element: Entity) => boolean
 *      },
 *      attributes: BaseEntityEditorAttribute[],
 *      icon: ReactNode,
 *      entityName: string,
 *      title?: string
 * }} param0 
 */
const makeEditor = ({meta, config, attributes, icon, entityName, title}) => wrapEdit({
    Component: Editor,
    ComponentProps: {
        attributes, 
        icon, 
        entityName,
        title,
        noCurrentDetails: config && config.noCurrentDetails,
        onValidate: config && config.onValidate,
        isValid: config && config.isValid,
        noHeader: config && config.noHeader
    },
    meta
});

export default makeEditor;