import {
  withStyles,
  Paper,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Grid,
  Avatar,
  makeStyles,
  Typography,
  Divider,
  IconButton,
  Collapse,
  Box,
  ListItemIcon,
  Dialog
} from "@material-ui/core";
import {
  Add,
  AddBox,
  KeyboardArrowRight,
  LinearScale,
  ListAlt,
  Person,
  Search,
  SignalCellular0Bar,
  KeyboardArrowLeft
} from "@material-ui/icons";
import clsx from "clsx";
import React, { Fragment, useState } from "react";
import "./singer-side-view.css";
import {HTMLCarousel, CarouselService, CustomCarouselService, inject} from "js-react-components";
import ComponentsContext from "../../contexts/components.context";
import ServicesContext from "../../contexts/services.context";
import AdderWrapper from "../auxiliary/adder-wrapper/adder-wrapper.component";
import CategoryEditor from "../category/entity-editors/category-entity-editor.component";
import AttributeEditor from "../attribute/entity-editors/attribute-entity-editor.component";
import CustomerEditor from "../customer/entity-editors/customer-entity-editor.component";
import ImageEditor from "../image/entity-editor/image-entity-editor.component";
import SettingsContext from "../../contexts/settings.context";
import servicesContext from "../../contexts/services.context";
import { Link } from "react-router-dom";
import EditSuccess from "../auxiliary/edit-success.component";
import BasketAdder from "../basket/entity-editor/basket-adder.component";
import settingsContext from "../../contexts/settings.context";
import {UserAuthContext} from "react-auth-components";
import componentsContext from "../../contexts/components.context";
import ComponentSwitcher from "../auxiliary/component-switcher/component-switcher.component";
import ComponentSwitcherService from "../auxiliary/component-switcher/component-switcher.service";

/**
 * @typedef {{
 *    label: string,
 *    link?: string,
 *    onClick?: function,
 *    Icon: function
 * }} AdditionalMenuElement
 */

const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  smallPerson: {
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
  hiddenOverflow: {
    overflow: "auto"
  },
  img: {
    maxWidth: "100%",
    display: "block"
  },
  paperPadding: {
    position: "sticky",
    paddingTop: "1em",
    paddingBottom: "1em",
    boxSizing: "border-box"
  },
  primaryText: {
    fontWeight: "bold"
  },
  navMenuTitle: {
    textTransform: "uppercase"
  },
  listItemContainer: {
    transitionProperty: "backgroundColor",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#d8ecff",
      color: "blue"
    }
  },
  listItemSelected: {
    backgroundColor: "#d8ecff",
    color: "blue"
  },
  listItemText: {
    marginLeft: "1em"
  },
  arrowRight: {
    position: "relative",
    top: "3px"
  },
  menuSubtitle: {
    textTransform: "uppercase",
    color: "rgb(120, 120, 120)"
  },
  arrowRightAnim: {
    animationName: "arrow-rotate",
    animationDuration: "500ms",
    animationIterationCount: 1,
    animationFillMode: "both"
  },
  divider: {
    margin: "0.5em 1.5em 0.5em 1em"
  },
  mainDivider: {
    margin: "0.5em 0 0.5em 0"
  }
}));

const SideMenuListItem = ({ label, id, onClick, selected, content }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <ListItem
        role="button"
        disableGutters
        key={id}
        className={clsx([
          classes.listItemContainer,
          selected && classes.listItemSelected
        ])}
        onClick={onClick}
      >
        <ListItemText primary={label} className={classes.listItemText} />
        <ListItemSecondaryAction>
          <KeyboardArrowRight
            className={clsx([
              classes.arrowRight,
              selected && classes.arrowRightAnim
            ])}
          />
        </ListItemSecondaryAction>
      </ListItem>
      {content && <Collapse collapsedHeight={0} in={selected}>
        {content}
      </Collapse>}
    </Fragment>
  );
};

const CollapsingSideMenu = withStyles({
  listItem: {
    "&:hover": {
      backgroundColor: "#feefff",
      cursor: "pointer",
      color: "red"
    }
  }
})(
  /**
   * @param {{
   *    elements: AdditionalMenuElement[]
   * }} param0
   */
  ({ elements, classes }) => (
    <Box margin="0.5em 1em 0 1em">
      <List disablePadding>
        {elements.map((e) => (
          <ListItem
            key={e.label || e.id}
            onClick={e.onClick ? e.onClick : undefined}
            className={classes.listItem}
          >
            <ListItemIcon>
              <e.Icon />
            </ListItemIcon>
            <ListItemText primary={e.label} />
          </ListItem>
        ))}
      </List>
    </Box>
  )
);

/**
 * @type {ComponentSwitcherService}
 */
const switcherService = ComponentSwitcherService.make();
const getAdditionalElements = (setAdder) => [
  {
    id: "categorie",
    label: "Categorie",
    content: (
      <CollapsingSideMenu
        elements={[
          { label: "Lista categorie", Icon: ListAlt, onClick: () => window.location.href = "#category" },
          { label: "Aggiunta categoria", Icon: Add, onClick: () => setAdder("category") }
        ]}
      />
    )
  },
  {
    id: "attributi",
    label: "Attributi",
    content: (
      <CollapsingSideMenu
        elements={[
          { label: "Lista attributi", Icon: ListAlt, onClick: () => window.location.href = "#attribute" },
          { label: "Aggiunta attributo", Icon: Add, onClick: () => setAdder("attribute") }
        ]}
      />
    )
  },
  {
    id: "prodotti",
    label: "Prodotti",
    content: (
      <CollapsingSideMenu
        elements={[
          { label: "Lista prodotti base", Icon: ListAlt, onClick: () => window.location.href = "#variable_product" },
          { label: "Lista varianti", Icon: ListAlt, onClick: () => window.location.href = "#product_variant" },
          { label: "Caricamenti/resi", Icon: ListAlt, onClick: () => window.location.href = "#quantity_alteration" },
          { label: "Aggiunta prodotto", Icon: Add, onClick: () => window.location.href = "#product/add"   },
          { label: "Ricerca prodotto", Icon: Search, onClick: () => switcherService.switch(1) }
        ]}
      />
    )
  },
  {
    id: "carrelli",
    label: "Carrelli",
    content: (
      <CollapsingSideMenu
        elements={[
          { label: "Lista carrelli", Icon: ListAlt, onClick: () => window.location.href = "#basket" },
          { label: "Aggiunta carrello", Icon: Add, onClick: () => setAdder("basket")  }
        ]}
      />
    )
  },
  {
    id: "clienti",
    label: "Clienti",
    content: (
      <CollapsingSideMenu
        elements={[
          { label: "Lista clienti", Icon: ListAlt, onClick: () => window.location.href = "#customer" },
          { label: "Aggiunta cliente", Icon: Add, onClick: () => setAdder("customer") }
        ]}
      />
    )
  },
  {
    id: "immagini",
    label: "Immagini",
    content: (
      <CollapsingSideMenu
        elements={[
          { label: "Galleria immagini", Icon: ListAlt, onClick: () => window.location.href = "#image"  },
          { label: "Aggiunta immagine", Icon: Add, onClick: () => setAdder("image") }
        ]}
      />
    )
  },
  {
    id: "notifiche",
    label: "Notifiche e operazioni",
    onClick: () => window.location.href = "#alert" 
  },
  {
    id: "statistiche",
    label: "Statistiche",
    onClick: () => window.location.href = "#basket/stats" 
  }
];

const SingerSideViewInner = inject(
  ServicesContext,
  ["category", "alertService", "attribute", "customer", "image", "basket", "windowService", "authService"],
  inject(
    settingsContext,
    ["basketAdderWidth", "username"],
    inject(
      componentsContext,
      ["Gate"],
      ({
        category, alertService, attribute, authService, onAfterLogout,
        customer, image, basket, windowService, basketAdderWidth, Gate
      }) => {
        const classes = useStyles();
        const [selected, setSelected] = useState(null);
        const [adder, setAdder] = useState(null);
        const toggleSelected = (id) => setSelected((s) => (s === id ? null : id));
        const additionalElements = getAdditionalElements(setAdder);
        const eMapper = ({ id, label, content, onClick }) => (
          <SideMenuListItem
            key={id}
            id={id}
            label={label}
            onClick={onClick ? onClick : () => toggleSelected(id)}
            selected={selected === id}
            content={content}
          />
        );
        const onLogout = onAfterLogout /*auth => authService
          .logout(auth)
          .then(onAfterLogout)
          .catch(mex => alertService.show({severity: "error", message: mex}))*/;
        return (
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Gate 
                    role="admin"
                    onAuthorized={({auth}) => <List disablePadding>
                      <ListItem key="user" disableGutters>
                        <ListItemAvatar style={{ minWidth: "36px" }}>
                          <Avatar className={classes.smallAvatar}>
                            <Person className={classes.smallPerson} />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={(auth.userDetails && auth.userDetails.userId) || "nome.utente"}
                          secondary="Amministratore"
                          primaryTypographyProps={{
                            className: classes.primaryText,
                            variant: "body1"
                          }}
                          secondaryTypographyProps={{
                            variant: "caption"
                          }}
                        />
                        <ListItemSecondaryAction>
                          <Button title="Logout" color="primary" variant="text" onClick={() => onLogout(auth)}>
                            Logout
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>}

                    />
                </Grid>
                <Gate
                  role="admin"
                  onAuthorized={({auth}) => <Fragment>
                    {/*<Grid item xs={12} component="section">
                      <Typography
                        variant="body1"
                        component="header"
                        gutterBottom
                        className={classes.navMenuTitle}
                      >
                        menu' di navigazione
                      </Typography>
                      <Divider style={{ marginTop: "0.75em" }} />
                        </Grid>*/}
                    <Grid item xs={12}>
                      <List disablePadding className="single-color-list">
                        <SideMenuListItem
                          id="dashboard"
                          label="Dashboard"
                          onClick={() => windowService.href("/dashboard")}
                        />
                        <Box marginLeft="1em" marginTop="1em">
                          <Typography
                            className={classes.menuSubtitle}
                            component="header"
                            variant="caption"
                            gutterBottom
                          >
                            entita'
                          </Typography>
                        </Box>
                        {additionalElements.slice(2, 6).map(eMapper)}
                        <Box marginLeft="1em" marginTop="1em">
                          <Typography
                            className={classes.menuSubtitle}
                            component="header"
                            variant="caption"
                            gutterBottom
                          >
                            metadati prodotti
                          </Typography>
                        </Box>
                        {additionalElements.slice(0, 2).map(eMapper)}
                        <Box marginLeft="1em" marginTop="1em">
                          <Typography
                            className={classes.menuSubtitle}
                            component="header"
                            variant="caption"
                            gutterBottom
                          >
                            altro
                          </Typography>
                        </Box>
                        {additionalElements.slice(6).map(eMapper)}
                      </List>
                    </Grid>
                  </Fragment>}
                />
              </Grid>
              <Dialog maxWidth="md" open={adder} onBackdropClick={() => setAdder(null)}>
                  {[
                    {
                      id: "category", 
                      entityName: "category", 
                      service: category, 
                      Editor: CategoryEditor
                    },
                    {
                      id: "basket", 
                      entityName: "basket", 
                      service: basket, 
                      Editor: BasketAdder,
                      EditorProps: {width: basketAdderWidth}
                    },
                    {
                      id: "attribute", 
                      entityName: "attribute", 
                      service: attribute, 
                      Editor: AttributeEditor
                    },
                    {
                      id: "customer", 
                      entityName: "customer", 
                      service: customer, 
                      Editor: CustomerEditor
                    },
                    {
                      id: "image", 
                      entityName: "image", 
                      service: image, 
                      Editor: ImageEditor
                    }
                  ].map(({id, entityName, service, Editor, EditorProps}) => (id === adder) ? <Container
                    maxWidth={false}
                  >
                    <AdderWrapper
                      Adder={Editor}
                      AdderProps={EditorProps || {}}
                      onAfterSave={e => {
                        setAdder(null);
                        if(entityName === "basket") {
                          windowService.href(`/basket/${e.id}`)
                        } else {
                          alertService.show({
                            message: <EditSuccess entity={e} entityName={entityName} />, 
                            severity: "success"
                          });
                        }
                      }}
                      onError={e => console.log(e)}
                      element={{}}
                      service={service}
                    />
                  </Container> : null)}
              </Dialog>
            </Container>
        );
      }
    )
  )
);

const WrappedSideViewProductSearch = inject(
  ComponentsContext,
  ["SideViewProductSearch"],
  ({SideViewProductSearch, onSelect}) => <Container maxWidth={false}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button 
          onClick={() => switcherService.switch(0)}
          variant="text"
          color="primary"
          startIcon={<KeyboardArrowLeft />}
        >
          Indietro
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography component="header" variant="h4" style={{textTransform: "uppercase"}} gutterBottom>ricerca prodotto</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <SideViewProductSearch onSelect={onSelect} />
      </Grid>
    </Grid>
  </Container>
);

const SingerSideView = inject(
  SettingsContext,
  ["sideViewWidth", "appBarHeight", "username"],
  inject(
    servicesContext,
    ["windowService"],
    ({sideViewWidth, windowService, onAfterLogout, appBarHeight, username}) => {
      const classes = useStyles();
      return <Paper
        className={clsx([
          classes.paperPadding,
          classes.hiddenOverflow
        ])}
        style={{
          height: `calc(100vh - ${appBarHeight}px)`, 
          top: `${appBarHeight}px`
        }}
      >
        <ComponentSwitcher
          items={[
            {
              Component: SingerSideViewInner,
              ComponentProps: {
                switcherService,
                onAfterLogout
              }
            },
            {
              Component: WrappedSideViewProductSearch,
              ComponentProps: {
                onSelect: p => windowService.href(`/product/${p.id}`)
              }
            }
          ]}
          initialSelected={0}
          service={switcherService}
        />
      </Paper>;
    }
  )
)

export default SingerSideView;