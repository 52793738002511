import makeEditor from "../../auxiliary/base-entity-editor.component";
import BasketProductMeta, {BasketProduct} from "../../../domain/basket-product";
import {ShoppingBasket} from "@material-ui/icons";
import {ReactComponent, EntityDetailRendererProps, inject} from "js-react-components";
import ServicesContext from "../../../contexts/services.context";
import AsyncEntityLoader from "../../auxiliary/async-entity-loader/async-entity-loader.component";
import { EntityService, HttpOperation } from "js-generic-utilities";
import { CircularProgress, Typography } from "@material-ui/core";
import ProductShortOverview from "../../product/entity-renderers/product-short-overview.component";

/**
 * @type {ReactComponent<EntityDetailRendererProps<BasketProduct>>}
 */
const Editor = makeEditor({
    meta: BasketProductMeta,
    entityName: "Prodotto carrello",
    icon: <ShoppingBasket />,
    config: {
        noCurrentDetails: true
    },
    attributes: [
        {
            id: "product_id",
            label: "Prodotto",
            Renderer: inject(
                ServicesContext,
                ["product"],
                /**
                 * @param {{
                 *      product: EntityService<HttpOperation>,
                 *      value: number,
                 *      originalElement: BasketProduct
                 * }}
                 */
                ({product, value, originalElement}) => {
                    console.log(`originalElement quantity = ${originalElement.quantity}`);
                    return <AsyncEntityLoader
                        id={value}
                        key={originalElement.quantity}
                        onGet={i => product.serve("get", null, {filterAttribute: "id", filterValue: i})}
                        Renderer={({entity}) => entity && <ProductShortOverview element={entity} />}
                        LoadingComponent={CircularProgress}
                    />;
                }
            )
        },
        {
            id: "quantity",
            label: "Quantità",
            type: "number"
        }
    ]
});

export default Editor;