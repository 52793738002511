import React from "react";
import {ReactComponent, BaseComponentArgs} from "js-react-components";
import {EntityService, HttpOperation, ObserverPool} from "js-generic-utilities";
import {Category, CategoryAttributes, CategoryIndices, CategorySorters, CategoryViews} from "../../../domain/category";
import makeCategoryBaseComponent from "../category-base-component";
import ProductSearchPaginator from "../../product/search/product-search-paginator.component";
import CategorySearchCard from "../entity-renderers/category-card-search-renderer.component";
import CategorySearchListRenderer from "../entity-renderers/category-search-list-renderer.component";
import getSearchComposer from "../../auxiliary/search-composer.component";

/**
 * @typedef {ReactComponent<BaseComponentArgs<Category, CategoryViews, CategoryIndices, CategorySorters, CategoryAttributes>>} CategorySearchComponent
 */

const SearchComposer = getSearchComposer("category-search-collection-wrapper");

/**
 * @param {{
 *      service: EntityService<HttpOperation>,
 *      onSelect: (e: Category) => void,
 *      componentService: ObserverPool<"refresh">
 * }} param0 
 * @return {CategorySearchComponent}
 */
export default function makeComponent({service, componentService, composer}) {

    const Component = makeCategoryBaseComponent({
        itemsPerPage: 4,
        Paginator: ProductSearchPaginator,
        componentService,
        service, 
        elementsPerRow: {xs: 12, sm: 3},
        composer: SearchComposer,
        ListEntityRenderer: CategorySearchListRenderer
    });

    return ({onSelect}) => <Component
        defaultView="list"
        operations={[
            {
                name: "select",
                label: "Seleziona",
                handler: onSelect
            }
        ]} 
    />;
        
}
