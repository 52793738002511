import {ObserverPool, observe, UpdateFunction, remove, notify, makeObserverPool} from "js-generic-utilities";

const setHandler = (service, action, handler) => observe(service._pool).on(action).with(handler);
const removeHandler = (service, action, handler) => remove(handler).from(service._pool).on(action).do();
const notifyHandler = (service, action, value) => notify(service._pool).of(action).with(value);

/**
 * @typedef {"next" | "prev" | "switch"} ComponentSwitcherEvent
 * @typedef {UpdateFunction<ComponentSwitcherEvent, void>} ComponentSwitcherEventHandler
 */

export default class ComponentSwitcherService {

    static make() {
        return new ComponentSwitcherService(makeObserverPool());
    }

    /**
     * @type {ObserverPool<ComponentSwitcherEvent, void>}
     */
    _pool;

    /**
     * @param {ObserverPool} pool 
     */
    constructor(pool) {
        this._pool = pool;
    }

    next() {
        notifyHandler(this, "next", null);
    }

    prev() {
        notifyHandler(this, "prev", null);
    }

    /**
     * @param {number} index
     */
    switch(index) {
        notifyHandler(this, "switch", index);
    }

    /**
     * @param {ComponentSwitcherEventHandler} handler
     */
    onNext(handler) {
        setHandler(this, "next", handler);
    }

    /**
     * @param {ComponentSwitcherEventHandler} handler
     */
    onPrev(handler) {
        setHandler(this, "prev", handler);
    }

    /**
     * @param {ComponentSwitcherEventHandler} handler
     */
    onSwitch(handler) {
        setHandler(this, "switch", handler);
    }

    /**
     * @param {ComponentSwitcherEventHandler} handler
     * @param {ComponentSwitcherEvent} event
     */
    remove(handler, event) {
        removeHandler(this, event, handler);
    }
}