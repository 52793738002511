import {ObserverPool, observe, UpdateFunction, notify, remove} from "js-generic-utilities";
import {Basket} from "../../../domain/basket";

/**
 * @typedef {ObserverPool<"refresh" | "reload", any>} BasketSideViewPool
 * @typedef {UpdateFunction<"refresh" | "reload", any>} BasketSideViewUpdateFunction
 */

export default class BasketSideViewService {

    /**
     * @type {BasketSideViewPool}
     */
    _pool;

    static make() {
        let pool = new ObserverPool();
        return new BasketSideViewService(pool);
    }

    /**
     * @param {BasketSideViewPool} pool 
     */
    constructor(pool) {
        this._pool = pool;
    }

    /**
     * @param {BasketSideViewUpdateFunction} listener 
     */
    onRefresh(listener) {
        observe(this._pool).on("refresh").with(listener);
    }

    onReload(listener) {
        observe(this._pool).on("reload").with(listener);
    }

    /**
     * @param {Basket} basket 
     */
    refresh(basket) {
        notify(this._pool).of("refresh").with(basket);
    }

    reload(noHash) {
        notify(this._pool).of("reload").with(noHash);
    }

    /**
     * @param {BasketSideViewUpdateFunction} listener 
     */
    remove(listener) {
        remove(listener).from(this._pool).on("refresh").do();
        remove(listener).from(this._pool).on("reload").do();
    }
}