import {EntityService, HttpOperation} from "js-generic-utilities";
import {render, EntityBaseComponentBuilder} from "js-react-components";
import ProductStatMeta, {ProductStat, ProductStatIndices, ProductStatAttributes, ProductStatSorters} from "../../domain/product-stat";
import ProductStatCollectionRenderer from "./collection-renderers/product-stat-collection-renderer.component";
import DefaultLoader from "../auxiliary/default-loader.component";
import { Box } from "@material-ui/core";

/**
 * @typedef {EntityBaseComponentBuilder<"refresh", ProductStat, "list", ProductStatIndices, ProductStatAttributes, ProductStatSorters>} ProductStatBaseComponentBuilder
 * @typedef {EntityService<HttpOperation>} ProductStatService
 * @param {{
 *      service: ProductStatService
 * }} param0 
 */
export default function makeProductStateBaseComponent({service}) {
    /**
     * @type {ProductStatBaseComponentBuilder}
     */
    const builder = render(service);

    return builder.withMeta(ProductStatMeta)
        .withComponents({
            Loader: DefaultLoader
        })
        .withGrid({
            Collection: ProductStatCollectionRenderer,
            Entity: () => null
        })
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Caricamento dati"
        })
        .withDefaultView("grid")
        .compose(({collection, loader}) => <Box position="relative">
            {collection}
            {loader}
        </Box>);

}