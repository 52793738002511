import { CollectionUtilityWrapper } from "js-generic-utilities";

export function getDummyComponent() {
    return () => null;
};

export function isSelected (element, els, matcher) {
    matcher = matcher || ((a, b) => a.id === b.id);
    if((els instanceof Array) && els.length) {
      /**
       * @type {CollectionUtilityWrapper<any>}
       */
      let wrapper = CollectionUtilityWrapper.wrap(els, matcher);
      return wrapper.has(element);
    }
    return false;
}