import {EntityMeta} from "js-generic-utilities";

/**
 * @typedef {"product_id" | "image_id"} ProductImageSorters
 * @typedef {"product_id" | "image_id"} ProductImageIndices
 * @typedef {"product_id" | "image_id"} ProductImageAttributes
 * @typedef {{
 *    product_id: number,
 *    image_id: number
 * }} ProductImage
 */

/**
 * @type {EntityMeta<ProductImage>}
 */
const meta = {
  keyGetter: (e) => String(e.product_id) + String(e.image_id),
  matcher: (a, b) => meta.keyGetter(a) === meta.keyGetter(b),
  Constructor: function(v) {return v;}
};

export default meta;
