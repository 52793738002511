import {EntityMeta} from "js-generic-utilities";

const rand = () => Math.floor(Math.random()*99999999+1);

export function isMod(element) {
    return element.permalink && (element.permalink !== "");
}

/**
 * @template Entity
 * @return {EntityMeta<Entity, Entity>}
 * @param {EntityMeta<Entity, Entity>} additional
 */
export function getDefaultMeta(additional) {
    return {
        Constructor: function(v) {
            v = v ? {...v} : {};
            if(typeof(v.id) !== "number") {
                v.id = rand();
            }
            return v;
        },
        matcher: (a, b) => a.id === b.id,
        keyGetter: a => a.id,
        ...additional
    };
}

export class ValidationWrapper {

    _data;
    _validators;

    constructor(data, validators) {
        this._data = data;
        this._validators = validators;
    }

    validate(name) {
        if(this._validators && this._validators[name]) {
            return this._data && this._data[name] && this._validators[name].test(this._data[name]);
        }
        return true;
    }

    validateMulti(names) {
        return names.map(n => this.validate(n)).reduce((a, b) => a && b, true);
    }
}