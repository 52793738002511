import {CardHeader, Card, CardContent, Avatar} from "@material-ui/core";
import React, {ReactNode} from "react";

/**
 * @param {Array} elements 
 * @param {string} statName
 */
const getData = (elements, xAttr, yAttr) => {
    return {
        labels: elements.map(e => e[xAttr]),
        values: elements.map(e => e[yAttr])
    }
}

/**
 * @typedef {{
 *      labels: string[],
 *      datasets: {
 *          label: string,
 *          data: Array
 *      }[]
 * }} GroupData
 * @param {{
 *      elements: Array,
 *      label: string,
 *      avatar: ReactNode,
 *      Renderer: ({data: GroupData}) => void,
 *      xAttr: string,
 *      yAttr: string
 * }} param0 
 */
const StatGroupRenderer = ({
    elements, 
    label, 
    avatar, 
    Renderer, 
    xAttr, 
    yAttr,
    subheader,
    title
}) => {
    const {labels, values} = getData(elements, xAttr, yAttr);
    const data = {
        labels: labels,
        datasets: [
            {
                label: label,
                data: values
            }
        ]
    };
    return <Card>
        <CardHeader
            avatar={
                <Avatar>
                    {avatar}
                </Avatar>
            }
            title={title}
            titleTypographyProps={{
                variant: "h4",
                style: {
                    color: "grey",
                    fontWeight: "bold"
                }
            }}
            subheader={subheader}
        />
        <CardContent>
            <Renderer data={data} />
        </CardContent>
    </Card>
}

export default StatGroupRenderer;