import React from "react";
import { EntityRendererProps } from "js-react-components";
import { Category } from "../../../domain/category";
import { ListItem, ListItemText, ListItemSecondaryAction, ListItemAvatar, Avatar, Button } from "@material-ui/core";
import { TagFacesOutlined, Label } from "@material-ui/icons";

/**
 * @param {EntityRendererProps<Category>} param0 
 */
export default function CategoryListRenderer({
    element, onOperation
}) {
    return <ListItem 
        key={element.id} 
        data-testid={`category-search-${element.id}-container`}
    >
        <ListItemAvatar>
            <Avatar>
                <Label />
            </Avatar>
        </ListItemAvatar>
        <ListItemText 
            primary={element.name} 
            secondary={element.description} 
            primaryTypographyProps={{
                "data-testid": `category-search-${element.id}`
            }}
            secondaryTypographyProps={{
                "data-testid": `category-search-${element.id}-name`
            }}
        />
        <ListItemSecondaryAction>
            <Button 
                variant="text" 
                color="primary" 
                type="button" 
                onClick={() => onOperation("select", element)}
                data-testid={`category-search-${element.id}-select`}
            >
                Seleziona
            </Button>
        </ListItemSecondaryAction>
    </ListItem>
}