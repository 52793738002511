import React from "react";
import BasketProductMeta, {BasketProduct} from "../../../domain/basket-product";
import {EntityEditWrapperInnerComponentProps, wrapEdit} from "js-react-components";
import { Grid, TextField, IconButton, InputAdornment, Button, withStyles } from "@material-ui/core";
import {Check} from "@material-ui/icons";
import { useState } from "react";
import { ClosedBasketContext } from "../../basket/entity-renderer/basket-product-list-renderer.component";

/**
 * @param {EntityEditWrapperInnerComponentProps<BasketProduct>} param0 
 */
const Component = ({onInputChange, onSubmit, element, style, readOnly, classes}) => {
    const [modified, setModified] = useState(null);
    return element ? <ClosedBasketContext.Consumer>
        {closed => <Grid 
            container 
            component="form"
            onSubmit={evt => {
                evt.preventDefault();
                setModified(false);
                onSubmit();
            }}
            style={style}
        >
            <Grid item>
                <TextField
                    defaultValue={element.quantity}
                    onChange={evt => {
                        setModified(true);
                        onInputChange("quantity", evt.target.value);
                    }}
                    className={classes.maxWidth}
                    disabled={closed}
                    type="number"
                    variant="outlined"
                    inputProps={{
                        min: 0,
                        step: 1
                    }}
                    style={style}
                    InputProps={{
                        endAdornment: !closed && <InputAdornment>
                            <IconButton type="submit" disabled={!modified} color="primary">
                                <Check />
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </Grid>
        </Grid>}
    </ClosedBasketContext.Consumer> : null;
};

const Wrapped = withStyles({
    maxWidth: {
        maxWidth: "125px"
    }
})(Component);

export default wrapEdit({
    Component: Wrapped,
    ComponentProps: {style: {width: "auto"}},
    meta: BasketProductMeta
})