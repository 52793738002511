import {BasketProduct} from "../../domain/basket-product";

/**
 * @type {BasketProduct[]}
 */
const data = [
    {id: 1, cart_id: 1, product_id: 10, quantity: 10, cost: 100},
    {id: 2, cart_id: 2, product_id: 10, quantity: 2, cost: 20},
    {id: 3, cart_id: 1, product_id: 11, quantity: 2, cost: 22},
    {id: 4, cart_id: 1, product_id: 12, quantity: 2, cost: 24},
];

export default data;