const date = new RegExp(/^[a-zA-Z0-9\\-]+$/);
const aNum = new RegExp(/^[a-zA-Z0-9\\-\\ \\_]+$/);
const addr = new RegExp(/^[0-9a-zA-Z\\,\\.\\°\\-\\ ]+$/);
//const text = new RegExp(/^[a-zA-Z0-9\\ \\,\\.\\-\\è\\à\\ò\\ù\\+\\(\\)\\/\\%\\&\\$\\"\\'\\_\\:\\;\\ç\\°\\*]+$/);
const text = new RegExp(/^.*$/);
/**
 * @type {{
 *      text: RegExp,
 *      alphanumeric: RegExp,
 *      date: RegExp,
 *      integer: RegExp,
 *      float: RegExp,
 *      address: RegExp,
 *      fullname: RegExp,
 *      phone: RegExp,
 *      email: RegExp,
 *      city: RegExp,
 *      password: RegExp
 * }}
 */
let regexes = {
    text,
    date,
    alphanumeric: aNum,
    integer: new RegExp(/^[0-9]+$/),
    float: new RegExp(/^[0-9]+([\\.\\,][0-9]+){0,1}$/),
    address: addr,
    fullname: aNum,
    phone: new RegExp(/^([+]{0,1}39){0,1}[0-9]{1,}$/),
    email: new RegExp(/^[a-zA-Z0-9]{1,}[@][a-zA-Z0-9][.][a-zA-Z]{1,}$/),
    city: addr,
    password: text
};

export default regexes;