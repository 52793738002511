import {createContext} from "react";
import {EntityService, HttpOperation, ObserverPool} from "js-generic-utilities";
import DocumentService from "../services/auxiliary/document-service";
import ErrorService from "../services/auxiliary/error-service";

/**
 * @typedef {EntityService<HttpOperation>} HttpService
 * @type {{
 *      attribute: HttpService,
 *      attributeValue: HttpService,
 *      category: HttpService,
 *      product: HttpService,
 *      variableProduct: HttpService,
 *      productCategory: HttpService,
 *      categoryComponent: ObserverPool<"refresh">,
 *      productCategoryComponent: ObserverPool<"refresh">,
 *      documentService: DocumentService,
 *      errorService: ErrorService
 * }}
 */
const services = {
    attribute: null,
    attributeValue: null,
    category: null,
    product: null,
    variableProduct: null,
    productCategory: null,
    documentService: null,
    categoryComponent: null,
    productCategoryComponent: null,
    errorService: null
};

export default createContext(services);