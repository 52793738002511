import React, { useState } from "react";
import {ReactComponent, EntityDetailRendererProps} from "js-react-components";
import {EntityService, HttpOperation} from "js-generic-utilities";
import {Box} from "@material-ui/core";
import LoadingComponent from "../loading.component";

const Loader = () => <LoadingComponent testID="adder-wrapper-loader" size="3em" label="Attendere..." />;

/**
 * @template T
 * @param {{
 *      service: EntityService<HttpOperation>,
 *      Adder: ReactComponent<EntityDetailRendererProps<T>>,
 *      AdderProps: any,
 *      onAfterSave?: Function,
 *      onError?: Function,
 *      element?: T
 * }} param0 
 */
const Component = ({
    service, Adder, AdderProps, onError, onAfterSave, element
}) => {

    const [loading, setLoading] = useState(false);

    const onSave = element => {
        setLoading(true);
        service
            .serve("save", element)
            .then(onAfterSave)
            .catch(onError)
            .finally(() => setLoading(false));
    };

    return <Box position="relative" margin="1em 0">
        {loading && <Loader />}
        <Adder {...(AdderProps || {})} element={element} onSave={onSave} />
    </Box>;

};

export default Component;