import React, { useState, Fragment } from "react";
import {TextField, InputAdornment, IconButton, Button, FormControl, ButtonGroup, Dialog, DialogTitle, DialogContent, DialogActions, Typography, FormControlLabel, Checkbox} from "@material-ui/core";
import ProductCostMeta, {ProductCost} from "../../domain/product-cost";
import {wrapEdit, EntityEditWrapperInnerComponent} from "js-react-components";
import { Check, CheckBox, Euro } from "@material-ui/icons";
import DiscountedPrice from "../auxiliary/inputs/discounted-price.component";
import ProductCostEditorContext from "./product-cost-editor.context";
import ProductCostVariantsContext from "../../contexts/product-cost-variants.context";

const getLabel = e => {
    if((e.productList.id % 2) === 1) {
        return "Standard";
    } else {
        return "Scontato";
    }
};

const DiscountedPriceDialog = ({open, DiscountedPriceProps, onClose}) => <Dialog open={open} maxWidth="md">
    <DialogTitle disableTypography>
        <Typography variant="h2" component="header">Modifica costo percentuale</Typography>
    </DialogTitle>
    <DialogContent>
        <DiscountedPrice {...DiscountedPriceProps} />
    </DialogContent>
    <DialogActions>
        <Button variant="text" color="secondary" onClick={onClose}>
            Chiudi
        </Button>
    </DialogActions>
</Dialog>

const Component = wrapEdit({
    Component: 
        /**
         * @param {EntityEditWrapperInnerComponent<ProductCost>} param0
         */
        ({onInputChange, onSubmit, element}) => {
            const [state, setState] = useState({mod: false, perc: false, controlled: true});
            const onPercToggle = () => setState(s => ({...s, perc: !s.perc}));
            const onSetControlled = v => setState(s => ({...s, controlled: v}));
            const onInputSubmit = () => {
                onSubmit();
                setState(s => ({...s, mod: false}));
            };
            return element ? <ProductCostEditorContext.Consumer>
                {({baseValue}) => <Fragment>
                    <DiscountedPriceDialog
                        open={state.perc}
                        onClose={onPercToggle}
                        DiscountedPriceProps={{
                            baseValue: baseValue,
                            discountedValue: element.cost,
                            onConfirm: v => {
                                onInputChange("cost", v);
                                setState(s => ({...s, perc: false, mod: true}));
                            }
                        }}
                    />
                    <FormControl 
                        component="form" 
                        fullWidth
                        onSubmit={evt => {
                            evt.preventDefault();
                            onInputSubmit();
                        }}
                    >
                        <TextField
                            label={getLabel(element)}
                            defaultValue={state.controlled ? null : element.cost}
                            value={state.controlled ? element.cost : undefined}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            type="number"
                            onChange={e => {
                                setState(s => ({...s, mod: true}));
                                onInputChange("cost", e.target.value);
                            }}
                            onFocus={() => onSetControlled(false)}
                            onBlur={() => onSetControlled(true)}
                            InputProps={{
                                startAdornment: <InputAdornment style={{marginRight: "0.5em"}}>
                                    <Euro />
                                </InputAdornment>,
                                endAdornment: <InputAdornment>
                                    <ButtonGroup>
                                        {baseValue && <Button
                                            variant="text"
                                            color="secondary"
                                            type="button"
                                            onClick={onPercToggle}
                                        >
                                            Perc
                                        </Button>}
                                        <Button 
                                            disabled={!state.mod} 
                                            type="submit"
                                            variant="text" 
                                            color="primary"
                                        >
                                            Salva
                                        </Button>
                                    </ButtonGroup>
                                </InputAdornment>
                            }}
                            inputProps={{
                                min: 0,
                                step: 0.01
                            }}
                        />
                    </FormControl>
                    <ProductCostVariantsContext.Consumer>
                        {({resetCosts}) => resetCosts && state.mod && <FormControlLabel
                            control={<Checkbox />}
                            label="Resetta costi varianti"
                            onChange={(evt, checked) => onInputChange("reset_variant_costs", evt.target.checked)}
                            defaultChecked={Boolean(element.reset_variant_costs)}
                        />}
                    </ProductCostVariantsContext.Consumer>
                </Fragment>}
            </ProductCostEditorContext.Consumer> : null;
        },
    meta: ProductCostMeta
});

export default Component;