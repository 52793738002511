import {EntityMeta, EntityWrapper} from "js-generic-utilities";
import { getDefaultMeta, ValidationWrapper } from "./auxiliary/domain-misc-functions";
import regexes from "./auxiliary/regex-repository";

/**
 * @typedef {"list"} CategoryViews
 * @typedef {"name"} CategoryIndices
 * @typedef {"name"} CategorySorters
 * @typedef {"id" | "name"} CategoryAttributes
 * @typedef {{
 *    id: number,
 *    name: string,
 *    description: string,
 *    products_num?: number
 * }} Category
 */

/**
 * @type {EntityMeta<Category>}
 */
const meta = getDefaultMeta();

export function validate(arg, data) {
    let w = new ValidationWrapper(data, {
        name: regexes.alphanumeric,
        description: regexes.text
    });
    if(arg instanceof Array) {
        return w.validateMulti(arg);
    } else {
        return w.validate(arg);
    }
}

export default meta;
