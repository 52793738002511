import BasketStatGroupRenderer from "../entity-renderers/basket-stat-group-renderer.component";
import {Line, Bar} from "react-chartjs-2";
import {Euro, Person} from "@material-ui/icons";
import {Avatar} from "@material-ui/core";
import StatGroupCollectionRenderer from "../../auxiliary/stat-group-collection-renderer.component";

const stats = [
    {
        name: "top_selling_product",
        label: "Prodotti base più venduti",
        Renderer: Bar,
        subheader: "top 10",
        avatar: <Avatar>B</Avatar>,
        xAttr: "label"
    },
    {
        name: "top_variant",
        label: "Varianti più vendute",
        Renderer: Bar,
        subheader: "top 10",
        avatar: <Avatar>V</Avatar>,
        xAttr: "label"
    },
    {
        name: "top_customer",
        label: "Clienti più redditizi",
        subheader: "top 10",
        Renderer: Bar,
        avatar: <Person />,
        xAttr: "label"
    },
    {
        name: "month_sales",
        label: "Vendite",
        subheader: "Ultimi 3 mesi",
        Renderer: Line,
        avatar: <Euro />,
        xAttr: "label"
    }
];

const BasketStatCollectionRenderer = ({elements}) => <StatGroupCollectionRenderer 
    stats={stats} 
    elements={elements}
    GroupRenderer={BasketStatGroupRenderer}
/>

export default BasketStatCollectionRenderer;