import React from "react";
import {EntityRendererProps, ReactComponent} from "js-react-components";
import {Image} from "../../../domain/image";
import { 
    Paper, Box, withStyles, Card, CardActionArea, CardActions, CardContent, CardHeader, ButtonGroup, Button, Avatar, Typography 
} from "@material-ui/core";
import { Image as ImageIcon } from "@material-ui/icons";
import { shorten } from "../../../utils/misc-utils-functions";

const Component = withStyles({  
    img: {
        display: "block",
        maxWidth: "100%"
    }
})(
    /**
     * @type {ReactComponent<EntityRendererProps<Image>>}
     */
    ({element, onOperation, onDelete, onSelect, onSave, classes}) => <Card>
        <CardHeader 
            title={element.name} 
            titleTypographyProps={{
                component: "header",
                variant: "h4",
                style: {fontWeight: "bold"}
            }}
            subheader={`Usata da ${element.productsCount} prodotti`}
            avatar={<Avatar><ImageIcon /></Avatar>}
        />
        <CardContent>
            <img 
                className={classes.img} 
                src={element.content || element.url || element.full_url} 
                alt={element.name} 
            />
        </CardContent>
        <CardActionArea>
            <CardActions>
                <ButtonGroup fullWidth>
                    {/*<Button variant="text" color="primary" onClick={() => onSelect(element)}>
                        Modifica
        </Button>*/}
                    <Button variant="text" color="secondary" onClick={() => onOperation("delete", element)}>
                        Cancella
                    </Button>
                </ButtonGroup>
            </CardActions>
        </CardActionArea>
    </Card>
);

export default Component;