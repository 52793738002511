export {FetchRequestManager};

function isNully(v) {
    return (v === undefined) || (v === null);
}

const makeQueryString = (params) => {
    const names = Object.getOwnPropertyNames(params);
    let out = "";
    if(names.length) {
        for(let i=0; i<names.length; i++) {
            const cName = names[i];
            if(!isNully(params[cName])) {
                out += cName + "=" + params[cName];
                if(i !== (names.length - 1)) {
                    out += "&";
                }
            }
        }
    }
    return out;
}

const getDefaultUrl = (url, defaultUrl) => {
    const regex = new RegExp(/^(http).*$/);
    if(regex.test(url)) {
        return "";
    } else {
        return defaultUrl;
    }
}
const makeOutPars = (defaultParams, params) => {
    params = params || {};
    const out = {
        ...defaultParams, 
        ...params, 
        indexName: params.filterAttribute, 
        indexValue: params.filterValue
    };
    delete out.filterAttribute;
    delete out.filterValue;
    return out;
}
const makeUrl = (url, params) => {
    console.log(params);
    if(params.indexName && params.indexValue) {
        params[params.indexName] = params.indexValue;
        delete params.indexName;
        delete params.indexValue;
    }
    if((params.start !== undefined) && (params.end !== undefined)) {
        let size = params.itemsPerPage || 24;
        let page = Math.ceil(params.end/size)-1;
        params.size = size;
        params.page = page;
        delete params.start;
        delete params.end;
    }
    /*if(params.sortAttribute) {
        params.sortBy = params.sortAttribute;
        delete params.sortAttribute;
    }*/
    if(params.sort) {
        params.sortAttribute = params.sort;
        delete params.sort;
    }
    const qString = makeQueryString(params);
    if(url.indexOf("?") === -1) {
        url = url + "?";
    }
    return url + (Boolean(qString) ? qString : "");
};
const makeRequest = onFetch => (method, url, data, headers, unset) => {
    if(unset && data) {
        data = {...data};
        if(data.variantAttribute) {
            delete data.variantAttribute;
        }
        if(data.variableProduct) {
            delete data.variableProduct;
        }
        if(data.product) {
            delete data.product;
        }
        if(data.productList) {
            delete data.productList;
        }
        if(data.categories) {
            delete data.categories;
        }
        if(data.cart) {
            delete data.cart;
        }
    }
    if(typeof(data) === "object") {
        data = JSON.stringify(data);
    }
    console.log(headers);
    return onFetch(url, {body: data, method, headers: {...headers, "content-type": "application/json"}})
        .then((response) => response.json().then(c => {
            console.log(c);
            if(c.count !== undefined) {
                c.data = c.count;
            }
            if(c._embedded) {
                let out = [];
                for(let p in c._embedded) {
                    out = out.concat(c._embedded[p]);
                }
                c.data = out;
            }
            if(c.data && (c.data.count !== undefined)) {
                c.data = c.data.count;
            }
            if(c.data && c.message) {
                c.data.message = c.message;
            }
            if(((c.data === undefined) || (c.data === null))) {
                if(!Object.getOwnPropertyNames(c).length) {
                    c = {data: []}
                } else {
                    c = {data: c};
                }
            }
            if(response.status === 401) {
                c = {message: "Credenziali di accesso non valide."};
            }
            return Promise.resolve({
                ...c, 
                status: response.status
            });
        }))
}

/**
 * @implements {RequestManager}
 */
class FetchRequestManager {

    static _instance;

    static build(defaultUrl, defaultHeaders) {
        if(!FetchRequestManager._instance) {
            FetchRequestManager._instance = new FetchRequestManager(defaultUrl, defaultHeaders);
        }
        return FetchRequestManager._instance;
    }
    
    /**
     * @type {object}
     */
    _defaultParams;

    /**
     * @type {{
     *      Authorization: string
     * }}
     */
    _defaultHeaders;
    
    /**
     * @type {string}
     */
    _defaultUrl;

    /**
     * @type {number}
     */
    _nRequests;

    /**
     * @type {(url: string, options: RequestInit) => Promise<Response<any>>}
     */
    _onFetch;

    constructor(defaultUrl, defaultHeaders, onFetch) {
        this._defaultParams = {};
        this._defaultHeaders = defaultHeaders;
        this._defaultUrl = defaultUrl;
        this._nRequests = 0;
        this._onFetch = onFetch;
    }

    /**
     * @param {object} params 
     */
    setDefaultParams(params) {
        if(params) {
            this._defaultParams = {...this._defaultParams, ...params};
        }
    }

    /**
     * @param {object} headers 
     */
    setDefaultHeaders(headers) {
        if(headers) {
            this._defaultHeaders = {...headers};
        }
    }

    post(url, data, params, headers) {
        const outPars = makeOutPars(this._defaultParams, params);
        const outUrl = getDefaultUrl(data.url || url, this._defaultUrl) + makeUrl(data.url || url, outPars);
        console.log(data);
        this._nRequests++;
        this.printNumRequests();
        return makeRequest(this._onFetch)(data.method || "post", outUrl, data, headers || this._defaultHeaders, false);
    }

    put(url, data, params, headers) {
        const outPars = makeOutPars(this._defaultParams, params);
        const outUrl = getDefaultUrl(url, this._defaultUrl) + makeUrl(url, outPars);
        delete data.type;
        console.log(data);
        this._nRequests++;
        this.printNumRequests();
        return makeRequest(this._onFetch)("put", outUrl, data, headers || this._defaultHeaders, true);
    }

    get(url, params, headers) {
        const outPars = makeOutPars(this._defaultParams, params);
        console.log(outPars);
        const outUrl = getDefaultUrl(url, this._defaultUrl) + makeUrl(url, outPars);
        console.log("url = " + outUrl);
        this._nRequests++;
        this.printNumRequests();
        return makeRequest(this._onFetch)("get", outUrl, undefined, headers || this._defaultHeaders);
    }

    delete(url, params, headers) {
        const outPars = makeOutPars(this._defaultParams, params);
        const outUrl = getDefaultUrl(url, this._defaultUrl) + makeUrl(url, outPars);
        console.log("url = " + outUrl);
        this._nRequests++;
        this.printNumRequests();
        return makeRequest(this._onFetch)("delete", outUrl, undefined, headers || this._defaultHeaders);
    }

    printNumRequests() {
        console.log("FetchRequestManager: got " + this._nRequests + " requests.");
    }
}