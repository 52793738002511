import React, {Fragment} from "react";
import {Grid, Box} from "@material-ui/core";
import {ReactComponent, ComposerProps} from "js-react-components";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    collectionWrapper: {
        width: "75%",
        margin: "auto",
        zIndex: 4
    },
    externalWrapper: {
        position: "relative",
        display: "flex",
        alignItems: "center"
    }
}));


 /**
 * @type {ReactComponent<ComposerProps>} 
 */
const SearchComposer = ({collection, paginator, searchBar, testId, container, loader}) => {
    const classes = useStyles();
    const aux = <Fragment>
        {loader}
        <Grid item xs={container ? 12 : undefined}>
            {searchBar}
        </Grid>
        <Grid item xs={12} data-testid={testId}>
            <Box className={classes.externalWrapper}>
                {paginator}
                <Box className={classes.collectionWrapper}>
                    {collection}
                </Box>
            </Box>
        </Grid>
    </Fragment>;

    return container ? <Grid container spacing={2}>{aux}</Grid> : aux;
};

const getSearchComposer = (testId, container) => (props) => <SearchComposer 
    testId={testId} container={container} {...props} 
/>;

export default getSearchComposer;