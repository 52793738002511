import React from "react";
import {ReactComponent, BaseComponentArgs} from "js-react-components";
import {EntityService, HttpOperation, ObserverPool} from "js-generic-utilities";
import {Image, ImageAttributes, ImageIndices, ImageSorters, ImageViews} from "../../../domain/image";
import makeImageBaseComponent from "../image-base-component";
import ProductSearchPaginator from "../../product/search/product-search-paginator.component";
import ImageSearchRenderer from "../entity-renderers/image-search-renderer.component";
import getSearchComposer from "../../auxiliary/search-composer.component";

/**
 * @typedef {ReactComponent<BaseComponentArgs<Image, ImageViews, ImageIndices, ImageSorters, ImageAttributes>>} ImageSearchComponent
 */

const SearchComposer = getSearchComposer("image-search-collection-wrapper");

/**
 * @param {{
 *      service: EntityService<HttpOperation>,
 *      onSelect: (e: Image) => void,
 *      componentService: ObserverPool<"refresh">
 * }} param0 
 * @return {ImageSearchComponent}
 */
export default function makeComponent({service, componentService}) {

    const Component = makeImageBaseComponent({
        itemsPerPage: 4,
        Paginator: ProductSearchPaginator,
        elementsPerRow: {xs: 4, sm: 3},
        componentService,
        service, 
        composer: SearchComposer,
        EntityRenderer: ImageSearchRenderer
    });

    return ({onSelect}) => <Component
        operations={[
            {
                name: "select",
                label: "Seleziona",
                handler: onSelect
            }
        ]} 
    />;
        
}
