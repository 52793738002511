import {Basket} from "../../domain/basket";

/**
 * @type {Basket[]}
 */
const data = [
    {id: 1, customer_id: 1, date: "2020-11-11", status: "open", cost: 100, product_count: 2},
    {id: 2, customer_id: 1, date: "2020-11-12", status: "open", cost: 20, product_count: 3},
    {id: 3, customer_id: 2, date: "2020-11-21", status: "closed", cost: 54, product_count: 4},
    {id: 4, customer_id: 2, date: "2020-11-23", status: "paid", cost: 44, product_count: 5},
    {id: 5, customer_id: 1, date: "2020-11-29", status: "open", cost: 20, product_count: 6},
    {id: 6, customer_id: 2, date: "2020-11-30", status: "open", cost: 2, product_count: 7}
];

export default data;