import React, {Fragment, useState} from "react";
import SingerMainView from "./components/singer-main-view.component";
import ServicesContext from "./contexts/services.context";
import ComponentsContext from "./contexts/components.context";
import ProductAdder from "./components/product/entity-editors/product-adder/product-adder.component";
import BaseProductEditor from "./components/product/entity-editors/base-product/base-product-editor.component";
import {ComponentsContext as BaseComponentsContext} from "js-react-components";
import {Container, Paper, Typography, Button, Dialog as DialogComponent, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import CategoryEditor from "./components/category/entity-editors/category-entity-editor.component";
import TestBaseVariant from "./testing/components/test-base-variant.component";
import BaseProductVariantList from "./components/product/entity-renderers/base-product-variant-list.component";
import { Switch, Route, HashRouter as Router } from "react-router-dom";
import ImageEditor from "./components/auxiliary/inputs/image-editor.component";
import BasketProductAdder from "./components/basket-product/entity-editor/basket-product-adder.component";
import HistoryWrapper from "./components/auxiliary/history-wrapper/history-wrapper.component";
import ProductVariantWrapper from "./components/product/auxiliary/product-variant-wrapper.component";
import renderMainRoutes from "./render-main-routes";
import AdderWrapper from "./components/auxiliary/adder-wrapper/adder-wrapper.component";
import SettingsContext, {settings} from "./contexts/settings.context";
import VariantGenerationAttributeSelection from "./components/product/entity-editors/base-product/variant-generation/variant-generation-attribute-selection.component";
import {initAuthComponents} from "react-auth-components";
import AuthWrapper from "./components/auth/auth-wrapper.component";
import {roleGetter, credentialsValidator, authEndpoints} from "./constants";
import {RequestManager} from "js-generic-utilities";
import MultiAttributeFilterer, {makeMultiAttributeFilterer} from "./components/auxiliary/filterers/multi-attribute-filterer.component";
import {makeDateFilterer} from "./components/auxiliary/filterers/simple-date-filterer.component";
import {makeConfirmationDialog} from "./components/auxiliary/confirmation-dialog/confirmation-dialog.component";
import ConfirmationDialogService from "./components/auxiliary/confirmation-dialog/confirmation-dialog.service";
import DiscountedPrice from "./components/auxiliary/inputs/discounted-price.component";
import BarcodeScanner from "./components/basket/auxiliary/basket-barcode-scanner.component";
import AlertErrorBoundary from "./utils/alert-error-boundary";

const getFakeRequestManager = () => ({
    get: (url, params, headers) => {
        return Promise.resolve({status: 200, data: {token: "token", refresh: "refresh", username: "admin", role: "admin"}})
    }
});

/**
 * @typedef {"product-editor" | "base-variant-product-editor" | "product-adder" | "category-editor" | "basket-product-adder"} Editors
 * @typedef {"image-search"} Searchers
 * @typedef {"product-variant-view" | "product-quantity-alteration-view" | "base-product-view" | "category-view" | "customer-view" | "attribute-view" | "image-view" | basket-view} Views
 * @typedef {"image-editor" | "login-panel"} Misc
 * @typedef {Editors | Searchers | Views | Misc} RootTests
 * 
 * @param {RootTests} type
 * @param {RequestManager} requestManager
 */
export default function renderRoot(type, {BaseComponents, services, components, requestManager, username}) {
    
    let fragment;
    let product;
    let variant;
    let filters;
    let WrapperComponent;
    let Filterer;
    let Dialog;
    let dialogService;

    switch(type) {

        case "product-adder":
            product = {id: 1, variantAttributeValues: []};
            fragment = <Container maxWidth="md">
                <ProductAdder
                    product={product}
                    service={services.product}
                    width={750}
                    onClose={() => alert("closed")}
                />
            </Container>;
            break;

        case "product-editor":
            product = {id: 1, name: "prod1", description: "prod1", stock: 10, type: "variable", image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"};
            fragment = <Container maxWidth="md">
                <SettingsContext.Provider value={{productEditorWidth: 888}}>
                    <BaseProductEditor
                        element={product}
                        onSubmit={p => console.log(p)}
                    />
                </SettingsContext.Provider>
            </Container>;
            break;

        case "category-editor":
            const c = {name: "cat1", description: "cat1", id: 1};
            fragment = <Paper>
                <CategoryEditor element={c} originalElement={c} onSubmit={c => console.log(c)} />
            </Paper>;
            break;

        case "base-product-view":
            fragment = <components.BaseProduct />;
            break;

        case "customer-view":
            fragment = <Switch>
                <Route path="/" exact>
                    <components.Customer />
                </Route>
                <Route path="/customer/1/baskets">
                    <div>Baskets for customer 1</div>
                </Route>
                <Route path="/customer/2/baskets">
                    <div>Baskets for customer 2</div>
                </Route>
            </Switch>;
            break;

        case "category-view":
            fragment = <components.Category />;
            break;

        case "basket-view":
            fragment = <components.Basket />;
            break;

        case "attribute-view":
            fragment = <components.Attribute />;
            break;

        case "image-view":
            fragment = <components.Image />;
            break;

        case "product-variant-view":
            fragment = <components.BaseProduct defaultView="list" />;
            break;

        case "image-editor":
            fragment = <Container maxWidth="sm">
                <ImageEditor defaultValue="https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg" onConfirm={i => console.log(i)} autoSubmit />
            </Container>;
            break;

        case "base-variant-product-editor":
            product = {id: 1, name: "prod1", description: "prod1", stock: 10, type: "variable", image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"};
            variant = {id: 10, name: "prod10", description: "prod1", stock: 3, type: "variant", variable_product_id: 1, variantAttributeValues: [2], image: "https://www.italwebcom.it/singer/prodotti/server/img//base/81610/0.jpg?dummy=126595"};
            fragment = <TestBaseVariant product={product} variant={variant} />;
            break;

        case "basket-product-adder":
            fragment = <ServicesContext.Provider value={services}>

                <BasketProductAdder width={700} basketId={1} onSave={e => console.log(e)} />
            </ServicesContext.Provider>;
            break; 
        case "product-quantity-alteration-view":
            fragment = <components.ProductQuantityAlterationReal defaultView="list" />;
            break;

        case "attribute-search":
            fragment = <components.AttributeSearch onSelect={e => console.log(e)} />;
            break;

        case "variant-generation-attribute-selection":
            fragment = <VariantGenerationAttributeSelection 
                baseProduct={{name: "product"}} 
                onGenerate={attrs => console.log(attrs)} 
            />;
            break;

        case "image-search":
            fragment = <Container maxWidth="md" style={{paddingTop: "1em"}}>
                <components.ImageSearch onSelect={i => console.log(i)} />
            </Container>;
            break;

        case "multi-attribute-filterer":
            filters = [
                {label: "attr1", attribute: "attr1"},
                {label: "attr2", attribute: "attr2"}
            ];
            Filterer = makeMultiAttributeFilterer({filters, testID: "test"});
            WrapperComponent = () => {
                const [state, setState] = useState({attribute: null, value: ""});
                return <Filterer 
                    {...state}
                    attributeLabel="Attributo"
                    onChange={(values) => {
                        setState(values);
                        console.log(values);
                    }} 
                    onClear={() => {
                        setState({attribute: null, value: ""});
                        console.log("clear");
                    }} 
                />;
            };
            fragment = <WrapperComponent />;
            break;

        case "simple-date-filterer":
            Filterer = makeDateFilterer({attribute: "date", resolution: "months", testID: "date-filterer"});
            WrapperComponent = () => {
                const [state, setState] = useState({attribute: "date", value: "2020-11"});
                return <Filterer
                    {...state}
                    onChange={(values) => setState(values)}
                    onClear={() => setState({attribute: "date", value: null})}
                />
            };
            fragment = <WrapperComponent />;
            break;

        case "main":
            fragment = <components.Auth>
                <SingerMainView>
                    {renderMainRoutes({services, components, requestManager})}
                </SingerMainView>
            </components.Auth>;
            break;

        case "confirmation-dialog":
            Dialog = makeConfirmationDialog({
                title: "Dialog confirmation",
                confirmLabel: "Confirm",
                closeLabel: "Close",
                testID: "dialog-confirmation",
                maxWidth: "md",
                titleTypographyProps: {
                    variant: "h3",
                    style: {
                        fontWeight: "bold",
                        color: "rgb(111, 111, 111)"
                    }
                },
                contentTypographyProps: {
                    variant: "body1"
                }
            });
            dialogService = ConfirmationDialogService.make();
            fragment = <Fragment>
                <Button variant="outlined" color="primary" onClick={() => dialogService.show({message: "message", onConfirm: () => alert("confirmed")})}>Show</Button>
                <Dialog service={dialogService} />
            </Fragment>;
            break;

        case "discounted-price":
            fragment = <DialogComponent open>
                <DialogTitle>Modifica percentuale</DialogTitle>
                <DialogContent maxWidth="md">
                    <DiscountedPrice baseValue={75} onConfirm={v => console.log(v)} />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="secondary" onConfirm={() => console.log("close")}>Chiudi</Button>
                </DialogActions>
            </DialogComponent>;
            break;

        case "barcode-scanner":
            fragment = <BarcodeScanner
                onSave={p => console.log(p)}
                basket={{}}
                scanTimeoutMs={250}
            />;
            break;

        case "login-panel":
            const fakeRequestManager = getFakeRequestManager();
            fragment = initAuthComponents({
                roleGetter: c => c.role,
                requestManager: fakeRequestManager,
                validator: () => true,
                endpoints: {auth: "auth", refresh: "refresh"}
            })(
                ({LoginPanel, Gate, Auth, authService}) => <Switch>
                    <Route path="/" exact>
                        <AuthWrapper
                            LoginPanel={LoginPanel}
                            Gate={Gate}
                            Auth={Auth}
                            service={authService}
                            LoginPanelProps={{
                                title: "Pannello login",
                                rememberLabel: "Ricordami",
                                rememberTooltip: "lol"
                            }}
                            loginPath="/private"
                            alertService={{
                                show: ({message}) => console.log(message)
                            }}
                        />
                    </Route>
                    <Route path="/private" exact>
                        <Typography component="p" variant="h2">Private area</Typography>
                    </Route>
                </Switch>
            );

            break;

        default:
            fragment = null; 
    }
    
    return <React.StrictMode>
        <BaseComponentsContext.Provider value={BaseComponents}>
            <ServicesContext.Provider value={services}>
                <ComponentsContext.Provider value={components}>
                    <SettingsContext.Provider value={{...settings, username}}>
                        <AlertErrorBoundary alertService={services.alertService}>
                            <Router>
                                {fragment}
                            </Router>
                        </AlertErrorBoundary>
                    </SettingsContext.Provider>
                </ComponentsContext.Provider>
            </ServicesContext.Provider>
        </BaseComponentsContext.Provider>
    </React.StrictMode>;
}