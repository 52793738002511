import {EntityRendererProps, ReactComponent} from "js-react-components";
import {Alert, wrap} from "../../../domain/alert";
import {ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, withStyles, Typography, IconButton, Collapse, Box, Grid, ButtonGroup} from "@material-ui/core";
import {shorten} from "../../../utils/misc-utils-functions";
import {Warning, Info, ExpandLess, ExpandMore, Delete, DeleteForever} from "@material-ui/icons";
import moment from "moment";
import { useState, Fragment } from "react";

const Renderer = withStyles({
    warning: {
        color: "red"
    },
    info: {
        color: "green"
    },
    listItem: {
        backgroundColor: "rgb(250, 250, 250)",
        borderRadius: "5px",
        border: "1px solid rgb(225, 225, 225)"
    },
    innerContent: {
        padding: "1em",
        marginBottom: "0.5em"
    }
})(
    /**
     * @type {ReactComponent<EntityRendererProps<Alert>>}
     */
    ({element, classes, onOperation}) => {
        const wrapped = wrap(element);
        const [state, setState] = useState(false);
        const toggleState = () => setState(s => !s);
        return <Fragment key={element.id}>
            <ListItem key={element.id} className={classes.listItem} style={!state ? {marginBottom: "0.5em"} : {}}>
                <ListItemAvatar>
                    {wrapped.isError() ? <Warning className={classes.warning} /> : <Info className={classes.info} />}
                </ListItemAvatar>
                <ListItemText 
                    primary={`${wrapped.getTypeDescription()}`} 
                    secondary={wrapped.getUsername()} 
                />
                <ListItemSecondaryAction>
                    <ButtonGroup>
                        <IconButton onClick={toggleState} color="primary">
                            {state ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        <IconButton onClick={() => onOperation("delete", element)} color="secondary">
                            <DeleteForever />
                        </IconButton>
                    </ButtonGroup>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={state}>
                <Grid container alignItems="center" className={classes.innerContent} spacing={2}>
                    <Grid item xs>
                        <Typography component="header" variant="h4" gutterBottom>
                            Messaggio
                        </Typography>
                        <Typography component="p" variant="body1">
                            {element.message}
                        </Typography>
                    </Grid>
                    {element.payload && <Grid item xs>
                        <Typography component="header" variant="h4" gutterBottom>
                            Payload richiesta
                        </Typography>
                        <Typography component="pre" variant="body1">
                            {JSON.stringify(JSON.parse(element.payload), null, 2)}
                        </Typography>
                    </Grid>}
                </Grid>
            </Collapse>
        </Fragment>;
    }
);

export default Renderer;