import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeBasketProductService({requestManager}) {
    const handlers = [
        handle("get").with({method: "GET", path: (d, e) => (e.filterAttribute === "cart_id") ? `/cart/${e.filterValue}/product` : `/basket_product`}).build(),
        handle("count").with({method: "GET", path: (d, e) => (e.filterAttribute === "cart_id") ? `/cart/${e.filterValue}/product/count` : `/basket_product/count`}).build(),
        handle("save").with({method: "POST", path: e => `/cart/${e.cart_id}/product`})
            .and
            .before(d => ({...d, product: d.product_id, method: d.type ? "put" : "post", url: d.type && `/cart/${d.cart_id}/product/${d.product_id}`}))
            .build(),
        handle("update").with({method: "PUT", path: e => `/cart/${e.cart.id ? e.cart.id : e.cart}/product/${e.product.id}`}).build(),
        handle("delete").with({method: "DELETE", path: e => `/cart/${e.cart.id ? e.cart.id : e.cart}/product/${e.product.id}`}).build()
    ];

    return serve(handlers).using(requestManager).build();
}