import {createMuiTheme, ThemeOptions} from "@material-ui/core";

/**
 * @type {ThemeOptions}
 */
const theme = {
    overrides: {
      MuiTableCell: {
        head: {
          color: "grey",
          fontWeight: "bold"
        }
      },
      MuiTabs: {
        root: {
          maxWidth: "90vw"
        },
        scroller: {
          overflow: "scroll"
        }
      },
      MuiTableRow: {
        root: {
          "&:not(.MuiTableRow-head):nth-of-type(2n)": {
            backgroundColor: "rgb(250, 250, 250)"
          }
        }
      },
      MuiOutlinedInput: {
        input: {
          padding: "14px"
        }
      },
      MuiInputLabel: {
        outlined: {
          transform: "translate(14px, 16px) scale(1)"
        }
      },
      MuiCardHeader: {
        action: {
          marginTop: 0,
          marginRight: 0,
          alignSelf: "initial"
        }
      },
      MuiButton: {
        label: {
            fontSize: "0.9em"
        }
      },
      MuiIconButton: {
          label: {
              "& > .MuiSvgIcon-root": {
                fontSize: "0.8em"
              }
          }
      },
    },
    typography: {
      h1: {
        fontSize: "2em",
        fontFamily: "Montserrat"
      },
      h2: {
        fontSize: "1.25em",
        fontFamily: "Montserrat",
        fontWeight: "bold",
        color: "rgb(111, 111, 111)"
      },
      h3: {
        fontSize: "1.1em",
        fontFamily: "Montserrat"
      },
      h4: {
        fontSize: "1em",
        fontFamily: "Montserrat",
        color: "rgb(134, 134, 134)"
      },
      h5: {
        fontSize: "0.75em",
        fontFamily: "Montserrat"
      },
      h6: {
        fontSize: "0.5em",
        fontFamily: "Montserrat"
      },
      body1: {
        fontFamily: "Arial, sans-serif"
      },
      body2: {
        fontFamily: "Arial, sans-serif"
      }
    }
};

export default createMuiTheme(theme);