import {EntityMeta} from "js-generic-utilities";
import { getDefaultMeta, ValidationWrapper } from "./auxiliary/domain-misc-functions";
import regexes from "./auxiliary/regex-repository";

/**
 * @typedef {"grid" | "list"} ImageViews
 * @typedef {"name"} ImageIndices
 * @typedef {"name"} ImageSorters
 * @typedef {"id" | "name" | "url" | "content"} ImageAttributes
 * @typedef {{
 *    id: number,
 *    name: string,
 *    url?: string,
 *    content?: string,
 *    products_number?: number
 * }} Image
 */

/**
 * @type {EntityMeta<Image>}
 */
const meta = getDefaultMeta({
    Constructor: function(v) {
        const out = v ? {...v} : {};
        if(out.full_url) {
            out.url = out.full_url;
            delete out.full_url;
        }
        return out;
    }
});

export function validate(data, n) {
    let w = new ValidationWrapper(data, {
        name: regexes.alphanumeric
    });
    return (n instanceof Array) ? w.validateMulti(n) : w.validate(n);
}

export default meta;
