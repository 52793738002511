import {EntityMeta} from "js-generic-utilities";

/**
 * @typedef {"name" | "label"} BasketStatSorters
 * @typedef {"name" | "label"} BasketStatIndices
 * @typedef {"name" | "label" | "value"} BasketStatAttributes
 * @typedef {{
 *    name: string,
 *    label: string,
 *    value: string|number
 * }} BasketStat
 */

/**
 * @type {EntityMeta<BasketStat, BasketStat>}
 */
const meta = {
  keyGetter: (e) => String(e.label) + String(e.name),
  matcher: (a, b) => {
    const ka = meta.keyGetter(a);
    const kb = meta.keyGetter(b);
    return ka === kb;
  },
  Constructor: function(v) {
      return v;
  }
};

export default meta;
