import React, {Component} from "react";

export default class AlertErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = {error: null};
    }

    componentDidCatch(e) {
        const {alertService} = this.props;
        console.log(e);
        alertService && alertService.show({
            message: e.message ? e.message : JSON.stringify(e),
            severity: "error"
        });
    }

    render() {
        return this.props.children;
    }
}