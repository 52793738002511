import moment from "moment";
import React, {memo} from "react";
import {Container, Grid, IconButton, Typography, ButtonGroup, Button, Badge, Tooltip} from "@material-ui/core";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import { inject } from "js-react-components";
import settingsContext from "../../../contexts/settings.context";

/**
 * @typedef {"months" | "days"} DateFiltererResolution
 */

const defaultResolution = "months";
const getResolutionPattern = (resolution, print) => {
    if(resolution === "months") {
        return print ? "MM-YYYY" : "YYYY-MM";
    } else {
        return print ? "DD-MM-YYYY" : "YYYY-MM-DD";
    }
};

/**
 * @param {string} value 
 * @param {number} amount
 * @param {DateFiltererResolution} resolution 
 */
const add = (value, amount, resolution) => {
    let m;
    value = value || undefined;
    if(resolution === "months") {
        m = moment(value).add(amount, "months"); 
    } else {
        m = moment(value).add(amount, "days");
    }
    return m.format(getResolutionPattern(resolution));
};

const getNext = (value, resolution) => add(value, 1, resolution);
const getPrev = (value, resolution) => add(value, -1, resolution);
const getToday = resolution => moment().format(getResolutionPattern(resolution));

const getToPrint = date => moment(date).format("MM-YYYY");

const Component = memo(
    inject(
        settingsContext,
        ["smallScreen"],
        ({
            attribute, requiredAttribute, value, onChange, onClear, resolution, testID, TypographyProps, smallScreen
        }) => {
    
            if(attribute && (requiredAttribute !== attribute)) {
                return null;
            }
            
            const onNext = () => onChange({attribute: requiredAttribute, value: getNext(value, resolution)});
            const onPrev = () => onChange({attribute: requiredAttribute, value: getPrev(value, resolution)});
            const onToday = () => onChange({attribute: requiredAttribute, value: getToday(resolution)});
        
            return <Container 
                maxWidth={false} 
                disableGutters
                data-testid={`${testID}-wrapper`}
            >
                <Grid container spacing={2} alignItems="center" justify="space-between">
                    <Grid item>
                        <Tooltip 
                            title={<Typography variant="body1">
                                {getToPrint(getPrev(value, resolution))}
                            </Typography>} 
                            arrow
                        >
                            <IconButton onClick={onPrev} data-testid={`${testID}-prev`}>
                                <KeyboardArrowLeft />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Typography
                            data-testid={`${testID}-date`}
                            {...(TypographyProps || {})}
                        >
                            {value ? moment(value).format(getResolutionPattern(resolution, true)) : "---"}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ButtonGroup>
                            <Tooltip 
                                title={<Typography variant="body1">
                                    {getToPrint(getToday(resolution))}
                                </Typography>} 
                                arrow
                            >
                                <Button type="button" variant="text" color="primary" onClick={onToday}>
                                    Oggi
                                </Button>
                            </Tooltip>
                            <Button type="button" variant="text" color="secondary" onClick={onClear}>
                                Rimuovi
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <Tooltip 
                            title={<Typography variant="body1">
                                {getToPrint(getNext(value, resolution))}
                            </Typography>} 
                            arrow
                        >
                            <IconButton onClick={onNext} data-testid={`${testID}-next`}>
                                <KeyboardArrowRight />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Container>
        }
    ),
    (p, n) => p.value === n.value
);

/**
 * 
 * @param {{attribute: string, resolution: DateFiltererResolution, testID: string}} param0 
 */
export function makeDateFilterer ({attribute, resolution, testID, TypographyProps}) {
    return props => <Component 
        {...props} 
        requiredAttribute={attribute} 
        resolution={resolution || defaultResolution} 
        testID={testID}
        TypographyProps={TypographyProps}
    />;
}