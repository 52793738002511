import React, {useState, useEffect} from "react";
import ServicesContext from "../../../contexts/services.context";
import {inject} from "js-react-components";
import {Chip, Typography} from "@material-ui/core";
import {DeleteForeverRounded} from "@material-ui/icons";

const Renderer = inject(
    ServicesContext,
    ["category", "errorService"],
    /**
     * @param {{
     *      id: number,
     *      onRemove: (id: number) => void
     * }} param0
     */
    ({id, onRemove, category, errorService, testId}) => {
        const [el, setEl] = useState(null);
    
        useEffect(() => {
            category.serve("get", null, {filterAttribute: "id", filterValue: id})
                    .then(v => setEl(v instanceof Array ? v[0] : v));
        }, [id]);
    
        return el ? <Chip 
            size="medium"
            variant="outlined"
            color="primary"
            data-testid={testId} 
            label={<Typography component="span" variant="body1">{el.name}</Typography>} 
            onDelete={() => onRemove(id)} 
            deleteIcon={<DeleteForeverRounded data-testid={`${testId}-delete`} />}
        /> : null;
    }
);

export default Renderer;