import React from "react";

import {
  Box,
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles,
  ListItemAvatar,
  Avatar
} from "@material-ui/core";
import { FolderOpen } from "@material-ui/icons";
import AttributeValueSearchContext from "../search/attribute-value-search.context";

const useStyles = makeStyles((theme) => ({
  lineBox: {
    width: "50px",
    marginRight: "1em",
    height: "1px",
    backgroundColor: "rgb(111, 111, 111)"
  },
  valueListItem: {
    background: "white"
  }
}));

const AttributeValueSearchListRenderer = ({ element, onSelect, onOperation}) => {
  const classes = useStyles();
  return element && (
    <AttributeValueSearchContext.Consumer>
      {({selected}) => <ListItem 
        disableGutters 
        className={classes.valueListItem} 
        data-testid={`attribute-value-search-list-element-${element.id}`}
        key={element.id}
      >
        {/*<Box className={classes.lineBox} />*/}
        <ListItemAvatar>
          <Avatar>
            {element.value && element.value.substring(0,1)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={element.value} secondary={element.full_value} />
        <ListItemSecondaryAction>
          <Button 
            color="primary" 
            onClick={() => onOperation("search_select", element)} 
            data-testid={`attribute-value-search-list-element-${element.id}-select`}
            disabled={selected && (selected.indexOf(element.id) !== -1)}
          >
            <Typography component="p" variant="caption">
              Seleziona
            </Typography>
          </Button>
        </ListItemSecondaryAction>
      </ListItem>}
    </AttributeValueSearchContext.Consumer>
  );
};

export default AttributeValueSearchListRenderer;