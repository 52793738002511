import {ProductStat} from "../../../domain/product-stat";
import React, {ReactNode} from "react";
import StatGroupRenderer from "../../auxiliary/stat-group-renderer.component";

const getFiltered = (elements, statName) => elements.filter(e => e.name === statName);

/**
 * @param {{
 *      elements: ProductStat[],
 *      name: string,
 *      label: string,
 *      avatar: ReactNode
 * }} param0 
 */
const BasketStatGroupRenderer = ({
    elements, 
    name, 
    label, 
    subheader,
    avatar, 
    Renderer, 
    xAttr, 
    yAttr
}) => {
    yAttr = yAttr || "value";
    xAttr = xAttr || "date";
    return elements && elements.length ? <StatGroupRenderer
        xAttr={xAttr}
        yAttr={yAttr}
        title={`${label}`}
        avatar={avatar}
        Renderer={Renderer}
        elements={getFiltered(elements, name)}
        label={label}
        subheader={subheader}
    /> : null;
}

export default BasketStatGroupRenderer;