import React, { useState, memo, useEffect } from "react";
import {
    TextField, Typography, Container, Grid, 
    InputAdornment, Button, Box, FormControl, IconButton
} from "@material-ui/core";
import { Check } from "@material-ui/icons";

/**
 * @param {number|string} baseValue 
 * @param {number|string} discountedValue 
 */
const getPercentage = (baseValue, discountedValue) => {
    baseValue = parseFloat(baseValue);
    discountedValue = parseFloat(discountedValue);
    return (discountedValue && baseValue) ? ((1 - (discountedValue / baseValue)) * 100) : 0;
}

/**
 * @param {number|string} baseValue 
 * @param {number|string} percentage 
 */
const getDiscounted = (baseValue, percentage) => {
    baseValue = parseFloat(baseValue);
    percentage = parseFloat(percentage);
    return (baseValue && percentage) ? (baseValue * (1 - percentage / 100)) : baseValue;
}

/**
 * @typedef {{
 *      baseValue: number,
 *      discountedValue?: number,
 *      onConfirm: (value: number) => void,
 *      testID?: string
 * }} DiscountedPriceComponentProps
 */

const Component = memo(
    /**
     * @param {DiscountedPriceComponentProps} param0
     */
    ({baseValue, discountedValue, onConfirm, testID, noPrices, inlineButton}) => {

        const [perc, setPerc] = useState(getPercentage(baseValue, discountedValue));

        const onValueSubmit = evt => {
            evt.preventDefault();
            setPerc(s => {
                onConfirm(getDiscounted(baseValue, perc));
                return s;
            });
        };

        useEffect(() => {
            const p = getPercentage(baseValue, discountedValue);
            if(p !== perc) {
                setPerc(p);
            }
        }, [discountedValue, baseValue]);
        
        return <Box component="article">
            <FormControl fullWidth component="form" onSubmit={onValueSubmit}>
                <Container maxWidth={false} disableGutters>
                    <Grid container spacing={1} justify="space-between" alignItems="center">
                        {!noPrices && <Grid item component="section">
                            <Typography variant="h4" component="header" gutterBottom>
                                Prezzo base
                            </Typography>
                            <Typography 
                                variant="body1" 
                                component="p"
                                data-testid={`${testID}-base-value`}
                            >
                                {baseValue} &euro;
                            </Typography>
    </Grid>}
                        <Grid item component="section">
                            <TextField
                                label="Sconto %"
                                defaultValue={perc}
                                onChange={evt => setPerc(evt.target.value)}
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment>
                                        <Typography component="p" variant="h3">
                                            %
                                        </Typography>
                                        {inlineButton && <Box marginLeft="0.5em">
                                            <IconButton type="submit">
                                                <Check />
                                            </IconButton>
                                        </Box>}
                                    </InputAdornment>
                                }}
                                inputProps={{
                                    min: 0,
                                    max: 100,
                                    step: 1,
                                    "data-testid": `${testID}-percentage-input`
                                }}
                            />
                        </Grid>
                        {!noPrices && <Grid item component="section">
                            <Typography variant="h4" component="header" gutterBottom>
                                Prezzo scontato
                            </Typography>
                            <Typography 
                                variant="body1" 
                                component="p"
                                data-testid={`${testID}-discounted-value`}
                            >
                                {getDiscounted(baseValue, perc)} &euro;
                            </Typography>
                            </Grid>}
                        {!inlineButton && <Grid item xs={12}>
                            <Button 
                                fullWidth
                                variant="outlined" 
                                color="primary" 
                                type="submit"
                                data-testid={`${testID}-confirm`}
                            >
                                Conferma
                            </Button>
                        </Grid>}
                    </Grid>
                </Container>
            </FormControl>
        </Box>;
    },
    (p, n) => (p.value === n.value) && (p.discountedValue === n.discountedValue)
);

export default Component;