const DEFAULT_SEPARATOR = "; ";
const INNER_SEPARATOR = "=";

/**
 * @param {CookieGetter} cookieGetter 
 * @param {string} separator
 */
const process = function(cookieGetter) {
    const d = cookieGetter._document;
    const separator = cookieGetter._separator;
    const out = {};
    d.cookie.split(separator).forEach(current => {
        const inner = current.split(INNER_SEPARATOR);
        out[inner[0]] = inner[1];
    });
    return out;
}

/**
 * @typedef {{
 *      cookie: string
 * }} CookieGetterDocument
 */

/**
 * @param {CookieGetterDocument} document 
 */
export function cookieGetter(document) {
    return new CookieGetter(document);
}

export class CookieGetter {

    /**
     * @type {CookieGetterDocument}
     */
    _document;

    /**
     * @type {string}
     */
    _separator;

    /**
     * @param {CookieGetterDocument} document
     * @param {string} separator 
     */
    constructor(document, separator) {
        separator = separator || DEFAULT_SEPARATOR;
        this._document = document;
        this._separator = separator;
    }

    /**
     * @return {boolean}
     * @param {string} name 
     */
    has(name) {
        const values = process(this);
        return typeof(values[name]) !== "undefined";
    }

    /**
     * @return {string}
     * @param {string} name 
     */
    get(name) {
        const values = process(this);
        return values[name];
    }
}