import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

const pathGetter = (d, e) => {
    if(e.filterAttribute === "customer_id") {
        return `/customer/${e.filterValue}/cart`;
    }
    if(e.filterAttribute === "id") {
        return `/cart/${e.filterValue}`;
    }
    return "/cart";
}

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeBasketService({requestManager}) {
    const handlers = [
        handle("get").with({method: "GET", path: pathGetter}).build(),
        handle("count").with({method: "GET", path: (d, e) => pathGetter(d, e) + "/count"}).build(),
        handle("save").with({method: "POST", path: e => {
            e.origin = "cms";
            return `/customer/${e.customer_id}/cart`;
        }}).build(),
        handle("update").with({method: "PUT", path: e => `/customer/${e.customer.id ? e.customer.id : e.customer}/cart/${e.id}`}).build(),
        handle("delete").with({method: "DELETE", path: e => `/customer/${e.customer.id ? e.customer.id : e.customer}/cart/${e.id}`}).build()
    ];

    return serve(handlers).using(requestManager).build();
}