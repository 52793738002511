import {Container, Grid, Box, withStyles, Avatar, Typography} from "@material-ui/core";
import {Work} from "@material-ui/icons";
import clsx from "clsx";

const WIP = withStyles({
    avatar: {
        height: "250px",
        width: "250px"
    },
    icon: {
        height: "65%",
        width: "65%"
    },
    text: {
        textAlign: "center",
        color: "grey"
    },
    bold: {
        fontWeight: "bold"
    }
})(
    ({classes}) => {
        return <Container maxWidth={false}>
            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <Avatar className={classes.avatar}>
                            <Work className={classes.icon} />
                        </Avatar>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="p" variant="h2" className={clsx([classes.text, classes.bold])}>Work in progress</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="p" variant="body1" className={classes.text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    }
);

export default WIP;