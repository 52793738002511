import React, {useState, Fragment} from "react";
import {MoreVert} from "@material-ui/icons";
import {Menu, MenuItem, IconButton} from "@material-ui/core";

/**
 * @typedef {{
 *      operations: {
 *          label: string,
 *          handler: Function,
 *          disabled?: boolean
 *      }[]
 * }} OperationsMenuProps
 */

/**
 * @param {OperationsMenuProps} param0
 */
const Component = ({operations}) => {
    const [ref, setRef] = useState(null);
    return <Fragment>
        <IconButton onClick={evt => setRef(evt.target)}>
            <MoreVert />
        </IconButton>
        <Menu open={Boolean(ref)} anchorEl={ref} onBackdropClick={() => setRef(null)}>
            {operations.map(({handler, label, disabled}) => <MenuItem onClick={handler} disabled={disabled}>
                {label}
            </MenuItem>)} 
        </Menu>
    </Fragment>
};

export default Component;