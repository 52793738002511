import React from "react";
import {
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  Divider,
  withStyles
} from "@material-ui/core";

const EntitySideMenu = withStyles({
  title: {
    fontWeight: "bold",
    margin: "0 0.75em 0.25em"
  }
})(
  /**
   * @typedef {{
   *    label: string,
   *    onClick: Function
   * }} SideMenuOperation
   * @param {{
   *    open: boolean,
   *    operations: SideMenuOperation[],
   *    title: string,
   *    variant?: string,
   *    baseId: string
   * }} param0
   */
  ({ open, operations, title, classes, variant, baseId, MenuProps, onClose }) => (
    <Menu {...MenuProps} open={open} onBackdropClick={onClose}>
      <Typography
        component="header"
        variant={variant || "h4"}
        className={classes.title}
        gutterBottom
        data-testid={`${baseId}-title`}
      >
        {title}
      </Typography>
      <Divider />
      {operations.map(({ label, onClick }) => {
        return (
          <MenuItem 
            key={label} 
            role="button" 
            onClick={onClick}
            data-testid={`${baseId}-operation-${label}-trigger`}
          >
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        );
      })}
    </Menu>
  )
);

export default EntitySideMenu;
