import {EntityServiceRepository, RequestManager} from "js-generic-utilities";
import {getServiceArgs} from "../../utils/misc-utils-functions";
import endpoints from "../endpoints";

/**
 * @param {{requestManager: RequestManager}} param0 
 */
export default function makeCustomerService({requestManager}) {
    return EntityServiceRepository.http(getServiceArgs({
        requestManager, endpoint: endpoints.customer
    }));
}