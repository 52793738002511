import makeAttributeValueBaseComponent from "../attribute-value-base-component";
import {EntityService} from "js-generic-utilities";
import {ReactComponent, ComposerProps} from "js-react-components";
import {Grid, Container} from "@material-ui/core";
import AttributeValueSearchListRenderer from "../entity-renderers/attribute-value-search-list-renderer.component";
import {AttributeValue} from "../../../domain/attribute-value";

/**
 * @type {ReactComponent<ComposerProps>} 
 */
const SearchComposer = ({collection, paginator, searchBar, loader}) => {
    return <Grid container spacing={2}>
        {loader}
        <Grid item xs={12} container alignItems="center" justify="space-between">
            <Grid item>{paginator}</Grid>
            <Grid item>{searchBar}</Grid>
        </Grid>
        <Grid item xs={12} data-testid="attribute-value-search-collection-wrapper">
            {collection}
        </Grid>
    </Grid>
}

/**
 * @param {{
 *      service: EntityService<"get">,
 *      onSelect: (value: AttributeValue) => void
 * }} param0 
 */
export default function makeAttributeValueSearchComponent({service}) {
    let Component = makeAttributeValueBaseComponent({
        service,
        ListRenderer: AttributeValueSearchListRenderer,
        itemsPerPage: 9999,
        defaultView: "list"
    });
    return ({onSelect, sortAttribute, sortDirection, filterAttribute, filterValue, Composer, ComposerProps}) => <Component
        operations={[
            {
                name: "search_select",
                label: "Seleziona",
                handler: onSelect
            }
        ]}
        defaultView="list"
        sortAttribute={sortAttribute}
        sortDirection={sortDirection}
        Composer={Composer}
        ComposerProps={ComposerProps}
        filterAttribute={filterAttribute}
        filterValue={filterValue}
    />;
}