import {makeProductService, makeVariableProductService} from "./services/product/product.service";
import {RequestManager, ObserverPool} from "js-generic-utilities";
import makeCategoryService from "./services/category/category-service";
import makeAttributeService from "./services/attribute/attribute-service";
import makeAttributeValueService from "./services/attribute/attribute-value-service";
import makeAlertService from "./services/alert/alert-service";
import makeAttributeBaseComponent from "./components/attribute/attribute-base-component";
import makeAlertBaseComponent from "./components/alert/alert-base-component";
import makeProductBaseComponent from "./components/product/product-base-component";
import makeAttributeValueBaseComponent from "./components/attribute/attribute-value-base-component";
import makeCategoryBaseComponent from "./components/category/category-base-component";
import makeCustomerBaseComponent from "./components/customer/customer-base-component";
import makeBasketStatService from "./services/basket/basket-stat-service";
import makeBasketStatBaseComponent from "./components/basket-stat/basket-stat-base-component";
import makeBasketBaseComponent from "./components/basket/basket-base-component";
import makeProductStatBaseComponent from "./components/product-stat/product-stat-base-component";
import makeBasketProductBaseComponent from "./components/basket-product/basket-product-base-component";
import makeBaseProductSearch, { AltSearchComposer } from "./components/product/search/product-search.component";
import makeAttributeValueSearch from "./components/attribute/search/attribute-value-search.component";
import makeCategorySearch from "./components/category/search-component/category-search-component";
import makeImageSearch from "./components/image/search-component/image-search-component";
import makeImageComponent from "./components/image/image-base-component";
import makeProductCategoryComponent from "./components/product-category/product-category-base-component";
import makeProductCostComponent from "./components/product-cost/product-cost-base-component";
import makeProductImagecomponent from "./components/product-image/product-image-base-component";
import makeProductCategoryService from "./services/category/product-category.service";
import makeProductImageService from "./services/image/product-image-service";
import makeProductVariantService from "./services/product/product-variant-service";
import makeCustomerService from "./services/customer/customer.service";
import makeBasketService from "./services/basket/basket-service";
import makeBasketProductService from "./services/basket/basket-product-service";
import makeProductStatService from "./services/product/product-stat.service";
import makeProductSearch from "./components/product/search/product-search.component";
import makeProductQuantityAlterationBaseComponent from "./components/product-quantity-alteration/product-quantity-alteration-base-component";
import makeProductQuantityAlterationService from "./services/product/product-quantity-alteration-service";
import makeProductCostService from "./services/product/product-cost-service";
import FakeRequestManager from "./testing/fake-request-manager";
import DocumentService from "./services/auxiliary/document-service";
import WindowService from "./services/auxiliary/window-service";
import ErrorService from "./services/auxiliary/error-service";
import makeImageService from "./services/image/image-service";
import BaseProductVariantList from "./components/product/entity-renderers/base-product-variant-list.component";
import ProductListRenderer from "./components/product/entity-renderers/product-list-renderer.component";
import ProductQuantityAlterationList from "./components/product/entity-renderers/product-quantity-alteration-list.component";
import BaseProductEditor from "./components/product/entity-editors/base-product/base-product-editor.component";
import SimpleProductEditor from "./components/product/entity-editors/base-product/simple-product-editor.component";
import CustomerEditor from "./components/customer/entity-editors/customer-entity-editor.component";
import BasketSideViewService from "./components/basket/side-view/basket-side-view.service";
import ProductCard from "./components/product/entity-renderers/product-card.component";
import {makePaginator, wrapEdit} from "js-react-components";
import AlertService from "./components/auxiliary/alert-component/alert-service";
import {makeComposer} from "./components/auxiliary/base-composer.component";
import CustomerMeta from "./domain/customer";
import ProductVariantEditor from "./components/product/entity-editors/product-variant/product-variant-editor.component";
import ProductEditorRenderer from "./components/product/entity-editors/product-editor-renderer.component";
import BasketProductList from "./components/basket/entity-renderer/basket-product-list-renderer.component";
import makeAttributeSearch from "./components/attribute/search/attribute-search.component";
import ProductAlterationAdder from "./components/product-quantity-alteration/entity-editors/product-quantity-alteration-adder";
import ProductQuantityAlterationEditor from "./components/product-quantity-alteration/entity-editors/product-quantity-alteration-editor";
import {
    ComponentsContext as BaseComponentsContext, ListCollectionRenderer, 
    EntityBaseComponent, EntityBaseComponentBuilder, EntityServiceWrapper
} from "js-react-components";
import {FetchRequestManager} from "./utils/fetch-request-manager";
import { Fragment } from "react";
import { makeCustomerCard } from "./components/customer/entity-renderers/customer-card-renderer";
import { BasketSideViewRendererWrapper } from "./components/basket/side-view/basket-side-view.component";
import { Box, Grid, Container, Typography } from "@material-ui/core";
import ConfirmationDialogService from "./components/auxiliary/confirmation-dialog/confirmation-dialog.service";
import { makeConfirmationDialog } from "./components/auxiliary/confirmation-dialog/confirmation-dialog.component";
import DelayedExecutorService from "./services/auxiliary/delayed-executor.service";
import ProductMeta from "./domain/product";
import BasketProductListEditorComponent from "./components/basket/entity-editor/basket-product-list-editor.component";
import CategoryListRenderer from "./components/category/entity-renderers/category-list-renderer.component";
import AttributeSearchListRenderer from "./components/attribute/entity-renderers/attribute-search-list-renderer.component";
import AttributeListRendererValueSearch from "./components/attribute/entity-renderers/attribute-list-renderer-value-search.component";
import AttributeValueSearchListRenderer from "./components/attribute/entity-renderers/attribute-value-search-list-renderer.component";
import onFetchFake from "./testing/fake-fetch";

const EditorCollectionRenderer = ({
    elements, onSave, onDelete, EntityRenderer, onOperation, loader
}) => {
    console.log(elements);
    return <Fragment>
        {loader}
        {elements ? <EntityRenderer 
            element={elements[0]} 
            onSave={onSave} 
            onDelete={onDelete} 
            onOperation={onOperation}
        /> : null}
    </Fragment>;
};

const EditorComposer = ({collection, loader}) => <Fragment>
    <Box position="relative">
        {loader}
        {collection}
    </Box>
</Fragment>;

export function initServices(requestManager) {
    const costService = makeProductCostService({requestManager});
    return {
        attribute: makeAttributeService({requestManager}),
        attributeValue: makeAttributeValueService({requestManager}),
        category: makeCategoryService({requestManager}),
        product: makeProductService({requestManager, costService}),
        image: makeImageService({requestManager}),
        variableProduct: makeVariableProductService({requestManager}),
        variableProductAlt: makeVariableProductService({requestManager, endpoint: "/variable-product"}),
        productVariant: makeProductVariantService({requestManager}),
        productCategory: makeProductCategoryService({requestManager}),
        productImage: makeProductImageService({requestManager}),
        productStat: makeProductStatService({requestManager}),
        customer: makeCustomerService({requestManager}),
        basket: makeBasketService({requestManager}),
        basketStat: makeBasketStatService({requestManager}),
        basketProduct: makeBasketProductService({requestManager}),
        alert: makeAlertService({requestManager}),
        productCost: costService,
        delayedExecutorService: new DelayedExecutorService(),
        productQuantityAlteration: makeProductQuantityAlterationService({requestManager}),
        categoryComponent: new ObserverPool(),
        productCategoryComponent: new ObserverPool(),
        basketComponent: new ObserverPool(),
        productImageComponent: new ObserverPool(),
        productCostComponent: new ObserverPool(),
        basketPool: new ObserverPool(),
        documentService: new DocumentService(document),
        windowService: new WindowService(window),
        basketSideView: BasketSideViewService.make(),
        errorService: new ErrorService(),
        alertService: AlertService.make(),
        deleteDialogService: ConfirmationDialogService.make()
    };
}

const BaseProductEditorWrapped = (props) => props.element && (props.element.type === "variable") ? <BaseProductEditor {...props} /> : <SimpleProductEditor {...props} />

export function initComponents(services, requestManager) {
    return {
        Attribute: makeAttributeBaseComponent({
            service: services.attribute, 
            alertService: services.alertService,
            deleteDialogService: services.deleteDialogService,
            itemsPerPage: 8
        }),
        AlertBaseComponent: makeAlertBaseComponent({
            service: services.alert,
            alertService: services.alertService,
            deleteDialogService: services.deleteDialogService
        }),
        AlertDashboard: makeAlertBaseComponent({
            service: services.alert,
            alertService: services.alertService,
            deleteDialogService: services.deleteDialogService,
            itemsPerPage: 6
        }),
        DeleteDialog: makeConfirmationDialog({
            title: "Conferma cancellazione",
            confirmLabel: "Conferma",
            closeLabel: "Annulla",
            maxWidth: "md",
            contentTypographyProps: {
                variant: "body1"
            },
            titleTypographyProps: {
                variant: "h3",
                style: {
                    fontWeight: "bold",
                    color: "rgb(111, 111, 111)"
                }
            },
            testID: "confirmation-dialog"
        }),
        AttributeValue: makeAttributeValueBaseComponent({
            service: services.attributeValue, 
            alertService: services.alertService
        }),
        AttributeValueAlt: makeAttributeValueBaseComponent({
            service: services.attributeValue, 
            alertService: services.alertService,
            itemsPerPage: 10
        }),
        Category: makeCategoryBaseComponent({
            service: services.category, 
            alertService: services.alertService,
            deleteDialogService: services.deleteDialogService,
            ListEntityRenderer: CategoryListRenderer,
            itemsPerPage: 20
        }),
        BaseProductSearch: makeBaseProductSearch({
            service: services.variableProductAlt, 
            select: true, 
            alertService: services.alertService,
            defaultView: "grid"
        }),
        ProductSearch: makeProductSearch({
            service: services.product, 
            alertService: services.alertService
        }),
        SelectableProductSearch: makeProductSearch({
            service: services.product, 
            alertService: services.alertService
        }),
        ProductSelectSearch: makeProductSearch({
            service: services.product, 
            select: true,
            alertService: services.alertService
        }),
        BasketProductSelectSearch: makeProductSearch({
            service: makeProductService({requestManager, includeVariants: true}), 
            select: true, 
            selectable: v => v.isBasketable(),
            alertService: services.alertService
        }),
        ProductStat: makeProductStatBaseComponent({
            service: services.productStat, 
            alertService: services.alertService
        }),
        BasketStat: makeBasketStatBaseComponent({
            service: services.basketStat, 
            alertService: services.alertService
        }),
        SideViewProductSearch: makeBaseProductSearch({
            service: services.product, 
            EntityRenderer: ProductCard,
            defaultView: "list",
            itemsPerPage: 10,
            Paginator: makePaginator({}, "", {
                hideItemsPerPage: true,
                defaultItemsPerPage: 10,
                buttons: true
            }),
            select: true,
            composer: AltSearchComposer
        }),
        AttributeValueSearch: makeAttributeValueSearch({
            service: services.attributeValue,
            alertService: services.alertService
        }),
        AttributeSearch: makeAttributeSearch({
            service: services.attribute,
            alertService: services.alertService
        }),
        AttributeSearchAlt: makeAttributeSearch({
            service: services.attribute,
            alertService: services.alertService,
            ListEntityRenderer: AttributeListRendererValueSearch,
            defaultView: "list"
        }),
        VariantGenerationAttributeSearch: makeAttributeSearch({
            service: services.attribute,
            defaultView: "list",
            ListEntityRenderer: AttributeSearchListRenderer,
            composer: ({loader, filterer, paginator, searchBar, collection}) =>  <Fragment>
                {loader}
                <Grid item>{searchBar || filterer}</Grid> 
                <Grid item xs={12} style={{marginTop: "1em"}}>{collection}</Grid>
                <Grid item xs={12}>{paginator}</Grid>
            </Fragment>
        }),
        ImageSearch: makeImageSearch({
            service: services.image
        }),
        Image: makeImageComponent({
            service: services.image, 
            alertService: services.alertService,
            deleteDialogService: services.deleteDialogService
        }),
        CategorySearch: makeCategorySearch({
            service: services.category
        }),
        Product: makeProductBaseComponent({
            service: services.product, 
            alertService: services.alertService,
            windowService: services.windowService,
            deleteDialogService: services.deleteDialogService
        }),
        ProductList: makeProductBaseComponent({
            service: services.product, 
            alertService: services.alertService,
            windowService: services.windowService,
            defaultView: "list",
            itemsPerPage: 10,
            ListEntityRenderer: props => <ProductListRenderer {...props} select selectable />
        }),
        Basket: makeBasketBaseComponent({
            service: services.basket,
            windowService: services.windowService,
            basketSideViewService: services.basketSideView,
            alertService: services.alertService,
            deleteDialogService: services.deleteDialogService
        }),
        BasketProduct: makeBasketProductBaseComponent({
            service: services.basketProduct, 
            alertService: services.alertService
        }),
        Customer: makeCustomerBaseComponent({
            service: services.customer, 
            windowService: services.windowService, 
            alertService: services.alertService,
            deleteDialogService: services.deleteDialogService
        }),
        CustomerSearch: makeCustomerBaseComponent({
            service: services.customer, 
            windowService: services.windowService, 
            alertService: services.alertService,
            EntityRenderer: makeCustomerCard(true),
            composer: makeComposer({
                EditRenderer: CustomerEditor,
                meta: CustomerMeta,
                config: {hideAdder: true}
            })
        }),
        BaseProduct: makeProductBaseComponent({
            service: services.variableProduct, 
            ListEntityRenderer: BaseProductVariantList,
            Editor: BaseProductEditorWrapped,
            basketProductService: services.basketProduct,
            basketSideViewService: services.basketSideView, 
            windowService: services.windowService, 
            alertService: services.alertService,
            deleteDialogService: services.deleteDialogService,
            itemsPerPage: 24
        }),
        BaseProductAlt: makeProductBaseComponent({
            service: services.variableProductAlt, 
            ListEntityRenderer: BaseProductVariantList,
            basketProductService: services.basketProduct,
            windowService: services.windowService, 
            alertService: services.alertService,
            deleteDialogService: services.deleteDialogService,
            itemsPerPage: 10,
            composer: ({loader, collection, filterer, paginator}) => <Box position="relative">
                {loader}
                <Container maxWidth={false} disableGutters style={{paddingRight: "1em"}}>
                    <Grid container spacing={2} justify="space-between" alignItems="center">
                        <Grid item>
                            <Box display="flex" alignItems="center">
                                <Typography component="p" variant="h4" style={{marginRight: "1em"}}>
                                    Filtra per prodotto base
                                </Typography>
                                {filterer}
                            </Box>
                        </Grid>
                        <Grid item>{paginator}</Grid>
                        <Grid item xs={12}>{collection}</Grid>
                    </Grid>
                </Container>
            </Box>
        }),
        ProductEditor: makeProductBaseComponent({
            service: services.product,
            alertService: services.alertService,
            ListCollectionRenderer: EditorCollectionRenderer,
            ListEntityRenderer: ProductEditorRenderer,
            defaultView: "list",
            composer: EditorComposer
        }),
        BasketSideEditor: makeBasketBaseComponent({
            service: services.basket,
            alertService: services.alertService,
            GridRenderer: EditorCollectionRenderer,
            deleteDialogService: services.deleteDialogService,
            EntityRenderer: BasketSideViewRendererWrapper,
            defaultView: "grid",
            composer: EditorComposer
        }),
        AttributeEditor: makeAttributeBaseComponent({
            service: services.attribute,
            ListCollectionRenderer: EditorCollectionRenderer,
            defaultView: "list",
            composer: EditorComposer
        }),
        ImageEditor: makeImageComponent({
            service: services.image, 
            alertService: services.alertService,
            CollectionRenderer: EditorCollectionRenderer
        }),
        BasketEditor: makeBasketBaseComponent({
            service: services.basket,
            windowService: services.windowService,
            alertService: services.alertService,
            basketSideViewService: services.basketSideView,
            deleteDialogService: services.deleteDialogService,
            onAfterDelete: () => window.history.back(),
            ListCollectionRenderer: EditorCollectionRenderer,
            ListEntityRenderer: /*(props) => <BasketProductList 
                {...props} 
                onAfterSave={() => services.basketSideView.reload()} 
                onAfterDelete={() => services.basketSideView.reload()}
                defaultShown
            />*/ BasketProductListEditorComponent,
            defaultView: "list",
            composer: ({collection}) => collection
        }),
        ProductVariant: makeProductBaseComponent({
            service: services.productVariant,
            alertService: services.alertService,
            ListEntityRenderer: ProductListRenderer,
            Editor: ProductVariantEditor,
            basketProductService: services.basketProduct,
            basketSideViewService: services.basketSideView,
            deleteDialogService: services.deleteDialogService
        }),
        ProductQuantityAlteration: makeProductQuantityAlterationBaseComponent({
            service: services.productQuantityAlteration,
            alertService: services.alertService,
            Composer: makeComposer({
                EditRenderer: ProductQuantityAlterationEditor,
                EntityRenderer: ProductAlterationAdder, 
                config: {
                    hideAdder: false
                }
            })
        }),
        ProductQuantityAlterationSigh: makeProductQuantityAlterationBaseComponent({
            service: services.productQuantityAlteration,
            alertService: services.alertService,
            Composer: makeComposer({
                EntityRenderer: ProductAlterationAdder, 
                config: {
                    hideAdder: true
                }
            })
        }),
        ProductQuantityAlterationAlt: makeProductQuantityAlterationBaseComponent({
            service: services.productQuantityAlteration,
            alertService: services.alertService,
            Composer: makeComposer({
                EditRenderer: ProductQuantityAlterationEditor,
                config: {noLabels: true}
            })
        }),
        ProductQuantityAlterationReal: makeProductBaseComponent({
            service: services.product,
            alertService: services.alertService,
            ListEntityRenderer: ProductQuantityAlterationList,
            composer: makeComposer({Adder: () => null})
        }),
        ProductCategory: makeProductCategoryComponent({
            service: services.productCategory, 
            alertService: services.alertService,
            componentService: services.productCategoryComponent
        }),
        ProductCost: makeProductCostComponent({
            service: services.productCost, 
            alertService: services.alertService,
            componentService: services.productCostComponent
        }),
        ProductImage: makeProductImagecomponent({
            service: services.productImage, 
            alertService: services.alertService,
            componentService: services.productImageComponent
        })
    }
}

export function configStuff() {
    EntityBaseComponentBuilder.configure({
        collectionRenderers: {
            list: ListCollectionRenderer
        },
        BaseComponent: EntityBaseComponent
    });
      
    EntityServiceWrapper.configure({isNew: e => !Boolean(e._links)});
}

/**
 * @param {boolean} debug
 * @return {RequestManager}
 */
export function initRequestManager(debug, url, headers) {
    //return debug ? (new FakeRequestManager()) : (new FetchRequestManager(url, headers || {}));
    headers = headers || {};
    headers["content-type"] = "application/json";
    return new FetchRequestManager(url, headers, debug ? onFetchFake : fetch);
}