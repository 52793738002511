import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeProductCostService({requestManager}) {

    const handlers = [
        handle("get").with({
            method: "GET", 
            path: (d, e) => `/product/${e.filterValue}/cost`
        }).build(),
        handle("count").with({
            method: "GET", 
            path: (d, e) => `/product/${e.filterValue}/cost/count`
        }).build(),
        handle("save").with({
            method: "POST", 
            path: e => e.reset_variant_costs ? `/product/${e.productId}/cost?resetVariantCosts=1` : `/product/${e.productId}/cost`
        }).build(),
        handle("delete").with({
            method: "DELETE", 
            path: e => `/product/${e.productId}/cost/${e.productList.id}`
        }).build(),
        handle("update").with({
            method: "put", 
            path: e => e.reset_variant_costs ? `/product/${e.productId}/cost/${e.productList.id}?resetVariantCosts=1` : `/product/${e.productId}/cost/${e.productList.id}`
        }).build()
    ];

    return serve(handlers).using(requestManager).build();
}