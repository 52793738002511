import {FakeRepository} from "js-react-components";
import ProductCategoryMeta from "../domain/product-category";
import ProductImageMeta from "../domain/product-image";
import BasketProductMeta from "../domain/basket-product";
import ProductStatMeta from "../domain/product-stat";
import ProductCostMeta from "../domain/product-cost";

import attributes from "./data/attributes";
import categories from "./data/categories";
import products from "./data/products";
import productStats from "./data/product-stats";
import images from "./data/images";
import attributeValues from "./data/attribute-values";
import prodCats from "./data/product-categories";
import prodImgs from "./data/product-images";
import customers from "./data/customers";
import baskets from "./data/baskets";
import basketStats from "./data/basket-stats";
import basketProducts from "./data/basket-products";
import prodQtyAlters from "./data/product-quantity-alterations";
import productCosts from "./data/product-cost";

const fakeDelay = 0;

let productQuantityAlterations = new FakeRepository(prodQtyAlters, fakeDelay);
let productRep = new FakeRepository(products, fakeDelay);
let variableProducts = new FakeRepository(products.slice(0, 8), fakeDelay);
let productVariants = new FakeRepository(products.slice(8), fakeDelay);
let attributeRep = new FakeRepository(attributes, fakeDelay);
let catRep = new FakeRepository(categories, fakeDelay);
let imageRep = new FakeRepository(images, fakeDelay);
let basketStatRep = new FakeRepository(basketStats, fakeDelay);
let attributeValuesRep = new FakeRepository(attributeValues, fakeDelay);
let productCategories = new FakeRepository(prodCats, null, ProductCategoryMeta.matcher);
let productImages = new FakeRepository(prodImgs, null, ProductImageMeta.matcher);
let customerRep = new FakeRepository(customers);
let basketRep = new FakeRepository(baskets);
let basketProductsRep = new FakeRepository(basketProducts, null, BasketProductMeta.matcher);
let productStatsRep = new FakeRepository(productStats, null, ProductStatMeta.matcher);
let productCostRep = new FakeRepository(productCosts, null, ProductCostMeta.matcher);

const aux = e => {
    if(!(e instanceof Array)) {
        e.id = e.id || Math.floor(Math.random()*60000+1);
    }
};

productRep.config({onAfterSave: aux});
variableProducts.config({onAfterSave: aux});
productVariants.config({onAfterSave: aux});

export {
    productRep as products, 
    productImages, 
    productCategories, 
    catRep as categories, 
    attributeValuesRep as attributeValues, 
    imageRep as images, 
    attributeRep as attributes, 
    variableProducts, 
    productVariants, 
    customerRep as customers, 
    basketRep as baskets,
    basketProductsRep as basketProducts, 
    productQuantityAlterations, 
    productStatsRep as productStats,
    basketStatRep as basketStats,
    productCostRep as productCosts
};