import React from "react";
import { inject } from "js-react-components";
import servicesContext from "../../../../contexts/services.context";
import AsyncEntityLoader from "../../../auxiliary/async-entity-loader/async-entity-loader.component";
import { CircularProgress, Typography } from "@material-ui/core";
import { CollectionUtilityWrapper } from "js-generic-utilities";
import ProductCostMeta, {ProductCost} from "../../../../domain/product-cost";
import {round} from "../../../../utils/misc-utils-functions";

const CustomLoadingComponent = () => <CircularProgress size="1em" style={{color: "white"}} />;

export default inject(
    servicesContext,
    ["productCost"],
    ({product, productCost, TypographyProps}) => product && <AsyncEntityLoader
      collection
      id={product.id}
      onGet={k => productCost.serve("get", null, {filterAttribute: "product_id", filterValue: k})}
      Renderer={({element}) => {
        console.log(element);
        if(element) {
          /**
           * @type {CollectionUtilityWrapper<ProductCost>}
           */
          const wrapped = CollectionUtilityWrapper.wrap(element, ProductCostMeta.matcher);
          const els = wrapped.filter(e => parseFloat(e.cost) > 1e-2);
          let text = "";
          if(els.count() > 1) {
              text = `${round(els.min("cost"), 2)}€ - ${round(els.max("cost"), 2)}€`;
          } else {
              text = (els.count() > 0) ? `${els.at(0).cost}€` : `0€`;
          }
          return <Typography {...TypographyProps}>{text}</Typography>;
        }
        return null;
      }}
      LoadingComponent={CustomLoadingComponent}
    />
  );