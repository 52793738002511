import {ObserverPool, makeObserverPool, notify, observe, remove} from "js-generic-utilities";
import {
    ConfirmationDialogEvent, 
    ConfirmationDialogInput, 
    ConfirmationDialogObserverPool, 
    ConfirmationDialogUpdateFunction
} from  "./defines";

const removeHandler = (pool, event, handler) => remove(handler).from(pool).on(event).do();

export default class ConfirmationDialogService {

    /**
     * @param {ObserverPool}
     * @return {ConfirmationDialogService}
     */
    static make(pool) {
        pool = pool || makeObserverPool();
        let service = new ConfirmationDialogService(pool);
        return service;
    }

    /**
     * @type {ConfirmationDialogObserverPool}
     */
    _pool;

    /**
     * @param {ConfirmationDialogObserverPool} pool 
     */
    constructor(pool) {
        this._pool = pool;
    }

    /**
     * @param {ConfirmationDialogInput} stuff 
     */
    show(stuff) {
        notify(this._pool).of("show").with(stuff);
    }

    hide() {
        notify(this._pool).of("hide").withNull();
    }

    /**
     * @param {ConfirmationDialogUpdateFunction} handler 
     */
    onShow(handler) {
        observe(this._pool).on("show").with(handler);
    }

    /**
     * @param {Function} handler 
     */
    onHide(handler) {
        observe(this._pool).on("hide").with(handler);
    }

    /**
     * @param {ConfirmationDialogUpdateFunction} handler 
     */
    removeShowHandler(handler) {
        removeHandler(this._pool, "show", handler);
    }

    /**
     * @param {Function} handler 
     */
    removeHideHandler(handler) {
        removeHandler(this._pool, "hide", handler);
    }
}