import React, { Fragment } from "react";
import {ListItem, ListItemText, ListItemAvatar, Avatar, Typography, withStyles, ListItemSecondaryAction, List, Grid} from "@material-ui/core";
import { imgPlaceholder } from "../../../constants";
import {wrap} from "../../../domain/product";
import { Warning } from "@material-ui/icons";

const Component = withStyles({
    img: {
        maxWidth: "100%"
    },
    listItem: {
        padding: 0
    },
    listItemText: {
        margin: 0
    },
    stock: {
        color: "green",
        fontWeight: "bold"
    }
})(
    ({element, classes, newStock}) => {
        const hasNewStock = newStock && !isNaN(newStock);
        if(hasNewStock) {
            newStock = parseInt(element.stock) + parseInt(newStock);
        }
        return <List>
            <ListItem key={element.id} className={classes.listItem}>
                <ListItemAvatar>
                    <Avatar>
                        <img 
                            src={element.image || imgPlaceholder} 
                            alt={element.name} 
                            className={classes.img} 
                        />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary={element.name} 
                    secondary={wrap(element).printType()} 
                    className={classes.listItemText} 
                />
                <ListItemSecondaryAction>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography component="span" variant="body1">Stock</Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="span" variant="body1" className={classes.stock}>{element.stock}</Typography>
                        </Grid>
                        {hasNewStock && <Fragment>
                            <Grid item>
                                <Typography component="span" variant="body1">&rarr;</Typography>
                            </Grid>
                            <Grid item>
                                <Typography component="span" variant="body1" className={classes.stock}>{(newStock > 0) ? newStock : <Warning style={{color: "red"}} />}</Typography>
                            </Grid>
                        </Fragment>}
                    </Grid>
                </ListItemSecondaryAction>
            </ListItem>
        </List>;
    }
);

export default Component;