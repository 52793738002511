import React, {Fragment} from "react";
import {
  Card,
  CardHeader,
  CardActionArea,
  makeStyles,
  Button,
  CardActions,
  Avatar,
  CardContent
} from "@material-ui/core";
import {AccountBox} from "@material-ui/icons";
import {Product, wrap} from "../../../domain/product";
import {EntityRendererProps, ReactComponent} from "js-react-components";

const useStyles = makeStyles((theme) => ({
  subheader: {
    color: "green"
  },
  title: {
    fontWeight: "bold"
  },
  image: {
    display: "block",
    maxWidth: "calc(min(100%, 200px))",
    margin: "auto"
  },
  contentText: {
    textAlign: "center"
  }
}));

/**
 * @type {ReactComponent<EntityRendererProps<Product>>}
 */
const ProductSearchCard = ({ element, onOperation, baseId, sideImage }) => {
  baseId = baseId || "product-search-card";
  const classes = useStyles();
  const imgFragment = <img
    className={classes.image}
    src={element.image}
    alt={element.name}
    title={element.name}
  />;
  return (
    <Fragment key={element.id}>
      <Card data-testid={`${baseId}-${element.id}`} key={`${element.id}-card`}>
        <CardHeader
          avatar={
            <Avatar>
              {sideImage ? imgFragment : <AccountBox />}
            </Avatar>
          }
          title={element.name}
          titleTypographyProps={{
            className: classes.title,
            "data-testid": `${baseId}-${element.id}-name-text`
          }}
          subheader={wrap(element).printType()}
          subheaderTypographyProps={{
            className: classes.subheader,
            "data-testid": `${baseId}-${element.id}-description-text`
          }}
        />
        {!sideImage && <CardContent>
          {imgFragment}
        </CardContent>}
        {onOperation && <CardActionArea>
          <CardActions>
            <Button
              key="edit"
              variant="text"
              color="primary"
              fullWidth
              onClick={() => onOperation("select", element)}
              data-testid={`product-search-card-${element.id}-select`}
            >
              Seleziona
            </Button>
          </CardActions>
        </CardActionArea>}
      </Card>
    </Fragment>
  );
};

export default ProductSearchCard;
