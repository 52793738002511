import React from "react";
import {
    EntityBaseComponentBuilder, 
    render,
    makePaginator,
    makeTableRenderer,
    ListCollectionRenderer,
    makeSearchBar
} from "js-react-components";
import {EntityService, HttpOperation, ObserverPool} from "js-generic-utilities";
import ProductQuantityAlterationMeta, {
    ProductQuantityAlteration, 
    ProductQuantityAlterationAttributes, 
    ProductQuantityAlterationIndices, 
    ProductQuantityAlterationSorters
} 
from "../../domain/product-quantity-alteration";
import Editor from "./entity-editors/product-quantity-alteration-editor";
import LoadingComponent from "../auxiliary/loading.component";
import ListRenderer from "./entity-renderers/list-renderer.component";
import TableEntityRenderer from "./entity-renderers/table-renderer.component";
import {makeComposer} from "../auxiliary/base-composer.component";
import {wrapRenderer} from "../auxiliary/missing-elements.component";
import { Warning } from "@material-ui/icons";
import { texts } from "../../constants";
import {makeDateFilterer} from "../auxiliary/filterers/simple-date-filterer.component";

const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />;
const Filterer = makeDateFilterer({
    attribute: "date", 
    resolution: "months", 
    testID: "basket-filterer",
    TypographyProps: {
        component: "span",
        variant: "h4",
        style: {
            color: "black"
        }
    }
});
const DefaultComposer = makeComposer({
    EditRenderer: Editor, 
    config: {}
});
const wrapOptions = {
    avatarSize: 100,
    title: texts.missing_elements_title("caricamento/reso"),
    body: texts.missing_elements_body,
    Icon: Warning
};

/**
 * @typedef {"list"} ProductQuantityAlterationViews
 * @typedef {EntityBaseComponentBuilder<"refresh", ProductQuantityAlteration, ProductQuantityAlterationViews, ProductQuantityAlterationIndices, ProductQuantityAlterationSorters, ProductQuantityAlterationAttributes>} ProductQuantityAlterationBaseComponentBuilder
 * @param {{
 *      componentService: ObserverPool<"refresh">,
 *      service: EntityService<HttpOperation>
 * }} param0 
 */
export default function makeComponent({service, componentService, Composer, alertService}) {
    /**
     * @type {ProductQuantityAlterationBaseComponentBuilder}
     */
    let builder = render(service);

    const TableRenderer = makeTableRenderer([
        {
            name: "product",
            label: "Prodotto",
            sortable: false
        }, 
        {
            name: "type",
            label: "Tipo",
            sortable: true
        }, 
        {
            name: "amount",
            label: "Qtà",
            sortable: true
        }, 
        {
            name: "date",
            label: "Data",
            sortable: true
        }
    ], {
        sorting: true,
        fixedLayout: true
    });

    builder.withMeta(ProductQuantityAlterationMeta)
        .withList({
            Entity: ListRenderer,
            Collection: ListCollectionRenderer
        })
        .withTable({
            Entity: TableEntityRenderer,
            Collection: wrapRenderer(TableRenderer, wrapOptions)
        })
        .withFilter({attribute: "product_id"})
        .withService(componentService)
        .withDefaultView("list")
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .editEntityWith(Editor)
        .withComponents({
            Alert: () => null,
            Loader: Loader,
            Filterer: Filterer,
            Sorter: () => null,
            Paginator: makePaginator({}, "product-quantity-alteration-paginator", {
                defaultItemsPerPage: 10,
                hideItemsPerPage: true,
                buttons: true,
                allowFirstLastPages: true
            })
        })
        .withBounds({start: 0, end: 10})
        .withCallbacks({
            onAfterSave: e => e.message && alertService.show({
                message: e.message,
                severity: "warning"
            }),
            onError: e => alertService.show({
                message: e,
                severity: "error"
            })
        });

    const Component = builder.compose(Composer || DefaultComposer);

    return ({productId, Composer, ComposerProps, defaultView}) => {
        const AdditionalProps = Composer && ComposerProps ? {Composer, ComposerProps} : {ComposerProps};
        return <Component 
            {...AdditionalProps}
            filterValue={productId} 
            filterAttribute={productId ? "product_id" : null}
            defaultView={defaultView}
        />
    };
}
