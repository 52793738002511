import React from "react";
import {EntityRendererProps, ReactComponent} from "js-react-components";
import {Image} from "../../../domain/image";
import {Paper, makeStyles, Tooltip} from "@material-ui/core";
import ImageSearchContext from "../../image/search-component/context/image-search-context";
import clsx from "clsx";
import { isSelected } from "../../auxiliary/auxiliary-component-functions";

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    image: {
        maxWidth: "100%",
        display: "block"
    },
    unselected: {
        cursor: "pointer",
        "&:hover": {
            boxShadow: "2px 2px 2px 2px rgb(111, 111, 111)"
        }
    },
    selected: {
        opacity: 0.4
    }
}));
  

/**
 * @type {ReactComponent<EntityRendererProps<Image>>}
 */
const Component = ({element, onOperation}) => {
    const classes = useStyles();
    return <ImageSearchContext.Consumer>
        {({selectedImages}) => {
            const sel = selectedImages && selectedImages.length && isSelected(element, selectedImages);
            return <Paper className={classes.wrapper}>
                <Tooltip arrow title={sel ? "Hai già selezionato questa immagine" : "Clicca per selezionare l'immagine"}>
                    <img 
                        role="button"
                        onClick={() => !sel && onOperation("select", element)}
                        className={clsx([
                            classes.image, 
                            sel ? classes.selected : classes.unselected 
                        ])} 
                        src={element.url || element.full_url} 
                        alt={element.name} 
                    />
                </Tooltip>
            </Paper>;
        }}
    </ImageSearchContext.Consumer>;
};

export default Component;