import {createContext} from "react";

/**
 * @type {{
 *      basketProductAdderWidth: number,
 *      sideViewWidth: number,
 *      productEditorWidth: number
 * }}
 */
const settings = {
    basketProductAdderWidth: 700,
    sideViewWidth: 330,
    productEditorWidth: 1280-96,
    basketAdderWidth: 512,
    smallScreen: window.innerWidth < 800,
    appBarHeight: 65,
    backgroundColor: "rgb(245, 245, 245)",
    username: ""
};

export {settings};
export default createContext(settings);