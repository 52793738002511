/* eslint-disable no-unused-vars */
import React, { useEffect, memo, useState, Fragment} from "react";
import ProductMeta, {Product} from "../../../domain/product";
import {inject} from "js-react-components";
import {EntityService, EntityWrapper, wrap} from "js-generic-utilities";
import ServicesContext from "../../../contexts/services.context";
import {e} from "../../../utils/misc-utils-functions";
import {Grid, FormLabel, Button, Typography, makeStyles, Container, Badge} from "@material-ui/core";
import VariableProductAsyncPrinter from "../auxiliary/variable-product-async-printer.component";
import ComponentsContext from "../../../contexts/components.context";
import DocumentService from "../../../services/auxiliary/document-service";
import clsx from "clsx";
import VariantAttributesGrid from "./variant-attributes-grid.component";
import AttributeValueSearchContext from "../../attribute/search/attribute-value-search.context";

const useStyles = makeStyles(theme => ({
    fakeInput: {
        width: 0,
        height: 0,
        padding: 0,
        border: 0
    },
    label: {
        transitionProperty: "font-size",
        transitionDuration: "750ms",
        fontWeight: "bold",
        color: "grey"
    },
    selectedLabel: {
        fontSize: "1.25em",
        color: "red"
    }
}));

/**
 * @param {Product} product 
 * @return {EntityWrapper<Product>}
 */
const makeState = product => {
    /**
     * @type {EntityWrapper<Product>}
     */
    let wrapper = wrap(product).withMeta(ProductMeta).build();
    wrapper.set("baseEditing", typeof(product.variable_product_id) !== "number");
    return wrapper;
};

/**
 * @param {EntityWrapper<Product>} state
 * @return {Product}
 */
const fromState = state => {
    let out = state.unwrap();
    delete out.baseEditing;
    return out;
}

/**
 * @typedef {{
 *      product: Product,
 *      variableProduct: EntityService<"get">,
 *      onConfirm: (p: Product) => void,
 *      AttributeValueSearch: Function,
 *      BaseProductSearch: Function,
 *      documentService: DocumentService
 * }} VariantBaseAttributeSelectionComponentProps
 */

/**
 * @param {VariantBaseAttributeSelectionComponentProps} param0
 */
function VariantBaseAttributeSelectionComponent({
    variableProduct, product, onConfirm, AttributeSearchAlt, BaseProductSearch, documentService
}) {
    const [currentProduct, setCurrentProduct] = useState(makeState(product));
    const classes = useStyles();

    const setBaseProduct = (prod) => setCurrentProduct(c => {
        if(prod instanceof Array) {
            prod = prod[0];
        }
        documentService.id("variant-base-attribute-selection-fake-input").focus();
        return c.clone().set("baseEditing", false).set("variableProduct", prod.id);
    });

    const onAttributeValueAdd = (value) => setCurrentProduct(c => {
        let cloned = c.clone();
        cloned.addTo("variantAttributeValues", value.id);
        return cloned;
    });

    const onAttributeValueRemove = (value) => setCurrentProduct(c => {
        let cloned = c.clone();
        cloned.removeFrom("variantAttributeValues", value);
        return cloned;
    });

    const setEditing = (e) => setCurrentProduct(c => c.clone().set("baseEditing", e));

    const onProductConfirm = () => setCurrentProduct(c => {
        onConfirm(fromState(c));
        return c;
    });

    useEffect(() => {
        setCurrentProduct(c => {
            let p = c.unwrap();
            p = e(product, p) ? p : product;
            return wrap(p).withMeta(ProductMeta).build().set("baseEditing", c.get("baseEditing"));
        })
    }, [product]);

    const variableProductId = currentProduct.get("variableProduct");
    const baseEditing = currentProduct.get("baseEditing");
    const attributeValues = currentProduct.get("variantAttributeValues");

    const baseProductSelectionFragment = <Grid item xs={12} container spacing={2} style={{marginLeft: "0.5em"}}>
        <Grid item container xs={12} justify="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <Typography 
                    component="header"
                    variant="h4"
                    className={clsx([classes.label, baseEditing && classes.selectedLabel])}
                >
                    Selezione prodotto base
                </Typography>
            </Grid>
            {baseEditing && <BaseProductSearch filterAttribute="type" filterValue="variable" onSelect={setBaseProduct} /> }
            {!baseEditing && <Grid item>
                <Button variant="outlined" color="primary" onClick={() => setEditing(true)}>
                    Modifica
                </Button>
            </Grid>}
        </Grid>
        {!baseEditing && <Grid item xs={12}>
            <VariableProductAsyncPrinter 
                product={currentProduct.unwrap()} 
                service={variableProduct}
            />
        </Grid>}
    </Grid>;

    const attributeSelectionFragment = <Grid item xs={12} container spacing={2}  style={{marginLeft: "0.5em"}}>
        <Grid item xs={12}>
            <Badge color="primary" badgeContent={currentProduct && attributeValues && attributeValues.length}>
                <FormLabel
                    className={clsx([classes.label, !baseEditing && classes.selectedLabel])}
                >
                    Selezione valori attributi
                </FormLabel>
            </Badge>
        </Grid>
        <Grid item xs={12} container spacing={2}>
            {(typeof(variableProductId) === "number") && !baseEditing ? <AttributeValueSearchContext.Provider value={{
                selected: attributeValues ? attributeValues : []
            }}>
                <Fragment>
                    <Grid item xs={12}>
                        <VariantAttributesGrid values={attributeValues} onRemove={onAttributeValueRemove} />
                    </Grid>
                    <Grid item xs={12}>
                        <AttributeSearchAlt 
                            sortAttribute="name" 
                            sortDirection="asc" 
                            onSelect={onAttributeValueAdd} 
                            defaultView="list"
                        />
                    </Grid>
                </Fragment>
            </AttributeValueSearchContext.Provider> : <Grid item xs={12}>
                <Typography
                    component="p"
                    variant="body1"
                    data-testid="variant-base-attribute-selection-missing-product"
                    style={{textAlign: "center"}}
                >
                    Selezionare il prodotto base prima degli attributi.
                </Typography>
            </Grid>}
            <input type="text" className={classes.fakeInput} id="variant-base-attribute-selection-fake-input" />
        </Grid>
    </Grid>;

    return <Container maxWidth={false} disableGutters>
        <Grid container spacing={4}>
            {baseProductSelectionFragment}
            {attributeSelectionFragment}
            <Grid item xs={12}>
                <Button 
                    type="button" 
                    onClick={onProductConfirm}
                    data-testid="variant-base-attribute-selection-confirm"
                    disabled={!currentProduct || !attributeValues || !attributeValues.length}
                    fullWidth
                    variant="outlined"
                    color="primary"
                >
                    Conferma
                </Button>
            </Grid>
        </Grid>
    </Container>
}

export default inject(
    ServicesContext,
    ["variableProduct", "attributeValue", "documentService"],
    inject(
        ComponentsContext,
        ["AttributeSearchAlt", "BaseProductSearch"],
        memo(VariantBaseAttributeSelectionComponent, (p, n) => e(p.product, n.product))
    )
)