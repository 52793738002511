import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeProductImageService({requestManager}) {
    const handlers = [
        handle("get").with({method: "GET", path: (d, e) => `/product/${e.filterValue}/image`}).build(),
        handle("count").with({method: "GET", path: (d, e) => `/product/${e.filterValue}/image/count`}).build(),
        handle("save").with({method: "put", path: e => `/product/${e.product_id}/image/${e.image_id}`}).build(),
        handle("delete").with({method: "DELETE", path: e => `/product/${e.product}/image/${e.id}`}).build()
    ];

    return serve(handlers).using(requestManager).build();
}