import makeEditor from "../../auxiliary/base-entity-editor.component";
import CategoryMeta, {validate} from "../../../domain/category";
import {LocalOffer} from "@material-ui/icons";

const Editor = makeEditor({
    meta: CategoryMeta,
    entityName: "Categoria",
    icon: <LocalOffer />,
    attributes: [
        {
            id: "name",
            label: "Nome",
            type: "text",
            helperText: "Nome mancante o non valido."
        },
        {
            id: "description",
            label: "Descrizione",
            type: "text",
            helperText: "Descrizione mancante o non valida."
        }
    ],
    config: {
        onValidate: validate,
        isValid: e => validate(["name", "description"], e)
    }
});

export default Editor;