import React, { Fragment, useState } from "react";
import {EntityRendererProps, BaseComponentArgs, inject} from "js-react-components";
import {Attribute} from "../../../domain/attribute";
import AttributeValueComponentContext from "../contexts/attribute-value-component.context";
import ComponentsContext from "../../../contexts/components.context";
import AdderWrapper from "../../auxiliary/editors/adder-wrapper.component";
import AttributeValueEditor from "../entity-editors/attribute-value-entity-editor.component";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from "@material-ui/core";

import {
  Folder,
  KeyboardArrowDown,
  KeyboardArrowRight
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  verticalLine: {
    width: "1px", 
    backgroundColor: "rgb(111, 111, 111)"
  },
  lineBox: {
    width: "50px",
    marginRight: "1em",
    height: "1px",
    backgroundColor: "rgb(111, 111, 111)"
  },
  listItem: {
    backgroundColor: "rgb(250, 250, 250)",
    borderRadius: "5px", 
    border: "1px solid rgb(222, 222, 222)",
    borderBottom: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  lel: {
    border: "1px solid rgb(222, 222, 222)",
    borderTop: 0,
    marginBottom: "0.5em"
  }
}));

/**
 * @param {EntityRendererProps<Attribute>} param0 
 */
const Composer = ({attribute, collection, loader }) => {
    const classes = useStyles();
    const [state, setState] = useState(false);
    const toggleState = () => setState((s) => !s);
    return (
      <Fragment>
        <ListItem 
          className={classes.listItem} 
          key={attribute.id} 
          data-testid={`attribute-list-element-${attribute.id}`}
        >
          <ListItemAvatar>
            <Avatar>
              <Folder />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={attribute.name} secondary={`${attribute.valuesNumber || "?"} valori, nome mostrato ${attribute.shownName}`} />
          <ListItemSecondaryAction>
            <ButtonGroup>
              <Button
                color="primary"
                variant="text"
                onClick={toggleState}
                data-testid={`attribute-list-element-${attribute.id}-view-values`}
                endIcon={state ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              >
                Valori
              </Button>
            </ButtonGroup>
          </ListItemSecondaryAction>
        </ListItem>
        <Box className={classes.lel}>
          <Collapse collapsedHeight={0} in={state}>
            <Box 
              margin="0.5em 1em 0 1em"
              width="calc(100% - 2em)" 
              display="flex"
              position="relative"
              flexDirection="column"
            >
                {loader}
                {collection}
            </Box>
          </Collapse>
        </Box>
      </Fragment>
    );
  };

  const AttributeListRendererValueSearch = inject(
    ComponentsContext,
    ["AttributeValueSearch"],
    ({AttributeValueSearch, element, onSelect, onOperation}) => <AttributeValueSearch 
      filterAttribute="variant_feature_id" 
      filterValue={element.id} 
      Composer={Composer}
      onSelect={e => onOperation("select", e)}
      ComposerProps={{attribute: element}}
    />
  )

  export default AttributeListRendererValueSearch;