import ServicesContext from "../../../contexts/services.context";
import {inject} from "js-react-components";
import {Paper, Container, Grid, Divider, Typography, makeStyles, Button, IconButton} from "@material-ui/core";
import {useState, useEffect} from "react";
import {EntityService, HttpOperation} from "js-generic-utilities";
import {Basket} from "../../../domain/basket";
import {make} from "../../../domain/basket-product";
import { Close } from "@material-ui/icons";
import DelayedExecutorService from "../../../services/auxiliary/delayed-executor.service";

const defaultScanTimeoutMs = 150;

const useStyles = makeStyles(theme => ({
    header: {
        color: "rgb(132, 132, 132)",
        textTransform: "uppercase"
    },
    input: {
        height: 0,
        padding: 0,
        border: 0,
        overflow: "hidden"
    },
    wrapper: {
        padding: "0.25em 0",
        border: "2px solid grey"
    },
    message: {
        textAlign: "center"
    }
}));

/**
 * @param {{
 *      product: EntityService<HttpOperation>, 
 *      basketProduct: EntityService<HttpOperation>,
 *      onError: (mex: string) => void,
 *      onAfterAdd: Function,
 *      basket: Basket,
 *      scanTimeoutMs?: number,
 *      delayedExecutorService: DelayedExecutorService
 * }} param0 
 */
const Component = ({
    product, onAfterAdd, onSave, onError, basket, onClose, scanTimeoutMs, delayedExecutorService, documentService
}) => {
    if(scanTimeoutMs === undefined) {
        scanTimeoutMs = defaultScanTimeoutMs;
    }
    const [state, setComponentState] = useState({loading: false, message: null, active: false, code: null});
    
    const toggleActive = (a) => setComponentState(s => ({...s, active: a, message: "In attesa di nuova scansione."}));

    const onInputChange = evt => {
        const v = evt.target.value;
        delayedExecutorService.delay(() => {
            setComponentState({active: true, loading: true, message: "Ricerca prodotto..."});
            product
                .serve("get", null, {filterAttribute: "code", filterValue: v})
                .then(p => {
                    if(p instanceof Array) {
                        p = p[0];
                    }
                    setComponentState({active: true, message: `Trovato prodotto ${p.name}. Aggiunta in corso...`});
                    let savePr = onSave(make({product: p, basket, quantity: 1, isMod: true}));
                    if(savePr instanceof Promise) {
                        return savePr.then(() => {
                            setComponentState({active: true, loading: false, message: "Prodotto aggiunto. In attesa di nuova scansione."});
                            onAfterAdd && onAfterAdd();
                        })
                        .catch(mex => {
                            setComponentState({active: true, loading: false, message: "In attesa di nuova scansione."});
                            onError(mex);
                        })
                    }
                })
                .catch(mex => setComponentState({
                    active: true, loading: false, message: "Prodotto non trovato."
                }))
                .finally(() => {
                    documentService.id("basket-barcode-scanner-input").value = "";
                })
            }, 
            scanTimeoutMs
        )
    };

    useEffect(() => {
        state.active && document.querySelector("[data-testid=basket-barcode-scanner-input]").focus();
    }, [state]);

    const classes = useStyles();
    return <Paper 
        component="article" 
        data-testid="basket-barcode-scanner-wrapper" 
        className={classes.wrapper}
    >
        <Container maxWidth="sm">
            <Grid container spacing={1} component="section">
                <Grid item container xs={12} justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography 
                            component="header" 
                            variant="h4" 
                            className={classes.header}
                            data-testid="basket-barcode-scanner-title"
                        >
                            Scansione codice a barre
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Button 
                            fullWidth 
                            variant="outlined"
                            color={state.active ? "primary" : "secondary"}
                            data-testid="basket-barcode-scanner-toggle"
                            onClick={() => toggleActive(true)} 
                            disabled={state.loading}
                        >
                            <Typography component="span" data-testid="basket-barcode-scanner-toggle-label">
                                {state.active ? "Scansione attiva" : "Premi per attivare la scansione"}
                            </Typography>
                        </Button>
                        <input 
                            data-testid="basket-barcode-scanner-input"
                            id="basket-barcode-scanner-input"
                            disabled={state.loading}
                            className={classes.input}
                            onBlur={() => toggleActive(false)}
                            onChange={onInputChange}
                        />
                    </Grid>
                    {state.active && <Grid item xs={12}>
                        <Typography 
                            component="p" 
                            variant="body1"
                            data-testid="basket-barcode-scanner-message"
                            className={classes.message}
                            gutterBottom
                        >
                            {state.message}
                        </Typography>
                    </Grid>}
                </Grid>
            </Grid>
        </Container>
    </Paper>
};

const InjectedComponent = inject(
    ServicesContext,
    ["product", "basketSideView", "basketProduct", "delayedExecutorService", "documentService"],
    Component
);

export default InjectedComponent;