const data = [
    {id: 1, name: "image1", url: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg"},
    {id: 2, name: "image2", url: "https://www.italwebcom.it/singer/prodotti/server/img//base/25323/0.jpg"},
    {id: 3, name: "image3", url: "https://www.italwebcom.it/singer/prodotti/server/img//base/25323/0.jpg"},
    {id: 4, name: "image4", url: "https://www.italwebcom.it/singer/prodotti/server/img//base/184777/0.jpg"},
    {id: 5, name: "image5", url: "https://www.italwebcom.it/singer/prodotti/server/img//base/46955/2.jpg"},
    {id: 6, name: "image6", url: "https://www.italwebcom.it/singer/prodotti/server/img//base/81610/0.jpg"},
    {id: 7, name: "image7", url: "https://www.italwebcom.it/singer/prodotti/server/img//base/81610/0.jpg"},
    {id: 8, name: "image8", url: "https://www.italwebcom.it/singer/prodotti/server/img//base/81610/0.jpg"}
];

export default data;