import {Typography, TypographyProps, Box, withStyles} from "@material-ui/core";

const Component = withStyles({})(
    /**
     * @param {{
     *      label: string,
     *      value: string,
     *      ValueProps: TypographyProps,
     *      LabelProps: TypographyProps
     * }} param0
     */
    ({LabelProps, ValueProps, labelVariant, label, value, classes}) => <Box component="section">
        <Typography 
            component="header" 
            variant={labelVariant || "h4"} 
            gutterBottom 
            className={classes.label}
            {...(LabelProps || {})}
        >
            {label}
        </Typography>
        <Typography 
            component="p" 
            variant="body1" 
            {...(ValueProps || {})}
        >
            {value}
        </Typography>
    </Box>
);

export default Component;