import React from "react";
import { Grid, withStyles, Box, Container, Divider, Typography, makeStyles, Paper} from "@material-ui/core";
import AdderWrapper from "./editors/adder-wrapper.component";
import {Add} from "@material-ui/icons";
import { Fragment } from "react";
import {InlineTrigger, FabTrigger} from "./fab-trigger.component";
import { inject } from "js-react-components";
import ServicesContext from "../../contexts/services.context";
import SettingsContext from "../../contexts/settings.context";

const useStyles = makeStyles(theme => ({
    relative: {
        position: "relative"
    },
    external: {
        position: "relative",
        /*width: "100%"*/
    },
    bar: {
        position: "sticky",
        background: "white",
        zIndex: 99
    },
    lulz: {
        /*borderTop: "1px solid rgb(225, 225, 225)",
        borderBottom: "1px solid rgb(225, 225, 225)",
        padding: "0.5em 0"*/
    },
    paginator: {
        position: "sticky",
        bottom: "0px",
        background: "white"
    },
    auxComponentText: {
        textAlign: "center"
    }
}));

const AuxComponent = ({children, label}) => {
    const classes = useStyles();
    return <Box component="article">
        {label && <Typography component="header" variant="h4" gutterBottom className={classes.auxComponentText}>
            {label}
        </Typography>}
        <Box component="section">
            {children}
        </Box>
    </Box>;
};

const BaseComposer = inject(
    ServicesContext,
    ["alertService"],
    inject(
        SettingsContext,
        ["appBarHeight", "smallScreen"],
        ({
            paginator, collection, searchBar, filterer,
            loader, EditRenderer, onSave, filterLabel,
            hideSearchBar, noWrappers, hideAdder,
            Adder, alertService, EditRendererProps,
            appBarHeight, labels, smallScreen
        }) => {
            const classes = useStyles();
            const adderFrag = <Fragment>
                {Adder ? <Adder onSave={onSave} /> : <AdderWrapper 
                    {...(EditRendererProps || {})}
                    Trigger={smallScreen ? FabTrigger : InlineTrigger} 
                    Editor={EditRenderer} 
                    onSave={onSave} 
                    alertService={alertService}
                />}
            </Fragment>;
            const frag = <Fragment>
                {loader}
                <Grid item xs className={classes.bar} style={{top: `${appBarHeight}px`}}>
                    {!smallScreen ? <Container maxWidth={false} disableGutters className={classes.lulz}>
                        <Grid 
                            container 
                            spacing={2}
                            alignItems="center" 
                            justify="space-between"
                            //style={{marginLeft: 0}}
                        >
                            {!smallScreen && <Grid item xs={12} sm={3}>
                                {!hideAdder && adderFrag}
                            </Grid>}
                            {!smallScreen && <Grid item sm={5}>
                                <AuxComponent label={labels && "Paginazione"}>{paginator}</AuxComponent>
                             </Grid>}
                            {!smallScreen && !hideSearchBar && <Grid item data-testid="search-bar-wrapper" sm={4}>
                                <AuxComponent label={labels && (filterLabel || "Filtraggio")}>
                                    {searchBar || filterer}
                                </AuxComponent>
                            </Grid>}
                        </Grid>
                    </Container> : !hideSearchBar && <Fragment>
                        {searchBar || filterer}
                    </Fragment>}
                </Grid>
                <Grid item xs={12}>
                    {collection}
                </Grid>
                {smallScreen && <Grid item xs={12}>{paginator}</Grid>}
                {smallScreen && <Box position="fixed" bottom="1em" right="1em">{adderFrag}</Box>}
            </Fragment>;
    
            return noWrappers ? frag : <Box className={classes.external}>
                <Grid container spacing={2} style={{marginBottom: "1em"}}>   
                    {frag}
                </Grid>
            </Box>;
        }
    )
);

/**
 * 
 * @param {{config: {hideSearchBar?: boolean, hideAdder?: boolean, noWrappers?: boolean, noLabels?: boolean, filterLabel?: string}}} param0 
 */
export function makeComposer({EditRenderer, config, Adder}) {
    return (props) => <BaseComposer 
        {...props}
        EditRenderer={EditRenderer} 
        Adder={Adder}
        hideSearchBar={config && config.hideSearchBar}
        noWrappers={config && config.noWrappers}
        hideAdder={config && config.hideAdder}
        noLabels={config && config.noLabels}
        filterLabel={config && config.filterLabel}
    />;
};

export default BaseComposer;