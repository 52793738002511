import ProductStatGroupRenderer from "../entity-renderers/product-stat-group-renderer.component";
import {Line, Bar} from "react-chartjs-2";
import {Avatar} from "@material-ui/core";
import {Euro, SignalCellular0Bar, Warning} from "@material-ui/icons";
import StatGroupCollectionRenderer from "../../auxiliary/stat-group-collection-renderer.component";
import {wrapRenderer} from "../../auxiliary/missing-elements.component";

const getStats = ({product}) => {
    const stats = [
        {
            name: "product_stock",
            label: "Stock",
            Renderer: Line,
            avatar: <SignalCellular0Bar />,
            xAttr: "date"
        },
        {
            name: "product_sale",
            label: "Vendite",
            Renderer: Bar,
            avatar: <Euro />,
            xAttr: "date"
        }
    ];
    
    if(!product || (product.type === "variable")) {
        stats.push({
            name: "top_variant",
            label: "Varianti più vendute",
            Renderer: Bar,
            avatar: <Avatar>V</Avatar>,
            xAttr: "variant_name"
        });
    }
    return stats;
}

const ProductStatCollectionRenderer = ({elements, product}) => <StatGroupCollectionRenderer 
    stats={getStats({product})} 
    elements={elements}
    GroupRenderer={ProductStatGroupRenderer}
/>;

export default wrapRenderer(ProductStatCollectionRenderer, {
    avatarSize: 100,
    title: "Nessuna statistica presente.",
    body: "",
    Icon: Warning
});