import React, { useState } from "react";
import ComponentsContext from "../../../contexts/components.context";
import {inject, ReactComponent, HTMLCarousel, CarouselService, CustomCarouselService} from "js-react-components";
import {Product} from "../../../domain/product";
import ProductQuantityAlterationMeta from "../../../domain/product-quantity-alteration";
import {Box, Container, Grid} from "@material-ui/core";
import {EntityWrapper} from "js-generic-utilities";
import ProductQuantityAlterationEditor from "./product-quantity-alteration-editor";
import ServicesContext from "../../../contexts/services.context";
import moment from "moment";

const makeElement = data => ProductQuantityAlterationMeta.Constructor({...data, date: moment().format("YYYY-MM-DD")});

/**
 * @type {CustomCarouselService}
 */
const carouselService = CarouselService.make();

const AuxSearch = inject(
    ComponentsContext,
    ["ProductSearch"],
    ({ProductSearch, onSelect}) => <Grid container spacing={2} justify="center">
        <ProductSearch onSelect={onSelect} />
    </Grid>
)

const Component = inject(
    ComponentsContext,
    ["ProductSearch"],
    /**
   * @param {{
    *      ProductSearch: ReactComponent<{onSelect: (p: Product) => void}>,
    *      productId: number,
    *      width: number,
    *      onSave: (basketProduct: BasketProduct) => void
    * }}
    */
    ({ProductSearch, productId, width, onSave}) => {
        const [element, setElement] = useState(makeElement({product_id: productId}));
  
        /**
         * @param {Product} p 
         */
        const setProduct = p => {
            setElement(e => {
                carouselService.next();
                return EntityWrapper.wrap({entity: e, ...ProductQuantityAlterationMeta}).set("product_id", p.id).unwrap();
            });
        }
  
        return <Box margin="1em 0" position="relative">
            <Container maxWidth={false}>
             {productId ? <ProductQuantityAlterationEditor
                element={element}
                onSave={onSave}
             /> : <HTMLCarousel
                 service={carouselService}
                 width={width}
                 items={[
                    {
                        Component: ProductSearch,
                        ComponentProps: {
                            onSelect: setProduct
                        }
                    },
                    {
                        Component: ProductQuantityAlterationEditor,
                        ComponentProps: {
                            element,
                            onSave: onSave
                        }
                    }
                 ]}
             />}
            </Container>
        </Box>;
    }
);

export default Component;