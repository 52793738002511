import React, {useState} from "react";
import {Grid, RadioGroup, FormControlLabel, FormLabel, Radio, Divider, Tooltip, Typography} from "@material-ui/core";
import ProductEditor from "../product-details-entity-editor.component";
import ProductMeta, {Product, wrap as wrapProduct} from "../../../../domain/product";
import VariantBaseAttributesSelectionComponent from "../../variant/variant-base-attribute-selection.component";
import {wrap, EntityWrapper} from "js-generic-utilities";

const makeState = product => {
    /**
     * @type {EntityWrapper<Product>}
     */
    let w = wrap(product || {type: "simple"}).withMeta(ProductMeta).build();
    return w;
};

/**
 * @typedef {{
 *      product: Product,
 *      onSave: (p: Product) => void
 * }} ProductAdderFirstStepProps
 */

/**
 * @param {ProductAdderFirstStepProps} param0 
 */
export default function ProductAdderFirstStep({product, onSave, fixedType}) {

    const [currentProduct, setCurrentProduct] = useState(makeState(product));
    const baseId = "product-adder";

    const onChangeType = t => setCurrentProduct(c => c.set("type", t).clone());
    const wrapped = wrapProduct(currentProduct.unwrap());

    console.log(currentProduct);

    const onProdSave = (e) => {
        setCurrentProduct(c => {
            onSave(e || c.unwrap());
            return c;
        });
    }

    return <Grid container spacing={2}>
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
                <Typography component="header" variant="h4">Tipo prodotto</Typography>
            </Grid>
            <Grid item xs={12}>
                <RadioGroup style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Tooltip 
                        arrow 
                        title="Seleziona se il prodotto è singolo e non ammette varianti."
                    >
                        <FormControlLabel
                            label="Prodotto semplice"
                            labelPlacement="end"
                            control={<Radio />}
                            checked={wrapped.is("simple")}
                            disabled={fixedType}
                            onChange={(e, checked) => checked && onChangeType("simple")}
                            data-testid={`${baseId}-type-simple`}
                        />
                    </Tooltip>
                    <Tooltip 
                        arrow 
                        title="Seleziona se il prodotto ammette varianti. Non sarà possibile gestire direttamente lo stock del prodotto o inserirlo in un carrello."
                    >
                        <FormControlLabel
                            label="Prodotto con varianti"
                            labelPlacement="end"
                            control={<Radio />}
                            checked={wrapped.is("variable")}
                            disabled={fixedType}
                            onChange={(e, checked) => checked && onChangeType("variable")}
                            data-testid={`${baseId}-type-variable`}
                        />
                    </Tooltip>
                    <Tooltip 
                        arrow 
                        title="Seleziona se il prodotto è variante di uno già esistente."
                    >
                        <FormControlLabel
                            label="Variante prodotto"
                            labelPlacement="end"
                            control={<Radio />}
                            checked={wrapped.is("variant")}
                            disabled={fixedType}
                            onChange={(e, checked) => checked && onChangeType("variant")}
                            data-testid={`${baseId}-type-variant`}
                        />
                    </Tooltip>
                </RadioGroup>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        {wrapped.hasType() && <Grid item xs={12}>
            {!wrapped.is("variant") ? <ProductEditor
                element={currentProduct.unwrap()}
                onSave={onProdSave}
            /> : <VariantBaseAttributesSelectionComponent
                product={currentProduct.unwrap()}
                onConfirm={onProdSave}
            />}
        </Grid>}
    </Grid>;
};