import {render, EntityBaseComponentBuilder, makeSearchBar, makePaginator} from "js-react-components";
import {EntityService, HttpOperation} from "js-generic-utilities";
import BasketProductMeta, {
    BasketProduct, 
    BasketProductAttributes, 
    BasketProductIndices, 
    BasketProductSorters, 
    BasketProductViews
} from "../../domain/basket-product";
import BasketProductListRenderer from "./entity-renderers/basket-product-list-renderer.component";
import {makeComposer} from "../auxiliary/base-composer.component";
import LoadingComponent from "../auxiliary/loading.component";

/**
 * @typedef {EntityBaseComponentBuilder<"refresh", BasketProduct, BasketProductViews, BasketProductIndices, BasketProductSorters, BasketProductAttributes>} BasketProductBaseComponentBuilder
 */

const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />;
const BaseBasketComponentComposer = ({collection}) => collection;

/**
 * @param {{
 *      service: EntityService<HttpOperation>
 * }} param0
 */
export default function makeBasketProductBaseComponent({
    service, composer, alertService
}) {

    /**
     * @type {BasketProductBaseComponentBuilder}
     */
    let builder = render(service);

    const b = builder
        .withMeta(BasketProductMeta)
        .withList(BasketProductListRenderer)
        .withComponents({
            SearchBar: makeSearchBar("basket-product-search-bar"),
            Paginator: makePaginator({}, "basket-product-paginator", {
                hideItemsPerPage: true,
                buttons: true
            }),
            Sorter: () => null,
            Filterer: () => null,
            Alert: () => null,
            Loader: Loader
        })
        .withTexts({
            onGetLoading: "Caricamento prodotti",
            onSaveLoading: "Salvataggio in corso",
            onDeleteLoading: "Cancellazione prodotto",
            onDelete: "Cancellazione prodotto",
            onSave: "Salvataggio prodotto"
        })
        .withSearchBarAttribute("product_id")
        .withDefaultView("list");
    
    b.withCallbacks({
        onError: message => alertService.show({
            message,
            severity: "error"
        })
    });
    
    const Component = b.compose(BaseBasketComponentComposer || composer);

    return (props) => <Component 
        {...props}
        CollectionRendererProps={{
            ...props.CollectionRendererProps,
            basket: props.basket
        }}
    />;
}