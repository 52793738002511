import React from "react";
import {Container, Grid, makeStyles, Avatar, Typography, Button} from "@material-ui/core";
import {EntityEditWrapperInnerComponentProps, ReactComponent, wrapEdit} from "js-react-components";
import ImageMeta, {Image, validate} from "../../../domain/image";
import {Image as ImageIcon} from "@material-ui/icons";
import LabelledInput from "../../auxiliary/inputs/labelled-input.component";
import ImageEditor from "../../auxiliary/inputs/image-editor.component";
import clsx from "clsx";
import {imgPlaceholder} from "../../../constants";

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: "1em"
    },
    img: {
        display: "block",
        maxWidth: "75%",
        margin: "auto"
    }
}));

/**
 * @param {EntityEditWrapperInnerComponentProps<Image>} param0 
 */
const Component = ({
    element, onInputChange, originalElement, onSubmit, maxWidth, noHeader, disableGutters
}) => {
    const classes = useStyles();
    const validName = element && validate(element, "name");
    const validSrc = element && (element.content);
    return element && <Container 
        maxWidth={(maxWidth !== undefined) ? maxWidth : "sm"} 
        disableGutters={disableGutters}
        component="form" 
        onSubmit={evt => {
            evt.preventDefault();
            onSubmit();
        }
    }>
        <Grid container spacing={2}>
            {!noHeader && <Grid item container xs={12} alignItems="center">
                <Grid item>
                    <Typography variant="h2" className={clsx([classes.bold, classes.headerLabel])}>
                        {originalElement && (typeof(originalElement.id) === "number") ? `Modifica immagine` : `Aggiunta immagine`}
                    </Typography>
                </Grid>
            </Grid>}
            <Grid item xs={12}>
                <Typography 
                    component="label"
                    htmlFor={element.id ? `image-${element.id}-name-edit` : "new-image-name-edit"} 
                    variant="h4"
                    style={{display: "block"}}
                    gutterBottom
                >
                    Nome
                </Typography>
                <LabelledInput
                    inputLabel
                    type="text"
                    TextFieldProps={{
                        error: !validName,
                        helperText: !validName && "Nome mancante o non valido."
                    }}
                    id={element.id ? `image-${element.id}-name-edit` : "new-image-name-edit"}
                    defaultValue={element.name}
                    onChange={v => onInputChange("name", v)}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography 
                    component="header" 
                    variant="h4"
                    gutterBottom
                >
                    Immagine
                </Typography>
                <ImageEditor 
                    defaultValue={element.url || element.content || imgPlaceholder} 
                    autoSubmit
                    noWrapper
                    imageStyle={{maxWidth: "200px", margin: "auto"}}
                    onConfirm={src => onInputChange("content", src)}
                />
            </Grid>
            <Grid item xs={12}>
                <Button 
                    variant="outlined" 
                    color="primary" 
                    type="submit" 
                    fullWidth 
                    disabled={!validName || !validSrc}
                >
                    Conferma
                </Button>
            </Grid>
        </Grid>
    </Container>
};

export function shitfuck() {
    return wrapEdit({
        Component,
        meta: ImageMeta,
        ComponentProps: {
            disableGutters: true,
            noHeader: true,
            maxWidth: false
        }
    })
}

export default wrapEdit({
    Component,
    meta: ImageMeta
});