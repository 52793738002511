import {EntityMeta} from "js-generic-utilities";
import { getDefaultMeta } from "./auxiliary/domain-misc-functions";

/**
 * @typedef {"list"} BasketProductViews
 * @typedef {"product_id"} BasketProductIndices
 * @typedef {"product_id"} BasketProductSorters
 * @typedef {"id" | "product_id" | "quantity" | "cost"} BasketProductAttributes
 * @typedef {{
 *    id: number,
 *    product_id: number,
 *    cart_id: number,
 *    quantity: number,
 *    cost: number
 * }} BasketProduct
 */

/**
 * @type {EntityMeta<BasketProduct, BasketProduct>}
 */
const meta = {
    keyGetter: (e) => String(e.product_id) + String(e.cart_id),
    matcher: (a, b) => {
      const ka = meta.keyGetter(a);
      const kb = meta.keyGetter(b);
      return ka === kb;
    },
    Constructor: function(v) {return v;}
  };

export function make({product, basket, quantity, isMod}) {
  return {cart_id: basket.id, product_id: product.id, quantity, type: isMod ? "mod" : undefined};
}

export default meta;
