import React from "react";
import {Box, Grid, Typography, Divider, Container, withStyles} from "@material-ui/core";
import {Home} from "@material-ui/icons";
import {ReactComponent, inject} from "js-react-components";
import {Link} from "react-router-dom";
import clsx from "clsx";
import componentsContext from "../../../contexts/components.context";
import UnauthMex from "../unauthorized-message.component";

/**
 * @typedef {{
 *      link: string, label: string
 * }} HistoryLink 
 */
const Component = withStyles({
    label: {
        textTransform: "uppercase",
        textDecoration: "none"
    },
    text: {
        color: "rgb(111, 111, 111)",
        position: "relative",
        top: "1px"
    },
    wrapper: {
        marginBottom: "1em"
    },
    home: {
        position: "relative",
        top: "2px"
    }
})(
    inject(
        componentsContext,
        ["Gate"],
        /**
         * @param {{
         *      Component: ReactComponent<any>,
         *      ComponentProps: any,
         *      links: HistoryLink[]
         * }} param0
         */
        ({Component, Gate, ComponentProps, links, classes, style}) => <Gate
            role="admin"
            onAuthorized={() => <Grid 
                container 
                style={style} 
                alignItems="center" 
                justify="space-between"
            >
                <Grid item xs={12} className={classes.wrapper}>
                    <Container maxWidth={false} disableGutters>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Link to="/dashboard">
                                    <Home className={clsx([classes.text, classes.home])} />
                                </Link>
                            </Grid>
                            {links.map(({label, link}) => <Grid item alignItems="center">
                                <Container maxWidth={false} disableGutters>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Typography component="p" variant="h3" className={classes.text}>
                                                /
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Link to={link} className={classes.label}>
                                                <Typography 
                                                    component="p" 
                                                    variant="h3" 
                                                    className={clsx([classes.text, classes.label])}
                                                >
                                                    {label}
                                                </Typography>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Grid>)}
                        </Grid>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Component {...(ComponentProps || {})} />
                </Grid>
            </Grid>}
            onUnauthorized={() => <UnauthMex />}
            onMissing={() => <UnauthMex />}
        />
    )
);

export default Component;
