import React, { useState, Fragment } from "react";
import {Dialog, DialogContent, DialogTitle, Typography, withStyles, Grid, Tabs, Tab, Box} from "@material-ui/core";
import { inject } from "js-react-components";
import componentsContext from "../../../contexts/components.context";
import servicesContext from "../../../contexts/services.context";
import {shitfuck} from "../../image/entity-editor/image-entity-editor.component";
import AlertService from "../../auxiliary/alert-component/alert-service";

const ImageEntityEditor = shitfuck();
const Component = inject(
    componentsContext,
    ["ImageSearch", "ImageEditor"],
    inject(
        servicesContext,
        ["image", "alertService"],
        withStyles({
            title: {
                color: "grey",
                fontWeight: "bold"
            }
        })(
            /**
             * @param {{
             *      alertService: AlertService
             * }}
             */
            ({Trigger, ImageSearch, onSelect, classes, ImageEditor, image, alertService}) => {
    
                const [state, setState] = useState({search: false, mode: 0});
                
                const onImageSelect = i => {
                    onSelect(i);
                    setState(s => ({...s, search: false}));
                }
                const onImageSave = i => {
                    return image.serve("save", i)
                        .then(onImageSelect)
                        .catch(mex => alertService.show({message: mex, severity: "error"}))
                };
                const toggleSearch = () => setState(s => ({...s, search: !s.search}));
                const setMode = (m) => setState(s => ({...s, mode: m}));
        
                return <Fragment>
                    <Trigger onClick={toggleSearch} />
                    <Dialog maxWidth="md" open={state.search} onBackdropClick={toggleSearch}>
                        <DialogTitle disableTypography>
                            <Typography component="header" variant="h2" className={classes.title}>
                                Aggiunta immagine
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Tabs value={state.mode} onChange={(evt, mode) => setMode(mode)}>
                                        <Tab label="Da galleria" value={0} key={0} />
                                        <Tab label="Nuova immagine" value={1} key={1} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12}>
                                    {(state.mode === 0) && <ImageSearch onSelect={onImageSelect} />}
                                    {(state.mode === 1) && <Box marginBottom="1em">
                                        <ImageEntityEditor 
                                            element={{}} 
                                            onSave={onImageSave}
                                        />
                                    </Box>}
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Fragment>;
            }
        )
    )
);

export default Component;