import React, {memo, useEffect, useState, Fragment, CSSProperties} from "react";
import ComponentSwitcherService from "./component-switcher.service";
import {Box} from "@material-ui/core";

const computeNextIndex = (next, total) => {
    const up = next >= total;
    const down = next < 0;
    if(up || down) {
        next = up ? (total - 1) : 0;
    }
    return next;
}

/**
 * @typedef {{
 *      Component: function,
 *      ComponentProps?: any
 * }} ComponentSwitcherItem
 * 
 * @typedef {{
 *      items: ComponentSwitcherItem[],
 *      service: ComponentSwitcherService,
 *      initialSelected: number,
 *      wrapperStyle?: CSSProperties
 * }} ComponentSwitcherProps
 * 
 */

const ComponentSwitcher = memo(
    /**
     * @param {ComponentSwitcherProps}
     */
    ({
        items, service, initialSelected, wrapperStyle
    }) => {

        const [selected, setSelected] = useState(initialSelected);

        const onNext = () => setSelected(s => computeNextIndex(s + 1, items.length));
        const onPrev = () => setSelected(s => computeNextIndex(s - 1, items.length));
        const onSwitch = v => setSelected(s => computeNextIndex(v, items.length));

        useEffect(() => {
            service.onNext(onNext);
            service.onPrev(onPrev);
            service.onSwitch(onSwitch);
            return () => {
                service.remove(onNext, "next");
                service.remove(onPrev, "prev");
                service.remove(onSwitch, "switch");
            }
        }, []);

        return <Fragment>
            {items.map((item, index) => (index === selected) && <Box key={index} style={wrapperStyle}>
                <item.Component {...(item.ComponentProps || {})} />
            </Box>)}
        </Fragment>;

    },
    (p, n) => {
        return (p.items === n.items);
    }
);

export default ComponentSwitcher;