import {EntityMeta} from "js-generic-utilities";

/**
 * @typedef {"product_id" | "product_list_id"} ProductCostSorters
 * @typedef {"product_id" | "product_list_id"} ProductCostIndices
 * @typedef {"product_id" | "product_list_id"} ProductCostAttributes
 * @typedef {{
 *    product_id: number,
 *    product_list_id: number,
 *    cost: number,
 *    active: 0 | 1
 * }} ProductCost
 */

/**
 * @type {EntityMeta<ProductCost, ProductCost>}
 */
const meta = {
  keyGetter: (e) => e ? (String(e.product_id) + String(e.product_list_id)) : null,
  matcher: (a, b) => {
    const ka = meta.keyGetter(a);
    const kb = meta.keyGetter(b);
    return ka === kb;
  },
  Constructor: function(v) {
    return v;
  }
};

export default meta;
