import React, {useState, useEffect} from "react";
import {Box, Fade, Modal} from "@material-ui/core";
import {Redirect} from "react-router-dom";
import {
    UserAuthService, 
    LoginPanel as LoginPanelComponent, 
    Gate as GateComponent, 
    UserCredentials,
    AuthComponent
} from "react-auth-components";
import LoadingComponent from "../auxiliary/loading.component";
import AlertService from "../../components/auxiliary/alert-component/alert-service";
import { inject } from "js-react-components";
import settingsContext from "../../contexts/settings.context";

const Loader = () => <LoadingComponent size="3em" label="Login in corso..." />;

const Component = inject(
    settingsContext,
    ["backgroundColor"],
    /**
     * @param {{
     *      service: UserAuthService,
     *      LoginPanel: LoginPanelComponent,
     *      LoginPanelProps: {
     *          title: string,
     *          rememberLabel: string,
     *          rememberTooltip?: string
     *      },
     *      alertService: AlertService,
     *      loginPath: string,
     *      Gate: GateComponent
     *      Auth: AuthComponent
     * }} param0 
     */
    ({
        service, 
        LoginPanelProps, 
        alertService,
        loginPath,
        Gate,
        Auth,
        LoginPanel,
        onLogin,
        ExpiredRefreshHandler,
        backgroundColor
    }) => {
        const [loading, setLoading] = useState(false);
        useEffect(() => {
            document.querySelector("[data-testid=user-id-input]").focus();
        }, []);
        return <Box 
            position="relative" 
            display="flex" 
            width="100%" 
            style={{
                backgroundColor,
                height: "calc(100% + 2em + 4px)",
                width: "calc(100% + 2em + 4px)",
                margin: "calc(-1em - 2px)"
            }}
        >
            <Box 
                display="flex" 
                id="login-panel-wrapper" 
                width="100%" 
                alignItems="center"
                style={{backgroundColor}}
            >
                <LoginPanel
                    title={LoginPanelProps.title}
                    rememberLabel={LoginPanelProps.rememberLabel}
                    rememberTooltip={LoginPanelProps.rememberTooltip || LoginPanelProps.rememberLabel}
                    onSubmit={/** @param {UserCredentials} c */ c => {
                        setLoading(true);
                        service
                            .requestToken(c)
                            .then(onLogin)
                            .catch(m => alertService.show({
                                message: m,
                                severity: "error"
                            }))
                            .finally(() => setLoading(false))
                    }}
                />
            </Box>
            {loading && <Loader />}
            <Gate role="admin">
                <Redirect from="/" to={loginPath} exact />
            </Gate>
        </Box>;
    }
);

export default Component;