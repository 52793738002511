import React, { useState} from "react";
import {Tabs, Tab, Grid, Box, Container} from "@material-ui/core";
import {inject} from "js-react-components";
import ComponentsContext from "../../../contexts/components.context";

const Component = inject(
    ComponentsContext,
    ["ProductQuantityAlterationReal", "ProductQuantityAlterationSigh"],
    ({ProductQuantityAlterationReal, ProductQuantityAlterationSigh}) => {
        const [view, setView] = useState(0);

        return <Container maxWidth={false}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Tabs value={view} onChange={(e, v) => setView(v)}>
                        <Tab value={0} label="Vista gerarchica" />
                        <Tab value={1} label="Lista" />
                    </Tabs>
                </Grid>
                <Box width="100%">
                    {(view === 0) ? <ProductQuantityAlterationReal defaultView="list" /> : null}
                    {(view === 1) ? <ProductQuantityAlterationSigh defaultView="table" /> : null}
                </Box>
            </Grid>
        </Container>
    }
);

export default Component;