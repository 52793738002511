import React, {useState, useEffect, memo} from "react";
import {ReactComponent, FilterValue, inject} from "js-react-components";
import {TextField, Select, MenuItem, Grid, Container, ButtonGroup, Button, IconButton, FormControl} from "@material-ui/core";
import {CollectionUtilityWrapper} from "js-generic-utilities";
import { Clear, Check, Search, Delete, RemoveCircle } from "@material-ui/icons";
import settingsContext from "../../../contexts/settings.context";

/**
 * @typedef {{
 *      attribute: string,
 *      label: string,
 *      date?: boolean,
 *      values?: FilterValue[]
 * }} AttributeFilter
 * 
 * @typedef {{
 *      attribute: string,
 *      value: FilterValue,
 *      onChange: ({attribute: string, value: FilterValue}) => void,
 *      onClear: Function,
 *      filters: AttributeFilter[],
 *      testID?: string
 * }} MultiAttributeFiltererProps
 */

function v(event) {
    return event.target.value;
}

/**
 * @param {AttributeFilter} a 
 * @param {AttributeFilter} b 
 */
const filterMatcher = (a, b) => {
    return a.attribute === b.attribute;
}

/**
 * @type {ReactComponent<MultiAttributeFiltererProps>}
 */
const Component = inject(
    settingsContext,
    ["smallScreen"],
    memo(
        ({attribute, value, onChange, onClear, filters, testID, attributeLabel, noClear, smallScreen}) => {
    
            const [state, setState] = useState({attribute, value, controlled: false});
            /**
             * @type {CollectionUtilityWrapper<AttributeFilter>}
             */
            const wrapped = CollectionUtilityWrapper.wrap(filters, filterMatcher);
            const onSubmit = () => setState(s => {
                if(s.attribute && s.value) {
                    onChange(s);
                }
                return s;
            });
    
            let cFilter;
            if(state.label) {
                cFilter = wrapped.get({attribute: state.attribute});
            }
    
            useEffect(() => {
                setState(s => {
                    let newState = {...s};
                    if(newState.attribute !== attribute) {
                        newState.attribute = attribute;
                    }
                    if(newState.value !== value) {
                        newState.value = value;
                    }
                    return newState;
                });
            }, [attribute, value]);
        
            return <FormControl 
                fullWidth 
                component="form" 
                onSubmit={evt => {
                    evt.preventDefault();
                    onSubmit();
                }}
            >
                <Container 
                    data-testid={testID}
                    disableGutters 
                    maxWidth={false}
                >
                    <Grid container spacing={1} data-testid={`${testID}-container`} alignItems="center">
                        <Grid item>
                            <Select 
                                fullWidth
                                variant="outlined"
                                label={attributeLabel}
                                value={state.attribute || ''}
                                data-testid={`${testID}-attribute-select`}
                                onChange={evt => setState(s => ({...s, attribute: v(evt)}))}
                            >
                                {filters.map(filter => {
                                    const cId = `${testID}-attribute-select-option-${filter.attribute}`;
                                    return <MenuItem 
                                        key={filter.attribute}
                                        value={filter.attribute}
                                        label={filter.label}
                                        data-testid={cId}
                                    >
                                        {filter.label}
                                    </MenuItem>;
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                variant="outlined"
                                type="search"
                                fullWidth
                                disabled={state.attribute === null}
                                defaultValue={state.controlled ? null : state.value}
                                value={state.controlled ? (state.value || "") : undefined}
                                onFocus={() => setState(s => ({...s, controlled: false}))}
                                onBlur={() => setState(s => ({...s, controlled: true}))}
                                onChange={evt => setState(s => ({...s, value: v(evt)}))}
                                label={cFilter && cFilter.label}
                                inputProps={{
                                    "data-testid": `${testID}-attribute-value`
                                }}
                                InputProps={{
                                    endAdornment: <ButtonGroup>
                                        <IconButton 
                                            disabled={!state.attribute || !state.value}
                                            color="primary" 
                                            type="submit"
                                            data-testid={`${testID}-confirm`}
                                        >
                                            <Search />
                                        </IconButton>
                                        <IconButton 
                                            disabled={!state.attribute || !state.value}
                                            color="secondary" 
                                            type="button"
                                            onClick={onClear}
                                            data-testid={`${testID}-clear`}
                                        >
                                            <RemoveCircle />
                                        </IconButton>
                                    </ButtonGroup>
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </FormControl>;
        },
        (p, n) => (p.attribute === n.attribute) && (p.value === n.value)
    )
);

/**
 * @param {{filters: AttributeFilter[], testID?: string}} param0 
 * @return {ReactComponent<MultiAttributeFiltererProps>}
 */
export function makeMultiAttributeFilterer({filters, testID, noClear}) {
    return props => <Component {...props} filters={filters} testID={testID} noClear={noClear} />;
}

export default Component;