import React from "react";
import {
    EntityBaseComponentBuilder, 
    render, 
    ComposerProps, 
    ReactComponent, 
    makeGridRenderer, 
    makeSearchBar, 
    makePaginator
} 
from "js-react-components";
import CategoryMeta, {
    Category, 
    CategoryAttributes, 
    CategoryIndices, 
    CategorySorters, 
    CategoryViews
} 
from "../../domain/category";
import {EntityService, HttpOperation} from "js-generic-utilities";
import CategoryCard from "./entity-renderers/category-card-renderer.component";
import BaseComposer, {makeComposer} from "../auxiliary/base-composer.component";
import CategoryEditor from "./entity-editors/category-entity-editor.component";
import LoadingComponent from "../auxiliary/loading.component";
import AlertService from "../auxiliary/alert-component/alert-service";
import EditSuccess from "../auxiliary/edit-success.component";
import { wrapRenderer } from "../auxiliary/missing-elements.component";
import { Label } from "@material-ui/icons";
import {texts} from "../../constants";

const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />;
const BaseCategoryComposer = makeComposer({
    EditRenderer: CategoryEditor, 
    meta: CategoryMeta
});

/**
 * @param {{
 *      service: EntityService<HttpOperation>,
 *      composer: ReactComponent<ComposerProps>,
 *      alertService: AlertService
 * }} param0 
 */
const makeComponent = ({
    service, 
    composer, 
    Paginator, 
    itemsPerPage, 
    EntityRenderer, 
    componentService, 
    elementsPerRow, 
    ListEntityRenderer,
    alertService,
    deleteDialogService
}) => {
    /**
     * @type {EntityBaseComponentBuilder<"refresh", Category, CategoryViews, CategoryIndices, CategorySorters, CategoryAttributes>}
     */
    let builder = render(service);

    builder.withMeta(CategoryMeta)
        .withList(ListEntityRenderer)
        .withGrid({
            Entity: EntityRenderer || CategoryCard,
            Collection: wrapRenderer(makeGridRenderer({
                spacing: 1, 
                elementsPerRow: elementsPerRow || {xs: 12, sm: 4}
            }), {
                avatarSize: 150,
                title: texts.missing_elements_title("categoria", true),
                body: texts.missing_elements_body,
                Icon: Label
            })
        })
        .editEntityWith(CategoryEditor)
        .withService(componentService)
        .withSearchBarAttribute({
            attribute: "name",
            label: "Nome categoria"
        })
        .withBounds({
            start: 0, 
            end: itemsPerPage || 10,
            itemsPerPage: itemsPerPage || 10
        })
        .withDefaultView("grid")
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .withComponents({
            SearchBar: makeSearchBar("category-search-bar"),
            Paginator: Paginator || makePaginator({}, "category-paginator", {
                defaultItemsPerPage: itemsPerPage || 10,
                hideItemsPerPage: true,
                buttons: true,
                allowFirstLastPages: true,
                hideTotalItems: true
            }),
            Filterer: () => null,
            Sorter: () => null,
            Loader: Loader,
            Alert: () => null
        })
        .withOperations([
            {
                name: "delete",
                handler: (e, {onDelete}) => deleteDialogService.show({
                    message: `Confermare la cancellazione della categoria ${e.name}?`, 
                    onConfirm: () => onDelete(e)
                })
            }
        ])
        .withCallbacks({
            onAfterSave: e => alertService.show({
                message: `Categoria ${e.name} modificata con successo.`, 
                severity: "success"
            }),
            onAfterDelete: e => alertService.show({
                message: `Categoria ${e.name} cancellata.`, 
                severity: "success"
            }),
            onError: e => alertService.show({
                message: e,
                severity: "error"
            })
        });
    
    return builder.compose(composer || BaseCategoryComposer);
}

export default makeComponent;