import React from "react";
import {CircularProgress, Typography} from "@material-ui/core";
import { inject } from "js-react-components";
import servicesContext from "../../../../contexts/services.context";
import AsyncEntityLoader from "../../../auxiliary/async-entity-loader/async-entity-loader.component";

const CustomerRenderer = ({entity}) => entity && <Typography component="span" variant="h4">Cliente {entity.name}</Typography>
const Component = inject(
    servicesContext,
    ["customer"],
    ({id, customer, Renderer}) => <AsyncEntityLoader
        id={id}
        onGet={id => customer.serve("get", null, {filterAttribute: "id", filterValue: id})}
        Renderer={Renderer || CustomerRenderer}
        LoadingComponent={CircularProgress}
    />
);

export default Component;