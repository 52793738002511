import categories from "./data/categories";

const getUrlParam = (url, p) => url.searchParams.get(p);
const getUrlParams = (url, filters) => {
    filters = filters || [];
    let u = new URL(url);
    let out = {
        size: parseInt(getUrlParam(u, "size")),
        page: parseInt(getUrlParam(u, "page")),
        sortAttribute: getUrlParam(u, "sortAttribute"),
        sortDirection: getUrlParam(u, "sortDirection")
    };
    for(let f of filters) {
        out[f] = getUrlParam(u, f);
    }
    return out;
}

const comparer = (d, s) => (a, b) => (d === "asc") ? (a[s] - b[s]) : (b[s] - a[s]);
const embedResults = (results, name) => {
    let out = {_embedded: {}};
    out._embedded[name] = results;
    return out;
}
/**
 * @param {any[]} stuff 
 * @param {any} byPars 
 * @param {string[]} filters 
 */
const filter = (stuff, byPars, filters) => {
    byPars = byPars || {};
    let {size, page, sortAttribute, sortDirection} = byPars;
    if(filters.length !== 0) {
        for(let f of filters) {
            if(byPars[f]) {
                stuff = stuff.filter(e => e[f] === byPars[f]);
            }
        }
    }
    if(sortAttribute && sortDirection) {
        stuff = stuff.sort(comparer(sortDirection, sortAttribute));
    }
    if(!isNaN(size) && !isNaN(page)) {
        stuff = stuff.slice(size*page, size*(page+1));
    }
    return stuff;
}

const add = (stuff, to) => {
    stuff = JSON.parse(stuff);
    stuff = {...stuff, id: to.length};
    to.push(stuff);
    return stuff;
}

const deleteFrom = (stuff, from) => {
    let match = from.filter(e => e.id === stuff.id);
    if(match.length) {
        categories.splice(categories.indexOf(match[0]), 1);
        return match[0];
    }
    return null;
}

/**
 * @typedef {{
 *      size?: number,
 *      page?: number,
 *      sortAttribute?: string,
 *      sortDirection?: string
 * }} GetArgs
 * @typedef {{
 *      regex: string,
 *      filters?: string[],
 *      method: string,
 *      onMatch: (args: GetArgs) => any
 * }} UrlMatcher
 * @type {UrlMatcher[]}
 */
const matchers = [
    {
        regex: /^https:\/\/[a-zA-Z0-9\.\-\/]+\/category-type\/1\/category[?].*$/,
        method: "get",
        filters: ["name"],
        onMatch: args => embedResults(filter(categories, args, ["name"]), "categoryList")
    },
    {
        regex: /^https:\/\/[a-zA-Z0-9\.\-\/]+\/category-type\/1\/category\/count.*$/,
        method: "get",
        filters: ["name"],
        onMatch: args => ({count: filter(categories, args, ["name"]).length})
    },
    {
        regex: /^https:\/\/[a-zA-Z0-9\.\-\/]+\/category-type\/1\/category.*$/,
        method: "post",
        onMatch: (args, body) => add(body, categories),
        status: 201
    }/*,
    {
        regex: /^https:\/\/[a-zA-Z0-9\.\-\/]+\/category-type\/1\/category.*$/,
        method: "delete",
        onMatch: (args, body) => add(body, categories)
    }*/
];

const matches = (matcher, url) => (new RegExp(matcher)).test(url);
const wrapResponse = (data, status) => {
    return Promise.resolve({
        status,
        json: () => Promise.resolve(data)
    });
}
export default function onFetch(url, options) {

    options = options || {};

    let params;
    let filters = [];
    let method = options.method ? options.method.toLowerCase() : "get";

    let m = matchers.filter(matcher => matches(matcher.regex, url) && (matcher.method.toLowerCase() === method));
    if(m.length !== 0) {
        let matcher = m[0];
        filters = matcher.filters;
        params = getUrlParams(url, filters);
        //console.log(params);
        let out = matcher.onMatch(params, options.body);
        return wrapResponse(out, matcher.status || 200);
    } else {
        return wrapResponse({message: "missing_endpoint"}, 404);
    }
}