import React, {useState} from "react";
import ComponentsContext from "../../../contexts/components.context";
import {inject, ReactComponent, HTMLCarousel, CarouselService, CustomCarouselService} from "js-react-components";
import {Customer} from "../../../domain/customer";
import BasketMeta from "../../../domain/basket";
import {Box, Container, Grid, Typography, Divider} from "@material-ui/core";
import {EntityWrapper} from "js-generic-utilities";
import BasketEditor from "./basket-editor.component";
import ComponentSwitcher from "../../auxiliary/component-switcher/component-switcher.component";
import ComponentSwitcherService from "../../auxiliary/component-switcher/component-switcher.service";

const makeElement = data => BasketMeta.Constructor(data);

/**
 * @type {ComponentSwitcherService}
 */
const service = ComponentSwitcherService.make();
const CustomerSearchWrapper = ({onSelect, Component}) => <Container maxWidth="md" disableGutters>
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography component="header" variant="h2">Selezione cliente</Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={12}>
            <Component onSelect={onSelect} />
        </Grid>
    </Grid>
</Container>

const Component = inject(
    ComponentsContext,
    ["CustomerSearch"],
    /**
   * @param {{
    *      CustomerSearch: ReactComponent<{onSelect: (c: Customer) => void}>,
    *      productId: number,
    *      width: number,
    *      onSave: (basket: Basket) => void
    * }}
    */
    ({CustomerSearch, customerId, width, onSave}) => {
        const [element, setElement] = useState(makeElement({customer_id: customerId}));
  
        /**
         * @param {Customer} p 
         */
        const setCustomer = c => {
            setElement(e => {
                service.next();
                return EntityWrapper.wrap({
                    entity: e, 
                    ...BasketMeta
                })
                .set("customer_id", c.id)
                .unwrap();
            });
        }
  
        return <Box position="relative">
            {customerId ? <BasketEditor
                element={element}
                onSave={onSave}
            /> : <ComponentSwitcher
                service={service}
                initialSelected={0}
                items={[
                    {
                        Component: CustomerSearchWrapper,
                        ComponentProps: {
                            onSelect: setCustomer,
                            Component: CustomerSearch
                        }
                    },
                    {
                        Component: BasketEditor,
                        ComponentProps: {
                            element,
                            onSave: onSave
                        }
                    }
                ]}
            />}
        </Box>;
    }
);

export default Component;