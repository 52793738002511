import ProductStatMeta, {ProductStat} from "../../../domain/product-stat";
import React, {ReactNode} from "react";
import {CollectionUtilityWrapper} from "js-generic-utilities";
import moment from "moment";
import StatGroupRenderer from "../../auxiliary/stat-group-renderer.component";

const getFiltered = (elements, statName) => elements.filter(e => e.name === statName);
const formatDate = d => moment(d).format("DD-MM-YYYY");
const getSubheader = (elements, statName)  => {
    /**
     * @type {CollectionUtilityWrapper<ProductStat>}
     */
    const filtered = CollectionUtilityWrapper.wrap(getFiltered(elements, statName), ProductStatMeta.matcher);
    const min = filtered.sortBy("date", "asc").at(0);
    const max = filtered.sortBy("date", "desc").at(0);
    return `Dal ${(min && formatDate(min.date)) || "---"} al ${(max && formatDate(max.date)) || "---"}`;
}

/**
 * @param {{
 *      elements: ProductStat[],
 *      name: string,
 *      label: string,
 *      avatar: ReactNode
 * }} param0 
 */
const ProductStatGroupRenderer = ({
    elements, 
    name, 
    label, 
    avatar, 
    Renderer, 
    xAttr, 
    yAttr
}) => {
    yAttr = yAttr || "value";
    xAttr = xAttr || "date";
    const subheader = (xAttr === "date") && getSubheader(elements, name);
    return <StatGroupRenderer
        subheader={subheader}
        xAttr={xAttr}
        yAttr={yAttr}
        title={`${label} prodotto`}
        avatar={avatar}
        Renderer={Renderer}
        elements={getFiltered(elements, name)}
        label={label}
    />;
}

export default ProductStatGroupRenderer;