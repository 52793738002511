import {EntityMeta} from "js-generic-utilities";
import { getDefaultMeta, ValidationWrapper } from "./auxiliary/domain-misc-functions";
import regexes from "./auxiliary/regex-repository";

/**
 * @typedef {{
 *    id: number,
 *    name: string,
 *    shownName: string,
 *    valuesNumber?: number
 * }} Attribute
 */

/**
 * @type {EntityMeta<Attribute>}
 */
const meta = getDefaultMeta();

export function validate(n, data) {
    let w = new ValidationWrapper(data, {
        name: regexes.alphanumeric,
        shownName: regexes.text
    });
    return (n instanceof Array) ? w.validateMulti(n) : w.validate(n);
}

export default meta;
