import React, { Fragment } from "react";
import {Product, wrap} from "../../../domain/product";
import {Grid, Typography, Box, makeStyles, Container, Badge, Chip} from "@material-ui/core";
import {imgPlaceholder} from "../../../constants";
import ProductCostPrinter from "./auxiliary/product-cost-printer.component";

const useStyles = makeStyles(t => ({
    img: {
        maxWidth: "100px",
        display: "block",
        margin: "auto"
    },
    header: {
        color: "grey",
        fontWeight: "bold"
    }
}));

function printValue(v, n) {
    switch(v) {
        case "variable":
            return `Variabile (${n || "?"} varianti)`;
        case "variant":
            return "Variante";
        case "simple":
            return "Semplice";
        default:
            return v;
    }
}

const DetailTextRenderer = ({label, value}) => {
    const classes = useStyles();
    return <Box component="section">
        <Typography component="header" variant="h4" gutterBottom className={classes.header}>
            {label}
        </Typography>
        <Typography component="p" variant="body1">
            {value || "-"}
        </Typography>
    </Box>;
};

/**
 * @param {{
 *      product: Product
 * }} param0
 */
const Component = ({product}) => {
    const classes = useStyles();
    const wrapped = wrap(product);
    /*const attrs = ["name", "quantity"];
    const labels = {name: "Nome", type: "Tipo", quantity: "Stock", description: "Descrizione"};
    const dims = `${product.width || "0"}cm x ${product.height|| "0"}cm x ${product.depth|| "0"}cm (${product.weight|| "0"} g)`;*/
    return <Grid container spacing={3} alignItems="center" justify="space-between">
        <Grid item xs={12} sm={3}>
            <img 
                src={(product.image && product.image.url) || imgPlaceholder}
                className={classes.img}
                alt={product.name}
            />
        </Grid>
        <Grid item xs={12} sm>
            <Container maxWidth={false} disableGutters>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <DetailTextRenderer label="Nome" value={product.name} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <DetailTextRenderer label="Tipo prodotto" value={printValue(product.type, product.variantsCount)} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <DetailTextRenderer label="Stock" value={product.stock} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <DetailTextRenderer label="Costi" value={<ProductCostPrinter product={product} />} />
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </Grid>;
};

export default Component;