import React from "react";
import {CircularProgress, Grid, Typography, makeStyles, Box} from "@material-ui/core";

const useStyles = makeStyles(t => ({
    text: {
        textAlign: "center",
        fontWeight: "bold"
    }
}));

/**
 * @param {{
 *      label: string,
 *      size: string | number
 * }} param0
 */
export default function LoadingComponent({label, size, testID}) {
    const classes = useStyles();
    return <Box 
        position="absolute" 
        style={{
            backgroundColor: "white", 
            zIndex: 8, 
            width: "100%", 
            top: 0,
            opacity: label ? 0.85 : 0, 
            height: "100%",
            transitionProperty: "opacity", 
            transitionDuration: "750ms",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}
    >
        <Box
            display="flex" 
            flexDirection="column" 
            alignItems="center"
        >
            <Box marginBottom="1em">
                <CircularProgress size={size} />
            </Box>
            <Typography 
                component="p" 
                variant="body1" 
                className={classes.text}
                data-testid={testID}
            >
                {label}
            </Typography>
        </Box>
    </Box>
}