import ComponentsContext from "../../../contexts/components.context";
import {ReactComponent, inject, EntityRendererProps} from "js-react-components";
import { 
    Box, ListItem, Divider, ListItemText, ListItemAvatar, 
    makeStyles, Avatar, ButtonGroup, Button, ListItemSecondaryAction, IconButton, Collapse, Grid, Container, Dialog 
} from "@material-ui/core";
import {SearchOutlined, AddBoxOutlined, KeyboardArrowRight, KeyboardArrowDown} from "@material-ui/icons";
import { Fragment, useState } from "react";
import ProductQuantityAlterationAdder from "../../product-quantity-alteration/entity-editors/product-quantity-alteration-adder";
import {wrap} from "../../../domain/product";
import AdderWrapper from "../../auxiliary/editors/adder-wrapper.component";
import {InlineTrigger, FabTrigger} from "../../auxiliary/fab-trigger.component";
import servicesContext from "../../../contexts/services.context";
import clsx from "clsx";
import settingsContext from "../../../contexts/settings.context";

const useStyles = makeStyles(t => ({
    img: {
        display: "block",
        maxWidth: "calc(min(100%, 100px))"
    },
    listItem: {
        backgroundColor: "rgb(250, 250, 250)",
        borderRadius: "5px", 
        border: "1px solid rgb(222, 222, 222)",
        borderBottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    lel: {
        border: "1px solid rgb(222, 222, 222)",
        borderTop: 0,
        marginBottom: "0.5em"
    },
    opaque: {
        opacity: 0.45
    }
}));

const Composer = inject(
    servicesContext,
    ["alertService"],
    inject(
        settingsContext,
        ["smallScreen"],
        ({collection, product, searchBar, paginator, loader, onSave, alertService, smallScreen}) => {
            const classes = useStyles();
            const [state, setState] = useState({adding: false, collapsed: true});
            const wrapped = wrap(product);
            const variable = wrapped.is("variable");
            return <Fragment key={product.id}>
                <Dialog open={state.adding} onBackdropClick={() => setState(s => ({
                    ...s, adding: false
                }))}>
                    <ProductQuantityAlterationAdder width={960} productId={product.id} onSave={onSave} />
                </Dialog>
                <ListItem key={product.id} className={clsx([classes.listItem, variable && classes.opaque])}>
                    <ListItemAvatar>
                        <Avatar>
                            <img className={classes.img} src={product.image} alt={product.name} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={product.name} secondary={!variable && "Lista caricamenti e resi"} />
                    {!variable && <ListItemSecondaryAction>
                        <IconButton 
                            key="show_alterations" 
                            onClick={() => setState(s => ({...s, collapsed: !s.collapsed}))} 
                            variant="text" 
                            color="primary"
                        >
                            {state.collapsed ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
                        </IconButton>
                    </ListItemSecondaryAction>}
                </ListItem>
                <Box 
                    className={classes.lel}
                >
                    <Collapse in={!state.collapsed} collapsedHeight={0}>
                        <Box
                            className="product-quantity-alteration-list" 
                            position="relative"
                            width="calc(100% - 2em)"
                            marginLeft="1em"
                            marginRight="1em"
                        >
                            {loader}
                            <Container maxWidth={false} disableGutters style={{margin: "1em 0"}}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs>
                                        <AdderWrapper
                                            Editor={ProductQuantityAlterationAdder}
                                            EditorProps={{productId: product.id}}
                                            Trigger={smallScreen ? FabTrigger : InlineTrigger}
                                            onSave={onSave}
                                            alertService={alertService}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {paginator}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {collection}
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Collapse>
                </Box>
            </Fragment>;
        }
    )
);

const ProductQuantityAlterationList = inject(
    ComponentsContext,
    ["ProductQuantityAlteration"],
    ({ProductQuantityAlteration, element}) => {
        const classes = useStyles();
        return <ProductQuantityAlteration 
            productId={element.id}
            Composer={Composer}
            ComposerProps={{product: element, classes}}
            defaultView="table"
        />;
    }
);

export default ProductQuantityAlterationList;