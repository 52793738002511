import makeEditor from "../../auxiliary/base-entity-editor.component";
import AttributeMeta, {validate} from "../../../domain/attribute";
import {Folder} from "@material-ui/icons";

const Editor = makeEditor({
    meta: AttributeMeta,
    entityName: "Attributo",
    icon: <Folder />,
    attributes: [
        {
            id: "name",
            label: "Nome",
            type: "text",
            helperText: "Nome mancante o non valido"
        },
        {
            id: "shownName",
            label: "Nome mostrato",
            type: "text",
            helperText: "Nome mostrato mancante o non valido"
        }
    ],
    config: {
        onValidate: validate,
        isValid: e => validate(["name", "shownName"], e)
    }
});

export default Editor;