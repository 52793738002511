import {inject} from "js-react-components";
import settingsContext from "../../../../contexts/settings.context";
import {wrap} from "../../../../domain/basket";
import {Button, Typography, ButtonGroup, withStyles, Box, Container, Grid, Divider, CircularProgress, Avatar, Badge, TextField, InputAdornment, IconButton} from "@material-ui/core";
import {Fragment, useState, createContext} from "react";
import AdderWrapper from "../../../auxiliary/editors/adder-wrapper.component";
import BasketProductAdder from "../../../basket-product/entity-editor/basket-product-adder.component";
import BasketBarcodeScanner from "../../auxiliary/basket-barcode-scanner.component";
import moment from "moment";
import servicesContext from "../../../../contexts/services.context";
import AsyncEntityLoader from "../../../auxiliary/async-entity-loader/async-entity-loader.component";
import clsx from "clsx";
import {round} from "../../../../utils/misc-utils-functions";
import { memo } from "react";
import { Check } from "@material-ui/icons";
import AsyncCustomerRenderer from "./async-customer-renderer.component";

const n = v => isNaN(v) ? "---" : v;
const DiscountContext = createContext(null);

const getCost = (entities, basket) => {
    return entities.length ? entities.map(e => e.cost).reduce((a, b) => a + b) : basket.cost;
};

const getDiscountedCost = (cost, discount) => {
    if(cost && discount) {
        cost = parseFloat(cost);
        discount = parseFloat(discount);
        return cost * (1 - (discount/100));
    }
    return cost;
};

const DiscountInput = memo(
    ({defaultValue, onChange}) => {
        const [value, setValue] = useState(defaultValue);
        const onSubmit = () => setValue(s => {
            onChange(s);
            return s;
        });
        return <TextField
            label="Sconto %"
            type="number"
            variant="outlined"
            defaultValue={value}
            onChange={evt => setValue(evt.target.value)}
            placeholder="Sconto %"
            InputProps={{
                endAdornment: <InputAdornment>
                    <IconButton disabled={!value} onClick={onSubmit}>
                        <Check />
                    </IconButton>
                </InputAdornment>
            }}
            inputProps={{
                min: 0,
                max: 100,
                step: 1
            }}
        />
    },
    (p, n) => p.value === n.value
);

const Renderer = inject(
    settingsContext,
    ["basketProductAdderWidth", "smallScreen"],
    inject(
        servicesContext,
        ["customer"],
        withStyles({
            barcodeOverlay: {
                background: "white", 
                opacity: 0.35, 
                width: "100%", 
                height: "100%", 
                position: "absolute"
            },
            barcodeWrapper: {
                position: "absolute", 
                height: "100%", 
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                top: "-2em"
            },
            cost: {
                color: "white",
                fontWeight: "bold"
            },
            discountedCost: {
                marginLeft: "0.5em"
            },
            originalCost: {
                textDecoration: "line-through"
            },
            noProductsText: {
                textAlign: "center"
            },
            costAvatar: {
                minWidth: "75px",
                minHeight: "75px",
                width: "auto",
                height: "auto",
                padding: "0 0.5em",
                backgroundColor: "green",
                borderRadius: "25px"
            }
        })(
            /**
             * @param {{basket: Basket}} param0 
             */
            ({
                collection, 
                basket, 
                classes, 
                loader, 
                onSave, 
                basketProductAdderWidth, 
                entities, 
                customer,
                onClose,
                onReserve,
                onSetDiscount,
                smallScreen
            }) => {
                const [scan, setScan] = useState(false);
                const [loading, setLoading] = useState(false);
                const wrapped = wrap(basket);
                const toggleScan = () => setScan(s => !s);
                const cost = getCost(entities, basket);
                return <Box 
                    marginLeft="1em"
                    marginRight="1em" 
                    width="calc(100% - 2em)" 
                    className="basket-product-list" 
                    position="relative"
                >
                    {loader}
                    {scan && <Fragment>
                        <Box 
                            style={{zIndex: scan ? 2 : 0}} 
                            className={classes.barcodeOverlay}
                        />
                        <Box 
                            style={{zIndex: scan ? 3 : 0}}
                            className={classes.barcodeWrapper}
                        >   
                            <BasketBarcodeScanner
                                basket={basket}
                                onSave={onSave}
                                onError={mex => console.log(mex)}
                                onClose={() => setScan(false)}
                            />
                        </Box>
                    </Fragment>}
                    <Container maxWidth={false} disableGutters style={{marginBottom: "1em", zIndex: 1}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Container maxWidth={false} disableGutters>
                                    <Grid container spacing={1} alignItems="center" justify="space-between">
                                        <Grid item component="header">
                                            <Box display="flex" flexDirection="column">
                                                <Badge badgeContent={wrapped.isFromWoocommerce() ? "W" : "M"} color="primary">
                                                    <Typography component="span" variant="h3" gutterBottom>
                                                        {wrapped.description()} del {moment(basket.date).format("DD-MM-YYYY")}
                                                    </Typography>
                                                </Badge>
                                                <Box marginBottom="0.5em">
                                                    {/*<AsyncCustomerRenderer id={basket.customer_id} />*/}
                                                    <Typography component="span" variant="h4">Cliente {basket.customer && basket.customer.name}</Typography>
                                                </Box>
                                                {wrapped.address() && <Typography component="p" variant="h4" gutterBottom>
                                                    <span style={{fontWeight: "bold"}}>{wrapped.deliveryZone() || "Spedizione"}</span> a <span style={{fontWeight: "bold"}}>{wrapped.address()}</span>
                                                </Typography>}
                                                {wrapped.inPlace() && <Typography component="p" variant="h4" gutterBottom style={{fontWeight: "bold"}}>
                                                    Ritiro in negozio
                                                </Typography>}
                                            </Box>
                                        </Grid>
                                        <Grid item style={{width: smallScreen ? "100%" : undefined}} component="section">
                                            <Box 
                                                display="flex" 
                                                alignItems="center" 
                                                justifyContent="center" 
                                                width="100%"
                                                margin={smallScreen ? "0.5em 0" : undefined}
                                            >
                                                {wrapped.isEditable() && <Box marginRight="1em">
                                                    <DiscountInput
                                                        defaultValue={basket.discount}
                                                        onChange={onSetDiscount}
                                                    />
                                                </Box>}
                                                <Avatar className={classes.costAvatar}>
                                                    <Typography
                                                        component="p" 
                                                        variant="h2" 
                                                        className={clsx([classes.cost, cost && basket.discount && classes.originalCost])}
                                                    >
                                                        {n(round(cost, 2))} &euro;
                                                    </Typography>
                                                    {basket.discount ? <Typography
                                                        component="p" 
                                                        variant="h2" 
                                                        className={clsx([classes.cost, classes.discountedCost])}
                                                    >
                                                        {n(round(getDiscountedCost(cost, basket.discount), 2))} &euro;
                                                    </Typography> : null}
                                                </Avatar>
                                            </Box>
                                        </Grid>
                                        {wrapped.isClosable() && <Grid item xs={12}>
                                            <ButtonGroup fullWidth>
                                                <Button 
                                                    disabled={loading}
                                                    fullWidth 
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        setLoading(true);
                                                        onClose().finally(() => setLoading(false));
                                                    }}
                                                >
                                                    {loading ? "Attendere..." : "Chiudi"}
                                                </Button>
                                                {wrapped.isEditable() && <Button 
                                                    disabled={loading}
                                                    fullWidth 
                                                    color="secondary"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        setLoading(true);
                                                        onReserve().finally(() => setLoading(false));
                                                    }}
                                                >
                                                    {loading ? "Attendere..." : "Sospendi"}
                                                </Button>}
                                            </ButtonGroup>
                                        </Grid>}
                                    </Grid>
                                </Container>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                {entities.length ? collection : <Typography className={classes.noProductsText}>Nessun prodotto presente.</Typography>}
                            </Grid>
                            {wrapped.isEditable() && <Grid item xs={12}>
                                <AdderWrapper 
                                    Trigger={({onClick}) => <ButtonGroup fullWidth>
                                        <Button 
                                            fullWidth 
                                            color="primary" 
                                            onClick={onClick} 
                                            variant="outlined"
                                            disabled={loading}
                                        >
                                            Aggiungi prodotto
                                        </Button>
                                        <Button 
                                            fullWidth 
                                            color="secondary" 
                                            variant="outlined" 
                                            onClick={toggleScan}
                                            disabled={loading}
                                        >
                                            Scansione prodotto
                                        </Button>
                                    </ButtonGroup>} 
                                    onSave={onSave}
                                    Editor={BasketProductAdder}
                                    EditorProps={{basketId: basket.id, width: basketProductAdderWidth}}
                                />
                            </Grid>}
                        </Grid>
                    </Container>
                </Box>;
            }
        )
    )
);

export {DiscountContext};
export default Renderer;