import React from "react";
import {ButtonGroup, Button, Box, IconButton, Tooltip} from "@material-ui/core";
import {Link} from "react-router-dom";
import {BasketSideViewContext} from "../../../basket/side-view/basket-side-view.component";
import {CollectionUtilityWrapper} from "js-generic-utilities";
import {Add, Edit, DeleteForever} from "@material-ui/icons";

const Component = ({
    select, 
    noDelete, 
    element, 
    onOperation, 
    onDelete, 
    icons, 
    selectable,
    selectLabel,
    link,
    onSelect
}) => <Box display="flex">
    <ButtonGroup>
        {select && <BasketSideViewContext.Consumer>
            {({products}) => <Button 
                variant="text" 
                color="primary" 
                onClick={() => onSelect ? onSelect(element) : onOperation("select", element)}
                disabled={!selectable || (products && CollectionUtilityWrapper.wrap(products, (a, b) => a.product_id === b.product_id).has({product_id: element.id}))}
            >
                {selectLabel || "Seleziona"}
            </Button>}
        </BasketSideViewContext.Consumer>}
        {link && <Link to={`/product/${element.id}`}>
            {icons ? <Tooltip title="Seleziona" arrow placement="top">
                <IconButton color="primary">
                    <Edit />
                </IconButton>
            </Tooltip> : <Button variant="text" color="primary">
                Modifica
            </Button>}
        </Link>}
        {!noDelete && (icons ? <Tooltip title="Cancella" arrow placement="top">
            <IconButton color="secondary" onClick={() => onOperation("delete", element)}>
                <DeleteForever />
            </IconButton>
        </Tooltip> : <Button 
            variant="text" 
            color="secondary"
            onClick={() => onOperation("delete", element)}
        >
            Cancella
        </Button>)}
    </ButtonGroup>
</Box>;

export default Component;