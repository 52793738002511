import React from "react";
import CategorySearchContext from "../search-component/context/category-search-context";
import {
  Card,
  CardHeader,
  CardActionArea,
  Avatar,
  Button,
  CardActions,
  makeStyles,
  IconButton
} from "@material-ui/core";
import { Label, Add } from "@material-ui/icons";
import clsx from "clsx";
import {isSelected} from "../../auxiliary/auxiliary-component-functions";

const useStyles = makeStyles(theme => ({
  selected: {
    opacity: 0.5
  },
  cardWrapper: {
    transitionProperty: "opacity",
    transitionDuration: "500ms",
    margin: "0.5em 0"
  },
  actionArea: {
    justifyContent: "center", 
    display: "flex"
  }
}));

const CategorySearchCard = ({ onDelete, onEdit, onOperation, element }) => {
  const classes = useStyles();
  return <CategorySearchContext.Consumer>
    {({selectedCategories}) => {
      const selected = selectedCategories && selectedCategories.length && isSelected(element, selectedCategories);
      return <Card 
        data-testid={`category-search-card-${element.id}${selected ? "-selected" : ""}`}
        className={clsx([selected && classes.selected, classes.cardWrapper])}
      >
        <CardHeader
          avatar={<Avatar><Label /></Avatar>}
          title={element.name}
        />
        <CardActionArea>
          <CardActions>
            <Button
              fullWidth
              data-testid={`category-search-card-${element.id}-select`}
              variant="text" 
              color="primary" 
              onClick={() => onOperation("select", element)}
              disabled={selected}
            >
              Seleziona
            </Button>
          </CardActions>
        </CardActionArea>
      </Card>;
    }}
  </CategorySearchContext.Consumer>;
};

export default CategorySearchCard;