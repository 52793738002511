import React from "react";
import BaseProductEditor from "../base-product/base-product-editor.component";
import ProductVariantEditor from "../product-variant/product-variant-editor.component";

const Component = ({product, onSubmit}) => {
    if(product) {
        const isVariant = (product.type === "variant");
        return isVariant ? <ProductVariantEditor element={product} onSave={onSubmit} /> : <BaseProductEditor element={product} onSave={onSubmit} />
    }
    return null;
}

export default Component;