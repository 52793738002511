import React from "react";
import BaseProductEditor from "../../components/product/entity-editors/base-product/base-product-editor.component";
import ProductVariantEditor from "../../components/product/entity-editors/product-variant/product-variant-editor.component";
import {Container, Paper} from "@material-ui/core";
import {HashRouter as Router, Route, Switch} from "react-router-dom";

const TestBaseVariant = ({product, variant}) => <Container maxWidth="lg">
    <Paper style={{minHeight: "100vh"}}>
        <Container maxWidth={false} style={{paddingTop: "1em", paddingBottom: "1em"}}>
            <Router>
                <Switch>
                    <Route path="/" exact>
                        <BaseProductEditor
                            product={product}
                            onSubmit={p => console.log(p)}
                        />
                    </Route>
                    <Route path="/variable_product/1">
                        <BaseProductEditor
                            product={product}
                            onSubmit={p => console.log(p)}
                        />
                    </Route>
                    <Route path="/product_variant/10">
                        <ProductVariantEditor
                            product={variant}
                            onSubmit={p => console.log(p)}
                        />
                    </Route>
                </Switch>
            </Router>
        </Container>
    </Paper>
</Container>;

export default TestBaseVariant;