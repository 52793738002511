import React, { memo, useState, useEffect } from "react";
import ServicesContext from "../../../contexts/services.context";
import { inject } from "js-react-components";
import { e } from "../../../utils/misc-utils-functions";
import {Chip, Grid, Typography, withStyles} from "@material-ui/core";
import ValueRenderer from "./variant-attribute-chip-renderer.component";

const Component = inject(
    ServicesContext,
    ["attributeValue", "errorService"],
    memo(
        withStyles({
            noAttributes: {
                textAlign: "center"
            }
        })(
            /**
             * @param {{
             *      values: number[],
             *      onRemove: (value: number) => void
             * }} param0
             */
            ({values, onRemove, attributeValue, errorService, classes}) => <Grid container spacing={2}>
                {values && values.length ? values.map(value => <Grid item>
                    <ValueRenderer 
                        value={value} 
                        onRemove={onRemove}
                        attributeValueService={attributeValue}
                        errorService={errorService}
                    />
                </Grid>) : <Grid item xs={12}>
                    <Typography component="p" variant="body1" className={classes.noAttributes}>
                        Nessun attributo selezionato.
                    </Typography>
                </Grid>}
            </Grid>
        ),
        (p, n) => e(p.values, n.values)
    )
);

export default Component;