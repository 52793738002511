import {EntityMeta} from "js-generic-utilities";

/**
 * @typedef {"product_id" | "name"} ProductStatSorters
 * @typedef {"product_id" | "name"} ProductStatIndices
 * @typedef {"product_id" | "name" | "value" | "date"} ProductStatAttributes
 * @typedef {{
 *    product_id: number,
 *    name: string,
 *    value: string|number,
 *    date: string
 * }} ProductStat
 */

/**
 * @type {EntityMeta<ProductStat, ProductStat>}
 */
const meta = {
  keyGetter: (e) => String(e.product_id) + String(e.name),
  matcher: (a, b) => {
    const ka = meta.keyGetter(a);
    const kb = meta.keyGetter(b);
    return ka === kb;
  },
  Constructor: function(v) {
      return v;
  }
};

export default meta;
