import React from "react";
import {ReactComponent, PaginatorProps} from "js-react-components";
import { Box, withStyles, IconButton } from "@material-ui/core";
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from "@material-ui/icons";

const Paginator = withStyles({
    box: {
        position: "absolute",
        width: "100%",
        zIndex: 3
    }
})(
    /**
     * @param {PaginatorProps} param0
     */
    ({onNextPage, onPrevPage, classes}) => <Box 
        display="flex" 
        justifyContent="space-between" 
        className={classes.box}
    >
        <IconButton onClick={onPrevPage}>
            <KeyboardArrowLeftOutlined />
        </IconButton>
        <IconButton onClick={onNextPage}>
            <KeyboardArrowRightOutlined />
        </IconButton>
    </Box>

);

export default Paginator;