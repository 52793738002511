import ComponentsContext from "../../../contexts/components.context";
import {ReactComponent, inject, EntityRendererProps} from "js-react-components";
import {Product} from "../../../domain/product";
import ProductListRenderer from "./product-list-renderer.component";
import { 
    Box, ListItem, Divider, ListItemText, ListItemAvatar, Badge,
    makeStyles, Avatar, ButtonGroup, Button, ListItemSecondaryAction, 
    IconButton, Collapse, Grid, Container 
} from "@material-ui/core";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { imgPlaceholder } from "../../../constants";

const useStyles = makeStyles(t => ({
    img: {
        display: "block",
        maxWidth: "calc(min(100%, 100px))"
    },
    listItem: {
        backgroundColor: "rgb(250, 250, 250)",
        borderRadius: "5px", 
        border: "1px solid rgb(222, 222, 222)",
        borderBottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    lel: {
        border: "1px solid rgb(222, 222, 222)",
        borderTop: 0,
        marginBottom: "0.5em"
    }
}));

const Composer = ({collection, baseProduct, classes, searchBar, paginator, filterer, loader, collapsed}) => {
    if(collapsed === undefined) {
        collapsed = true;
    }
    const [state, setState] = useState({collapsed});
    const classes2 = useStyles();
    return <Fragment key={baseProduct.id}>
        <ListItem key={baseProduct.id} className={classes.listItem}>
            <ListItemAvatar>
                <Badge badgeContent={`${baseProduct.variantsCount}v`} variant="standard" color="primary">
                    <Avatar>
                        <img className={classes.img} src={baseProduct.image || imgPlaceholder} alt={baseProduct.name} />
                    </Avatar>
                </Badge>
            </ListItemAvatar>
            <ListItemText 
                primary={baseProduct.name} 
                secondary={`Codice ${baseProduct.code}, ${baseProduct.stock} disponibili`}
            />
            <ListItemSecondaryAction>
                <ButtonGroup>
                    <Button key="variant_show" onClick={() => setState(s => ({...s, collapsed: !s.collapsed}))} variant="text" color="primary">
                        {state.collapsed ? "Espandi" : "Chiudi"}
                    </Button>
                </ButtonGroup>
            </ListItemSecondaryAction>
        </ListItem>
        <Box className={classes2.lel}>
            <Collapse in={!state.collapsed} collapsedHeight={0}>
                <Box 
                    margin="0 1em"
                    width="calc(100% - 2em)" 
                    className="base-product-variant-list" 
                    position="relative"
                >
                    {loader}
                    <Container maxWidth={false} disableGutters style={{margin: "1em 0"}}>
                        <Grid container spacing={1} alignItems="stretch">
                            <Grid item xs={12} sm={4}>
                                <Link to={`/variable_product/${baseProduct.id}/variant/add`}>
                                    <Button color="primary" variant="outlined" fullWidth style={{height: "100%"}}>
                                        Aggiungi variante {baseProduct.name}
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {paginator}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {filterer}
                            </Grid>
                            <Grid item xs={12}>
                                {collection}
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Collapse>
        </Box>
    </Fragment>;
};

const BaseProductVariantList = inject(
    ComponentsContext,
    ["ProductVariant"],
    ({ProductVariant, element, collapsed}) => {
        const classes = useStyles();
        return <ProductVariant 
            defaultFilterAttribute="variable_product_id" 
            defaultFilterValue={element.id} 
            sortAttribute="name"
            sortDirection="asc"
            Composer={Composer}
            ComposerProps={{
                baseProduct: element, 
                classes, 
                collapsed
            }}
            defaultView="table"
        />;
    }
);

export default BaseProductVariantList;