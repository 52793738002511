import React from "react";
import {
    render,
    EntityBaseComponentBuilder, 
    ComposerProps, 
    ReactComponent, 
    makeGridRenderer, 
    makeSearchBar, 
    makePaginator
} 
from "js-react-components";
import {EntityService, HttpOperation} from "js-generic-utilities";
import ImageMeta, {Image, ImageAttributes, ImageIndices, ImageSorters, ImageViews} from "../../domain/image";
import ImageGridRenderer from "./entity-renderers/image-grid-renderer.component";
import {makeComposer} from "../auxiliary/base-composer.component";
import LoadingComponent from "../auxiliary/loading.component";
import ImageEditor from "./entity-editor/image-entity-editor.component";
import { wrapRenderer } from "../auxiliary/missing-elements.component";
import { Image as ImageIcon} from "@material-ui/icons";
import { texts } from "../../constants";

const BaseImageComposer = makeComposer({EditRenderer: ImageEditor});
const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />;

/**
 * @param {{
 *      service: EntityService<HttpOperation>,
 *      composer: ReactComponent<ComposerProps>
 * }} param0 
 */
export default function makeComponent({
    service, composer, Paginator, itemsPerPage, CollectionRenderer, 
    EntityRenderer, componentService, elementsPerRow, alertService, deleteDialogService
}) {
    /**
     * @type {EntityBaseComponentBuilder<"refresh", Image, ImageViews, ImageIndices, ImageSorters, ImageAttributes>}
     */
    let builder = render(service);

    builder.withMeta(ImageMeta)
        .withGrid({
            Entity: EntityRenderer || ImageGridRenderer,
            Collection: wrapRenderer(CollectionRenderer || makeGridRenderer({
                spacing: 2, 
                elementsPerRow: elementsPerRow || {xs: 6, sm: 4, md: 3}
            }), {
                avatarSize: 150,
                Icon: ImageIcon,
                title: texts.missing_elements_title("immagine", true),
                body: texts.missing_elements_body
            })
        })
        .withService(componentService)
        .withSearchBarAttribute({
            attribute: "name",
            label: "Nome immagine"
        })
        .withBounds({start: 0, end: itemsPerPage || 8})
        .withDefaultView("grid")
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .editEntityWith(ImageEditor)
        .withComponents({
            SearchBar: makeSearchBar("image-search-bar"),
            Paginator: Paginator || makePaginator({}, "image-paginator", {
                defaultItemsPerPage: itemsPerPage || 8,
                hideItemsPerPage: true,
                buttons: true,
                allowFirstLastPages: true,
                hideTotalItems: true
            }),
            Filterer: () => null,
            Sorter: () => null,
            Loader: Loader,
            Alert: () => null
        })
        .withOperations([
            {
                name: "delete",
                handler: (e, {onDelete}) => deleteDialogService.show({
                    message: `Confermare la cancellazione dell'immagine ${e.name}?`, 
                    onConfirm: () => onDelete(e)
                })
            }
        ])
        .withCallbacks({
            onAfterSave: e => alertService.show({
                message: `Immagine salvata.`, 
                severity: "success"
            }),
            onAfterDelete: e => alertService.show({
                message: `Immagine ${e.name} cancellata.`, 
                severity: "success"
            }),
            onError: e => alertService.show({
                message: e,
                severity: "error"
            })
        });
    
    return builder.compose(composer || BaseImageComposer);

}
