import React, {useState} from "react";
import {wrapEdit, HTMLCarousel, CarouselService, inject} from "js-react-components";
import {Product} from "../../../../domain/product";
import { ObserverPool, EntityService, HttpOperation} from "js-generic-utilities";
import { Typography, Box, Fade, CircularProgress, makeStyles } from "@material-ui/core";
import LoadingComponent from "../../../auxiliary/loading.component";
import ProductAdderFirstStep from "./product-adder-first-step.component";
import ProductAdderSecondStep from "./product-adder-second-step.component";
import ProductDetailsEditor from "../product-details-entity-editor.component";
import servicesContext from "../../../../contexts/services.context";
import AlertService from "../../../auxiliary/alert-component/alert-service";

const useStyles = makeStyles(theme => ({
    loadingWrapper: {
        height: "100%",
        width: "100%",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "white"
    }
}));

/**
 * @type {CarouselService}
 */
const carouselService = CarouselService.make();

/**
 * @typedef {{
 *      product?: Product,
 *      width: number,
 *      service: EntityService<HttpOperation>
 * }} ProductAdderProps
 * 
 */

/**
 * @param {ProductAdderProps} param0 
 */
const ProductAdder = inject(
    servicesContext,
    ["windowService", "alertService"],
    /**
     * @param {{alertService: AlertService}}
     */
    ({
        product, width, service, onClose, FirstStepProps, alertService, windowService
    })  => {
        
        const [state, setState] = useState({loading: false, product: product, mode: null});
        const classes = useStyles();
        
        const onProductSave = (p, isSecond) => {
            setState(s => {
                return {...s, loading: true};
            });
            console.log(p);
            service.serve("save", p).then(p => {
                console.log(p);
                setState(s => {
                    /*if(isSecond) {
                        onClose();
                    } else {
                        carouselService.next();
                    }*/
                    windowService.href(`/product/${p.id}`);
                    return {...s, product: p, loading: false};
                });
            }).catch(mex => {
                alertService.show({message: mex, severity: "error"});
                setState(s => ({...s, loading: false}));
            })
        };
    
        return <Box margin="1em 0" position="relative">
            <Fade in={state.loading}>
                <Box 
                    zIndex={state.loading ? 99 : -99} 
                    className={classes.loadingWrapper}
                >
                    <LoadingComponent size="3rem" label="Caricamento..." />
                </Box>
            </Fade>
            <HTMLCarousel
                service={carouselService}
                width={width}
                items={[
                    {
                        Component: ProductAdderFirstStep,
                        ComponentProps: {
                            ...(FirstStepProps || {}),
                            product: state.product,
                            onSave: onProductSave
                        }
                    },
                    {
                        Component: ProductAdderSecondStep,
                        ComponentProps: {
                            product: state.product,
                            onSubmit: p => onProductSave(p, true)
                        }
                    }
                ]}
            />
        </Box>;
    }
)

export default ProductAdder;