import {EntityMeta} from "js-generic-utilities";
import {getDefaultMeta} from "./auxiliary/domain-misc-functions";
import moment from "moment";

/**
 * @typedef {"product_id" | "type"} ProductQuantityAlterationSorters
 * @typedef {"product_id" | "type"} ProductQuantityAlterationIndices
 * @typedef {"id" | "product_id" | "type" | "amount" | "date"} ProductQuantityAlterationAttributes
 * @typedef {{
 *    id: number,
 *    date: string,
 *    product_id: number,
 *    type: "increase" | "decrease" | "sale" | "return",
 *    amount: number
 * }} ProductQuantityAlteration
 */

/**
 * @type {EntityMeta<ProductQuantityAlteration>}
 */
const meta = getDefaultMeta({
    Constructor: function(data) {
        return {
            ...data, 
            id: Math.floor(Math.random()*600000+1),
            date: moment().format("YYYY-MM-DD")
        }
    }
});

class ProductQuantityAlterationWrapper {

    /**
     * @type {ProductQuantityAlteration}
     */
    _data;

    /**
     * @param {ProductQuantityAlteration} data 
     */
    constructor(data) {
        this._data = data;
    }

    /**
     * @param {"increase" | "decrease" | "sale" | "return"} type 
     */
    is(trg) {
        const {type} = this._data;
        return type && (type.toLowerCase() === trg);
    }

    isUnload() {
        return this.is("decrease") || this.is("sale");
    }

    getActualAmount() {
        if(this.is("decrease") || this.is("sale")) {
            return -this._data.quantity;
        } else {
            return this._data.quantity;
        }
    }
}

/**
 * @param {ProductQuantityAlteration} data 
 * @return {ProductQuantityAlterationWrapper}
 */
export function wrap(data) {
    return new ProductQuantityAlterationWrapper(data);
}

export default meta;
