import React from "react";
import { withStyles, Box } from "@material-ui/core";

export default withStyles({
  transition: {
    transitionProperty: "width",
    height: "100%"
  }
})(({ collapsed, children, classes, duration, width }) => (
  <Box
    className={classes.transition}
    style={{
      transitionDuration: duration || "750ms",
      width: collapsed ? 0 : width
    }}
  >
    {children}
  </Box>
));
