import makeEditor from "../../auxiliary/base-entity-editor.component";
import CustomerMeta, {CustomerWrapper} from "../../../domain/customer";
import {Person} from "@material-ui/icons";
import { TextField, InputAdornment, Button, CircularProgress } from "@material-ui/core";
import { inject } from "js-react-components";
import ServicesContext from "../../../contexts/services.context";
import { useState } from "react";

const CodeRenderer = inject(
    ServicesContext,
    ["product"],
    ({value, element, originalElement, onChange, product}) => {
        const [state, setState] = useState({loading: false});
        const onGenerateCode = () => {
            setState({loading: true});
            product.serve("generate_code")
                .then(data => {
                    console.log(data);
                    onChange(data.code);
                })
                .catch(mex => alert(mex))
                .finally(() => setState({loading: false}))
        }
        const {loading} = state;
        return <TextField
            fullWidth
            variant="outlined"
            label="Codice cliente"
            defaultValue={value}
            type="text"
            onChange={evt => onChange(evt.target.value)}
            InputProps={{
                endAdornment: <InputAdornment>
                    {loading ? <CircularProgress size="1em" /> : <Button 
                        color="primary"
                        onClick={onGenerateCode}
                    >
                        Genera
                    </Button>}
                </InputAdornment>
            }}
        />
    }
);

const Editor = makeEditor({
    meta: CustomerMeta,
    entityName: "Cliente",
    icon: <Person />,
    attributes: [
        {
            id: "name",
            label: "Nome",
            helperText: "Nome mancante o non valido.",
            type: "text"
        },
        {
            /*id: "code",
            label: "Codice cliente",
            helperText: "Codice mancante o non valido.",
            type: "text"*/
            label: "Codice",
            id: "code",
            helperText: "Codice mancante o non valido.",
            Renderer: CodeRenderer
        },
        {
            id: "address",
            label: "Indirizzo",
            helperText: "Indirizzo mancante o non valido.",
            type: "text"
        },
        {
            id: "phone",
            label: "Telefono",
            helperText: "Telefono mancante o non valido.",
            type: "tel"
        }
    ],
    config: {
        onValidate: (name, e) => {
            let cWrap = new CustomerWrapper(e);
            return cWrap.validate(name, e);
        },
        isValid: e => (new CustomerWrapper(e)).validateAll()
    }
});

export default Editor;