import React, {Fragment} from "react";
import {
  Card,
  CardHeader,
  CardActionArea,
  makeStyles,
  Button,
  CardActions,
  Avatar
} from "@material-ui/core";
import {Label} from "@material-ui/icons";
import {Attribute} from "../../../domain/attribute";
import {EntityRendererProps, ReactComponent} from "js-react-components";
import AttributeSearchContext from "../search/attribute-search.context";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold"
  },
  contentText: {
    textAlign: "center"
  }
}));

/**
 * @type {ReactComponent<EntityRendererProps<Attribute>>}
 */
const AttributeSearchCard = ({ element, onOperation, baseId }) => {
  const classes = useStyles();
  baseId = baseId || "attribute-search-card";
  console.log(element);
  return (
    <AttributeSearchContext.Consumer>
      {({selected}) => <Fragment key={element.id}>
        <Card data-testid={`${baseId}-${element.id}`} key={`${element.id}-card`}>
          <CardHeader
            avatar={<Avatar><Label /></Avatar>}
            title={element.name}
            titleTypographyProps={{
              className: classes.title,
              "data-testid": `${baseId}-${element.id}-name-text`
            }}
            subheader={`Attributo, ${element.valuesNumber || "-"} valori`}
            action={onOperation && <Button
              fullWidth
              key="edit"
              variant="text"
              color="primary"
              onClick={() => onOperation("select", element)}
              data-testid={`attribute-search-card-${element.id}-select`}
              disabled={selected && (selected.indexOf(element.id) !== -1)}
            >
              Seleziona
            </Button>}
          />
          {/*onOperation && <CardActionArea>
            <CardActions>
              <Button
                fullWidth
                key="edit"
                variant="text"
                color="primary"
                onClick={() => onOperation("select", element)}
                data-testid={`attribute-search-card-${element.id}-select`}
                disabled={selected && (selected.indexOf(element.id) !== -1)}
              >
                Seleziona
              </Button>
            </CardActions>
          </CardActionArea>*/}
        </Card>
      </Fragment>}
    </AttributeSearchContext.Consumer>
  );
};

export default AttributeSearchCard;
