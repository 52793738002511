import {EntityMeta} from "js-generic-utilities";
import { getDefaultMeta, ValidationWrapper } from "./auxiliary/domain-misc-functions";
import regexes from "./auxiliary/regex-repository";

/**
 * @typedef {{
 *    id: number,
 *    value: string,
 *    variant_feature_id: number
 * }} AttributeValue
 */

/**
 * @type {EntityMeta<AttributeValue>}
 */
const meta = getDefaultMeta({
    Constructor: function(data) {
        return data ? {...data, id: data.id ? data.id : Math.floor(Math.random()*60000+1)} : data;
    }
});

export function validate(n, data) {
    let w = new ValidationWrapper(data, {
        value: regexes.alphanumeric
    });
    return (n instanceof Array) ? w.validateMulti(n) : w.validate(n);
}

export default meta;
