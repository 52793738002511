import {handle, serve, EntityServiceRepository} from "js-generic-utilities";
import endpoints from "../endpoints";
import { getServiceArgs } from "../../utils/misc-utils-functions";

const getEndpointByType = (t, data) => {
    switch(t) {
        case "variable":
            return "/variable-product";
        case "variant":
            return `/variable-product/${data.variableProduct}/variant`;
        default:
            return "/product";
    }
};

const pathGetter = (data, args) => {
    if(args && (args.filterAttribute === "id")) {
        return `/product/${args.filterValue}`;
    } else {
        return "/product";
    }
}
export function makeProductService({requestManager, costService, includeVariants}) {
    const handlers = [
        handle("get").with({
            method: "GET", 
            path: (d, a) => pathGetter(d, a) + (includeVariants || (a.filterAttribute === "code") ? "?include_variants=true&" : "")
        }).and.after(data => (data instanceof Array) ? data: [data]).build(),
        handle("count").with({
            method: "GET", 
            path: (data, args) => pathGetter(data, args) + "/count" + (includeVariants ? "?include_variants=true&" : "")
        }).build(),
        handle("save").with({
            method: "POST", 
            path: (data) => getEndpointByType(data.type, data),
        }).build(),
        handle("update").with({
            method: "put", 
            path: (data) => `/product/${data.id}`
        }).build(),
        handle("delete").with({
            method: "DELETE", 
            path: (e) => `/product/${e.id}`
        }).build(),
        handle("generate_code").with({
            method: "GET", 
            path: "/product/code"
        }).build(),
        handle("generate_variants").with({
            method: "put", 
            path: ({variableProduct}) => `/variable-product/${variableProduct}/variant/generation`
        }).build()
    ];

    return serve(handlers).using(requestManager).build();
}

export function makeVariableProductService({requestManager, endpoint}) {
    let service = EntityServiceRepository.http(getServiceArgs({
        requestManager, 
        endpoint: endpoint || endpoints.variableProduct
    }));
    service._handlers.push(handle("generate_variants").with({
        method: "put", 
        path: ({variableProduct}) => `/variable-product/${variableProduct}/variant/generation`
    }).build());
    return service;
}