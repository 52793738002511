import React from "react";
import {Typography, Box, withStyles, Grid} from "@material-ui/core";
import {Link} from "react-router-dom";

const EditSuccess = withStyles({
    link: {
        color: "white"
    }
})(
    ({entity, entityName, classes, noLink, subheader, title}) => {
        console.log(entity);
        title = title || "Salvataggio effettuato.";
        return <Grid container spacing={1} component="article" alignItems="flex-end">
            <Grid item>
                <Typography component="p" variant="header">
                    {title}
                </Typography>
            </Grid>
            {(!noLink || subheader) && <Grid item>
                <Typography component="p" variant="subtitle2">
                    {!noLink && entity && <Link 
                        to={`/${entityName}/${entity.id}`}
                        className={classes.link}
                    >
                        Visualizza l'elemento.
                    </Link>}
                    {noLink && subheader}
                </Typography>
            </Grid>}
        </Grid>
    }
);

export default EditSuccess;