import {
    render, EntityBaseComponentBuilder, makeSearchBar, makePaginator, BaseComponentArgs, makeGridRenderer
} from "js-react-components";
import {EntityService, HttpOperation} from "js-generic-utilities";
import AttributeValueMeta, {AttributeValue} from "../../domain/attribute-value";
import AttributeValueListRenderer from "./entity-renderers/attribute-value-list-renderer.component";
import {Grid, Container} from "@material-ui/core";
import {makeComposer} from "../auxiliary/base-composer.component";
import AttributeValueEditor from "./entity-editors/attribute-value-entity-editor.component";
import LoadingComponent from "../auxiliary/loading.component";

const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />;
const DefaultComposer = makeComposer({EditRenderer: AttributeValueEditor, config: {
    hideSearchBar: true, hideAdder: true
}});

/**
 * @typedef {"attribute_id" | "name" | "id"} AttributeValueAttributes
 * @typedef {"attribute_id"} AttributeValueIndices
 * @typedef {"value"} AttributeValueSorters
 * @typedef {"list"} AttributeValueViews
 * @typedef {EntityBaseComponentBuilder<"refresh", AttributeValue, AttributeValueViews, AttributeValueIndices, AttributeValueSorters, AttributeValueAttributes>} AttributeValueBaseComponentBuilder
 * @typedef {BaseComponentArgs<AttributeValue, AttributeValueViews, AttributeValueIndices, AttributeValueSorters, AttributeValueAttributes>} AttributeValueBaseComponentProps
 */

/**
 * @param {{
 *      service: EntityService<HttpOperation>
 * }} param0
 */
export default function makeAttributeValueBaseComponent({
    service, composer, operations, itemsPerPage, GridRenderer, alertService, ListRenderer
}) {

    /**
     * @type {AttributeValueBaseComponentBuilder}
     */
    let builder = render(service);

    if(GridRenderer) {
        builder.withGrid({
            Collection: makeGridRenderer({
                spacing: 1,
                elementsPerRow: {
                    xs: 6,
                    sm: 4
                }
            }),
            Entity: GridRenderer
        });
    } else {
        builder.withList(ListRenderer || AttributeValueListRenderer);
    }

    builder.withMeta(AttributeValueMeta)
        .withComponents({
            Filterer: () => null,
            Sorter: () => null,
            SearchBar: makeSearchBar("attribute-value-search-bar"),
            Paginator: makePaginator({}, "attribute-value-paginator", {
                hideItemsPerPage: true, 
                defaultItemsPerPage: itemsPerPage || 50,
                buttons: true
            }),
            Loader: Loader,
            Alert: () => null
        })
        .withBounds({
            start: 0, 
            end: itemsPerPage || 50,
            itemsPerPage: itemsPerPage || 50
        })
        .withSearchBarAttribute({
            attribute: "full_value",
            label: "Ricerca per valore attributo"
        })
        .withTexts({
            onGetLoading: "Caricamento valori...",
            onSaveLoading: "Salvataggio...",
            onDeleteLoading: "Cancellazione..."
        })
        .withDefaultView(GridRenderer ? "grid" : "list")
        .editEntityWith(AttributeValueEditor)
        .withCallbacks({
            onAfterSave: e => alertService.show({
                message: `Valore ${e.full_value} modificato con successo`, 
                severity: "success"
            }),
            onAfterDelete: e => alertService.show({
                message: `Attributo ${e.name} cancellato.`, 
                severity: "success"
            }),
            onError: e => alertService.show({
                message: e,
                severity: "error"
            })
        });

    if(operations) {
        builder.withOperations(operations);
    }

    return builder.compose(composer || DefaultComposer);
}