import {createContext} from "react";
import {Category} from "../../../../domain/category";

/**
 * @type {{
 *      selectedCategories: Category[]
 * }}
 */
const categorySearchContextArgs = {
    selectedCategories: null
};

export default createContext(categorySearchContextArgs);