import React, {memo, useState, useEffect, Fragment, CSSProperties} from "react";
import {withStyles, Box, Typography, Divider, Button, CircularProgress, IconButton} from "@material-ui/core";
import {Edit, DeleteForever, Check} from "@material-ui/icons";

let inputRef = {ref: null};
let readFile = (f, onSuccess, onError, onLoadStart, onLoadEnd) => {
    const fReader = new FileReader();
    fReader.onloadend = () => {
        onLoadEnd && onLoadEnd();
        if(fReader.result) {
            onSuccess(fReader.result);
        }
    }
    if(onError) {
        fReader.onerror = () => {
            onLoadEnd && onLoadEnd();
            onError(fReader.error);
        };
    }
    onLoadStart && onLoadStart();
    fReader.readAsDataURL(f);
}

/**
 * 
 * @param {{
 *      classes?: any,
 *      placeholder?: string,
 *      defaultValue?: string,
 *      onConfirm: function,
 *      autoSubmit?: boolean,
 *      imageStyle?: CSSProperties
 * }} param0 
 */
const Component = ({classes, placeholder, defaultValue, onConfirm, autoSubmit, imageStyle, noWrapper}) => {

    imageStyle = imageStyle || {};

    const [overlayShown, setOverlayShown] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const [loading, setLoading] = useState(false);

    const onEdit = e => inputRef.ref && inputRef.ref.click();
    const onDelete = e => setValue(null);

    const onImageChange = (/** @type {Event} */ e) => {
        const f = e.target.files[0];
        readFile(f, result => {
            inputRef.ref.value = null;
            setValue(result);
            autoSubmit && onConfirm(result);
        }, mex => console.log(mex), () => setLoading(true), () => setLoading(false));
    };

    useEffect(() => {
        if(value && (defaultValue !== value)) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    return <Box component="article" className={classes.wrapper}>
        <input type="file" style={{display: "none"}} ref={r => inputRef.ref = r} onChange={onImageChange} />
        <Box component="header" display="flex" alignItems="center">
            {!autoSubmit && <Box 
                flex={1} 
                padding="1em" 
                borderRight="1px solid rgb(234, 234, 234);" 
                display="flex" 
                alignItems="center"
                className={classes.imgLabelWrapper}
            >
                <Box flex={1}>
                    <Typography component="h4" variant="h4" className={classes.text}>
                        Immagine iniziale
                    </Typography>
                </Box>
            </Box>}
            {!noWrapper && <Box 
                flex={1} 
                padding="1em" 
                display="flex" 
                alignItems="center"
                className={classes.imgLabelWrapper}
            >
                <Box flex={1}>
                    <Typography component="h4" variant="h4" className={classes.text}>
                        {autoSubmit ? "Immagine" : "Nuova immagine"}
                    </Typography>
                </Box>
                {!autoSubmit && <IconButton onClick={() => value && onConfirm(value)} disabled={!value}>
                    <Check />
                </IconButton>}
            </Box>}
        </Box>
        <Divider />
        <Box component="section" display="flex">
            {!autoSubmit && <Box flex={1} borderRight="1px solid rgb(234, 234, 234);" margin="1em 0">
                <img 
                    src={defaultValue} 
                    className={classes.initialImage}
                    style={imageStyle}
                />
            </Box>}
            <Box flex={1} position="relative" margin="1em 0">
                {!loading ? <Fragment>
                    <Box 
                        component="div" 
                        onMouseLeave={e => setOverlayShown(false)}
                        onMouseEnter={e => setOverlayShown(true)}
                        className={classes.buttonWrapper}
                        style={{opacity: overlayShown ? 0.85 : 0}}
                        display="flex"
                        alignItems="center"
                    >
                        <Button
                            type="button" 
                            startIcon={<Edit />} 
                            title="Modifica"
                            variant="outlined"
                            className={classes.button}
                            onClick={onEdit}
                        >Modifica</Button>
                        {!autoSubmit && <Button 
                            type="button" 
                            startIcon={<DeleteForever />} 
                            title="Cancella"
                            variant="outlined"
                            className={classes.button}
                            onClick={onDelete}
                        >Cancella</Button>}
                    </Box>
                    <img 
                        src={value || placeholder}
                        className={classes.newImage}
                        style={imageStyle}
                    />
                </Fragment> : <Box display="flex" flex={1} justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>}
            </Box>
        </Box>
    </Box>
};

export default memo(withStyles({
    newImage: {
        display: "block", 
        maxWidth: "100%", 
        height: "auto", 
        margin: "auto",
        zIndex: 1
    },
    imgLabelWrapper: {
        backgroundColor: "rgb(244, 245, 253)"
    },
    button: {
        width: "50%",
        margin: "auto", 
        color: "white", 
        backgroundColor: "rgb(32,132,232)"
    },
    buttonWrapper: {
        position: "absolute", 
        width: "100%", 
        height: "100%", 
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        zIndex: 2,
        cursor: "pointer",
        transitionProperty: "opacity",
        transitionDuration: "500ms",
        backgroundColor: "rgb(234, 234, 234)"
    },
    initialImage: {
        display: "block", 
        maxWidth: "100%", 
        height: "auto", 
        margin: "auto"
    },
    wrapper: {
        maxHeight: "300px", 
        width: "100%"
    },
    text: {
        textAlign: "center",
        fontWeight: "bold",
        color: "rgb(125, 125, 125)"
    }
})(Component), (prev, next) => prev.defaultValue === next.defaultValue);