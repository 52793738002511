import {EntityMeta} from "js-generic-utilities";
import { getDefaultMeta, ValidationWrapper } from "./auxiliary/domain-misc-functions";
import regexes from "./auxiliary/regex-repository";
import moment from "moment";

/**
 * @typedef {"list" | "grid"} Basketviews
 * @typedef {"date"} BasketIndices
 * @typedef {"date"} BasketSorters
 * @typedef {"id" | "date" | "cost" | "status" | "product_count" | "customer_id"} BasketAttributes
 * @typedef {"open" | "closed" | "paid"} BasketStatus
 * @typedef {{
 *    id: number,
 *    date: string,
 *    customer_id: number,
 *    cost: number,
 *    product_count?: number,
 *    status: BasketStatus
 * }} Basket
 */

/**
 * @type {EntityMeta<Basket>}
 */
const meta = getDefaultMeta({
  Constructor: function(v) {
    v = v ? {...v} : {};
    if(!v.date) {
      v.date = moment().format("YYYY-MM-DD");
    }
    return v;
  }
});

export class BasketWrapper {

  /**
   * @type {Basket}
   */
  _data;

  constructor(data) {
    this._data = {...data};
  }
  
  /**
   * @param {"open" | "closed" | "paid"} target 
   */
  is(target) {
    return this._data.status && (this._data.status.toLowerCase() === target);
  }

  valid() {
    return this._data && (typeof(this._data.id) === "number");
  }

  isPaid() {
    return this.is("paid");
  }

  isClosed() {
    return this.is("closed");
  }

  isReserved() {
    return this.is("reserved");
  }

  isOpen() {
    return this.is("open");
  }

  isEmpty() {
    return !this._data.product_count;
  }

  close() {
    this.status("PAID");
    return this;
  }

  reserve() {
    this.status("RESERVED");
    return this;
  }

  abort() {
    this.status("ABORTED");
    return this;
  }

  confirm() {
    this.status("CONFIRMED");
    return this;
  }

  isClosable() {
    return this.isOpen() || this.isReserved();
  }

  isReservable() {
    return this.isOpen();
  }

  isDeletable() {
    return this.isOpen();
  }

  isConfirmableOrAbortable() {
    return this.isPaid() && (this._data.origin === "app");
  }

  isEditable() {
    return this.isOpen();
  }

  isDelivery() {
    const {delivery} = this._data;
    return Boolean(delivery);
  }

  isPaypalAwaitingPayment() {
    return (this._data.status.toLowerCase() === "closed") && this._data.cartPaymentType && (this._data.cartPaymentType.toLowerCase() === "paypal");
  }

  canBeSetPaid() {
    return this.isClosed() && (this._data.origin === "app") && !this.isPaypalAwaitingPayment();
  }

  isFromWoocommerce() {
    return Boolean(parseInt(this._data.woocommerce)); 
  }

  getOrigin() {
    let {origin} = this._data;
    if(!origin) {
      origin = "cms";
    }
    return origin.charAt(0).toUpperCase();
  }

  address() {
    return this._data.address;
  }

  deliveryZone() {
    return this._data.deliveryZone && this._data.deliveryZone.name;
  }

  inPlace() {
    return this._data.deliveryZone && (this._data.deliveryZone.id === 0);
  }

  description() {
    const added = "";//this.isFromWoocommerce() ? "woocommerce" : "magazzino";
    switch(this._data.status && this._data.status.toLowerCase()) {
      case "confirmed":
        return "Carrello confermato";
      case "aborted":
        return "Carrello annullato";
      case "active":
        return `Carrello attivo`;
      case "open":
        return `Carrello ${added} aperto`;
      case "reserved":
        return `Carrello ${added} sospeso`;
      case "closed":
        return `Carrello ${added} chiuso in attesa di pagamento`;
      default:
        return "Carrello pagato";
    }
  }

  status(t) {
    this._data.status = t || "paid";
    return this;
  }

  get() {
    return this._data;
  }
}

export function wrap(data) {
  return new BasketWrapper(data);
}

export function validate(n, data) {
  let w = new ValidationWrapper(data, {date: regexes.date});
  return (n instanceof Array) ? w.validateMulti(n) : w.validate(n);
}

export default meta;
