import {createContext} from "react";

const components = {
    Attribute: null,
    AttributeValue: null,
    Category: null,
    BaseProductSearch: null,
    AttributeValueSearch: null,
    CategorySearch: null,
    ProductCategory: null
};

export default createContext(components);