import React from "react";
import {ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Typography} from "@material-ui/core";
import {SwapHoriz} from "@material-ui/icons";
import moment from "moment";
import {typesToText} from "./auxiliary";

/**
 * @type {ReactComponent<EntityRendererProps<ProductQuantityAlteration>>} 
 */
const ListRenderer = ({element, onSave}) => {
    const isUnload = (element.type === "decrease");
    return <ListItem>
        <ListItemAvatar>
            <Avatar>
                <SwapHoriz />
            </Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={moment(element.date).format("DD-MM-YYYY")}
            secondary={typesToText[element.type]}
        />
        <ListItemSecondaryAction>
            <Typography component="p" variant="body1" style={{fontWeight: "bold", color: isUnload ? "red" : "green"}}>
                {isUnload ? `+${element.amount}` : `-${element.amount}`}
            </Typography>
        </ListItemSecondaryAction>
    </ListItem>;
};

export default ListRenderer;