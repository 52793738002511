class DelayedExecutorService {

    /**
     * @type {number}
     */
    _timer;

    constructor() {
        this._timer = null;
    }

    delay(callback, timeout) {
        if(timeout) {
            if(this._timer) {
                clearTimeout(this._timer);
            }
            this._timer = setTimeout(callback, timeout);
        } else {
            callback();
        }
    }
}

export default DelayedExecutorService;