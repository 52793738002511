import {Container, Grid} from "@material-ui/core";
import React, {ReactNode} from "react";

/**
 * @typedef {{
 *      name: string,
 *      label: string,
 *      subheader?: string,
 *      Renderer: Function,
 *      xAttr?: string,
 *      yAttr?: string,
 *      avatar: ReactNode
 * }} StatGroupElement
 * @param {{
 *      stats: StatGroupElement[]
 * }} param0 
 */
const StatGroupCollectionRenderer = ({elements, stats, GroupRenderer}) => {
    return <Container maxWidth={false} disableGutters>
        <Grid container spacing={3} justify="center">
            {stats.map(({name, label, Renderer, avatar, xAttr, subheader}) => <Grid item xs={12} md={6}>
                <GroupRenderer
                    elements={elements}
                    name={name}
                    label={label}
                    Renderer={Renderer}
                    subheader={subheader}
                    avatar={avatar}
                    xAttr={xAttr}
                />
            </Grid>)}
        </Grid>
    </Container>;
}

export default StatGroupCollectionRenderer;