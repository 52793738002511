import React, { Fragment, useState } from "react";
import {EntityRendererProps, BaseComponentArgs, inject} from "js-react-components";
import {Attribute} from "../../../domain/attribute";
import AttributeValueComponentContext from "../contexts/attribute-value-component.context";
import ComponentsContext from "../../../contexts/components.context";
import AdderWrapper from "../../auxiliary/editors/adder-wrapper.component";
import AttributeValueEditor from "../entity-editors/attribute-value-entity-editor.component";
import SettingsContext from "../../../contexts/settings.context";
import OperationsMenu from "../../auxiliary/operations-menu.component";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from "@material-ui/core";

import {
  Folder,
  KeyboardArrowDown,
  KeyboardArrowRight
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  verticalLine: {
    width: "1px", 
    backgroundColor: "rgb(111, 111, 111)"
  },
  lineBox: {
    width: "50px",
    marginRight: "1em",
    height: "1px",
    backgroundColor: "rgb(111, 111, 111)"
  },
  listItem: {
    backgroundColor: "rgb(250, 250, 250)",
    borderRadius: "5px", 
    border: "1px solid rgb(222, 222, 222)",
    borderBottom: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  lel: {
    border: "1px solid rgb(222, 222, 222)",
    borderTop: 0,
    marginBottom: "0.5em"
  }
}));

/**
 * @param {EntityRendererProps<Attribute>} param0 
 */
const AttributeListRendererComposer = inject(
  SettingsContext,
  ["smallScreen"],
  ({ onSelect, onDelete, onSave, onOperation, attribute, collection, loader, smallScreen }) => {
    const classes = useStyles();
    const [state, setState] = useState(false);
    //const [element, setElement] = useState({attribute_id: attribute.id});
    const toggleState = () => setState((s) => !s);
    const valuesButton = <Button
      color="primary"
      variant="text"
      onClick={toggleState}
      data-testid={`attribute-list-element-${attribute.id}-view-values`}
      endIcon={state ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
    >
      {!smallScreen && "Valori"}
    </Button>;
    return (
      <Fragment>
        <ListItem 
          className={classes.listItem} 
          key={attribute.id} 
          data-testid={`attribute-list-element-${attribute.id}`}
        >
          <ListItemAvatar>
            <Avatar>
              <Folder />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={attribute.name} secondary={`${attribute.valuesNumber || "?"} valori` + (smallScreen ? "" : `, nome mostrato ${attribute.shownName || attribute.name}`)} />
          <ListItemSecondaryAction>
            {!smallScreen ? <ButtonGroup>
              <Button 
                color="primary" 
                variant="text" 
                data-testid={`attribute-list-element-${attribute.id}-edit`}
                onClick={() => onSelect(attribute)}
              >
                Modifica
              </Button>
              <Button 
                color="secondary" 
                variant="text" 
                data-testid={`attribute-list-element-${attribute.id}-delete`}
                onClick={() => onOperation("delete", attribute)}
              >
                Cancella
              </Button>
              {valuesButton}
            </ButtonGroup> : <Fragment>
              {valuesButton}
              <OperationsMenu
                operations={[
                  {
                    label: "Cancella",
                    handler: () => onOperation("delete", attribute)
                  },
                  {
                    label: "Modifica",
                    handler: () => onSelect(attribute)
                  }
                ]}
              />
            </Fragment>}
          </ListItemSecondaryAction>
        </ListItem>
        <Box className={classes.lel}>
          <Collapse collapsedHeight={0} in={state}>
            <Box 
              margin="0.5em 1em 0 1em"
              width="calc(100% - 2em)" 
              display="flex"
              position="relative"
              flexDirection="column"
            >
                {loader}
                <AdderWrapper 
                  onSave={onSave}
                  Editor={AttributeValueEditor} 
                  element={{variant_feature_id: attribute.id}}
                  Trigger={({onClick}) => <Box display="flex" alignItems="center">
                    <Button 
                      fullWidth
                      onClick={onClick} 
                      variant="outlined"
                      color="primary"
                    >
                      Aggiungi valore attributo {attribute.name}
                    </Button>
                  </Box>}
                />
                {collection}
            </Box>
          </Collapse>
        </Box>
      </Fragment>
    );
  }
)

  const AttributeListRenderer = inject(
    ComponentsContext,
    ["AttributeValue"],
    ({AttributeValue, element, onSelect, onDelete, onOperation}) => <AttributeValue 
      filterAttribute="variant_feature_id" 
      filterValue={element.id} 
      Composer={AttributeListRendererComposer}
      ComposerProps={{attribute: element, onSelect, onDelete, onOperation}}
    />
  )

  export default AttributeListRenderer;