import React, { useState, useEffect } from "react";
import {Tabs, Tab, Grid, Box} from "@material-ui/core";
import { inject } from "js-react-components";
import ComponentsContext from "../../../contexts/components.context";
import {HTMLCarousel, CarouselService, CustomCarouselService, ReactComponent} from "js-react-components";

/**
 * @type {CustomCarouselService}
 */
const carouselService = CarouselService.make();

/**
 * @type {ReactComponent<{width: number}>}
 */
const Component = inject(
    ComponentsContext,
    ["BaseProductAlt", "ProductVariant"],
    ({BaseProductAlt, ProductVariant, width}) => {
        const [view, setView] = useState(0);

        /*useEffect(() => {
            carouselService.switch(view);
        }, [view]);*/
    
        return <Grid container spacing={3}>
            <Grid item xs={12}>
                <Tabs value={view} onChange={(e, v) => setView(v)}>
                    <Tab value={0} label="Lista" />
                    <Tab value={1} label="Vista gerarchica" />
                </Tabs>
            </Grid>
            <Box marginLeft="15px">
                {(view === 1) ? <BaseProductAlt 
                    defaultView="list" 
                    defaultFilterAttribute="type" 
                    defaultFilterValue="variable" 
                    sortAttribute="name"
                    sortDirection="asc"
                /> : null}
                {(view === 0) ? <ProductVariant defaultView="grid" /> : null}
            </Box>
        </Grid>
    }
);

export default Component;