import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeProductQuantityAlterationService({requestManager}) {
    const handlers = [
        handle("get").with({method: "GET", path: (d, e) => (e.filterAttribute === "product_id")? `/product/${e.filterValue}/stock-alteration` : `/stock-alteration`}).build(),
        handle("count").with({method: "GET", path: (d, e) => (e.filterAttribute === "product_id")? `/product/${e.filterValue}/stock-alteration/count` : `/stock-alteration/count`}).build(),
        handle("save").with({method: "POST", path: e => `/product/${e.product_id}/stock-alteration`}).build(),
        handle("delete").with({method: "DELETE", path: e => {
            console.log(e);
            return `/product/${e.product_id}/stock-alteration/${e.id}`;
        }}).build()
    ];

    return serve(handlers).using(requestManager).build();
}