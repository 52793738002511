import React from "react";
import {Container, Grid, Typography, Button, makeStyles, Box, Paper} from "@material-ui/core";
import {Link} from "react-router-dom";
import clsx from "clsx";

const styles = makeStyles(theme => ({
    header: {
        color: "red",
        fontWeight: "bold"
    },
    text: {
        textAlign: "center"
    },
    container: {
        display: "flex",
        alignItems: "center",
        margin: "calc(-1em - 2px)",
        height: "calc(100% + 2em + 4px)",
        background: "rgb(245, 245, 245)",
        justifyContent: "center"
    },
    paper: {
        padding: "1em 0",
        maxWidth: "576px"
    }
}));

const Component = () => {
    const classes = styles();
    return <Container maxWidth={false} className={classes.container} style={{width: "calc(100% + 2em + 4px)",}}>
        <Paper className={classes.paper}>
            <Grid container spacing={2} component="article">
                <Grid item xs={12} component="header">
                    <Typography 
                        component="h1" 
                        variant="h1" 
                        className={clsx([classes.header, classes.text])}
                        gutterBottom
                    >
                        Accesso negato.
                    </Typography>
                </Grid>
                <Grid item xs={12} component="section">
                    <Typography
                        component="p"
                        variant="h4"
                        gutterBottom
                        className={classes.text}
                    >
                        Non sei autorizzato a visualizzare questa pagina.
                    </Typography>
                </Grid>
                <Grid item xs={12} component="footer">
                    <Box display="flex" justifyContent="center" component="nav">
                        <Link to="/">
                            <Button type="button" variant="outlined" color="primary">
                                Effettua il login per continuare
                            </Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    </Container>
};

export default Component;
