import React, {useState} from "react";
import {Delete} from "@material-ui/icons";
import { Box, makeStyles, IconButton, Typography, Fade } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    img: {
        display: "block",
        maxWidth: "100%",
        boxSizing: "border-box",
        zIndex: 1,
        transitionProperty: "opacity",
        transitionDuration: "500ms"
    },
    wrapper: {
        position: "relative"
    },
    menu: {
        position: "absolute"
    },
    menuWrapper: {
        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "rgb(0, 0, 0, 0.65)",
        zIndex: 2,
        padding: "0.25em 0"
    },
    imgHover: {
        opacity: 0.65
    },
    button: {
        color: "white"
    },
    text: {
        color: "white",
        marginLeft: "0.5em"
    }
}));

const Renderer = ({onRemove, element}) => {
    const [menu, setMenu] = useState(null);
    const classes = useStyles();

    return element ? <Box 
        className={classes.wrapper} 
        onMouseEnter={evt => setMenu(evt.target)}
        onMouseLeave={() => setMenu(null)}
    >
        <Fade in={Boolean(menu)}>
            <Box className={classes.menuWrapper}>
                <Typography component="p" variant="body1" className={classes.text}>
                    {element.name}
                </Typography>
                <IconButton size="small" onClick={onRemove} className={classes.button}>
                    <Delete />
                </IconButton>
            </Box>
        </Fade>
        <img 
            src={element.content || element.url || element.full_url} 
            alt={element.name} 
            className={clsx([classes.img, menu && classes.imgHover])}
        />
    </Box> : null;
};

export default Renderer;