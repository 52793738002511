import makeEditor from "../../auxiliary/base-entity-editor.component";
import BasketMeta, {validate} from "../../../domain/basket";
import {ShoppingBasket} from "@material-ui/icons";
import AsyncEntityLoader from "../../auxiliary/async-entity-loader/async-entity-loader.component";
import {inject} from "js-react-components";
import ServicesContext from "../../../contexts/services.context";
import {CircularProgress} from "@material-ui/core";
import EntityValuePrinter from "../../auxiliary/entity-value-printer.component";

const CustomerRenderer = inject(
    ServicesContext,
    ["customer"],
    ({value, customer}) => <AsyncEntityLoader
        id={value}
        onGet={id => customer.serve("get", null, {filterAttribute: "id", filterValue: id})}
        Renderer={({entity}) => entity ? <EntityValuePrinter label="Cliente" value={entity.name} /> : null}
        LoadingComponent={CircularProgress}
    />
);

const Editor = makeEditor({
    meta: BasketMeta,
    entityName: "Carrello",
    icon: <ShoppingBasket />,
    attributes: [
        {
            id: "customer_id",
            label: "Cliente",
            Renderer: CustomerRenderer
        },
        {
            id: "date",
            label: "Data",
            type: "date",
            helperText: "Data mancante o non valido",
            GridProps: {
                style: {display: "flex"}
            },
            TextFieldProps: {
                style: {alignSelf: "flex-end"}
            }
        }
    ],
    config: {
        onValidate: validate,
        isValid: e => validate(["date"], e),
        noCurrentDetails: true
    }
});

export default Editor;