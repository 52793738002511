import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

function getPath({filterAttribute, filterValue}) {
    switch(filterAttribute) {
        case "variable_product_id":
            return `/variable-product/${filterValue}/variant`;
        case "id":
            return `/product/${filterValue}`;
        default:
            return "/variant";
    }
}

/**
 * @param {{
 *      requestManager: RequestManager
 * }} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeProductVariantService({requestManager}) {
    const handlers = [
        handle("get")
            .with({
                method: "GET", 
                path: (d, e) => getPath(e)
            })
            .build(),
        handle("count")
            .with({
                method: "GET", 
                path: (d, e) => getPath(e) + "/count"
            })
            .build(),
        handle("save")
            .with({
                method: "POST", 
                path: e => `/variable-product/${e.variableProduct}/variant`
            })
            .build(),
        handle("update")
            .with({
                method: "PUT", 
                path: e => `/variable-product/${e.variableProduct.id}/variant/${e.id}`
            })
            .build(),
        handle("delete")
            .with({
                method: "DELETE", 
                path: e => {
                    console.log(e);
                    return `/variable-product/${e.variableProduct.id}/variant/${e.id}`;
                }
            })
            .build()
    ];

    return serve(handlers).using(requestManager).build();
}