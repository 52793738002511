import React, {CSSProperties, useEffect, useState } from "react";
import AlertService from "./alert-service";
import {Fade, Typography, Box, Collapse} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import isReact from "is-react";

const isObject = e => typeof(e) === "object";

/**
 * @param {{service: AlertService, style: CSSProperties, hideTime?: number}} param0 
 */
const Component = ({service, style, hideTime}) => {

    const [state, setState] = useState({message: null, severity: null, zIndex: -9999});
    const onShow = (stuff) => {
        /*console.log(stuff);
        if(typeof(stuff.message) !== "string" && !is) {
            stuff.message = JSON.stringify(stuff.message);
        }*/
        setState(s => ({...s, ...stuff}));
    };
    const onHide = () => setState(s => ({...s, severity: null, message: null}));

    useEffect(() => {
        service.onShow(onShow);
        service.onHide(onHide);
        return () => {
            service.removeListener("show", onShow);
            service.removeListener("hide", onHide);
        }
    }, []);

    useEffect(() => {
        state.severity && state.message && setTimeout(onHide, hideTime || 2000);
    }, [state]);

    const shown = state.message && state.severity;
    
    return <Box style={{
        ...style, 
        zIndex: state.zIndex
    }}>
        <Collapse
            collapsedHeight={0}
            in={Boolean(shown)}
            onEntering={() => setState(s => ({...s, zIndex: 99999}))}
            onExited={() => setState(s => ({...s, zIndex: -99999}))}
        >
            <Box data-testid="alert-wrapper">
                {shown && <Alert 
                    severity={state.severity} 
                    variant="filled"
                    onClose={onHide}
                >
                    <Typography component="span" data-testid="alert-message">
                        {isReact.element(state.message) || (typeof(state.message) === "string") ? state.message : (state.message.message ? state.message.message : JSON.stringify(state.message))}      
                    </Typography>  
                </Alert>}
            </Box>
        </Collapse>
    </Box>;
};

export default Component;