import React from "react";
import {TextField, FormControl, Typography} from "@material-ui/core";

const Component = ({
    value, defaultValue, onChange, label, TextFieldProps, id, type, LabelProps, inputLabel
}) => <FormControl fullWidth component="fieldset" style={{alignSelf: "flex-end"}}>
    {!inputLabel && <Typography 
        {...(LabelProps || {})}
        component="label" 
        htmlFor={id}
        variant="h4"
        gutterBottom
    >
        {label}
    </Typography>}
    <TextField
        {...TextFieldProps}
        data-testid={id}
        label={inputLabel && label}
        fullWidth
        type={type}
        variant="outlined"
        inputProps={{id}}
        value={value}
        defaultValue={defaultValue}
        onChange={evt => onChange(evt.target.value)}
    />
</FormControl>;

export default Component;