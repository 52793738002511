import {EntityMeta} from "js-generic-utilities";
import {getDefaultMeta, ValidationWrapper} from "./auxiliary/domain-misc-functions";
import regexes from "./auxiliary/regex-repository";
import { colors } from "@material-ui/core";

/**
 * @typedef {"name" | "cost" | "quantity" | "variantsCount"} ProductSorters
 * @typedef {"type" | "name" } ProductIndices
 * @typedef {"id" | "name" | "description" | "cost" | "woocommerce_cost" | "quantity" | "type"} ProductAttributes
 * @typedef {{
 *    id: number,
 *    name: string,
 *    description: string,
 *    short_description: string,
 *    cost: number,
 *    code: string,
 *    woocommerce_cost: number,
 *    quantity: number,
 *    variantsCount?: number,
 *    variable_product_id?: number,
 *    variantAttributeValues?: number[],
 *    type: "simple" | "variable" | "variant",
 *    woocommerceId?: number,
 *    weight: number,
 *    width: number,
 *    height: number,
 *    depth: number
 * }} Product
 */

/**
 * @type {EntityMeta<Product>}
 */
const meta = getDefaultMeta({
  collections: [
    {attribute: "variantAttributeValues", matcher: (a, b) => a === b}
  ],
  Constructor: function(data) {
    data = data || {};
    
    const out = {
      ...data, 
      id: data.id || Math.floor(Math.random() * 600000 + 1),
      variantAttributeValues: data.variantAttributeValues || []
    };
    return out;
  }
});

export function validate(names, data) {
  let w = new ValidationWrapper(data, {
    name: regexes.text,
    description: regexes.text,
    code: regexes.alphanumeric,
    cost: regexes.float,
    woocommerce_cost: regexes.float,
    weight: regexes.float,
    depth: regexes.float,
    height: regexes.float,
    width: regexes.float
  });

  return (names instanceof Array) ? w.validateMulti(names) : w.validate(names);
}

export class ProductWrapper {

  static wrap(data) {
    return new ProductWrapper(data);
  }

  /**
   * @type {Product}
   */
  _data;

  constructor(data) {
    this._data = data;
  }

  /**
   * 
   * @param {"variable" | "variant" | "simple"} type 
   */
  is(type) {
    return this._data.type === type;
  }

  hasType() {
    return Boolean(this._data.type);
  }

  isBasketable() {
    return !this.is("variable") && !this.hasVariants() && !this.isOver();
  }

  hasVariants() {
    return (this.is("variable") && this._data.variantsCount);
  }

  isOver() {
    return this._data.stock === 0;
  }

  isOnWoocommerce() {
    return Boolean(this._data.woocommerceId);
  }

  printType() {
    switch(this._data.type) {
      case "variable":
        return "Prodotto variabile";
      case "variant":
        return "Variante prodotto";
      case "simple":
        return "Prodotto semplice";
      default:
        return this._data.type;
    }
  }

  isRiskyStock() {
    return this._data.stock < 7;
  }

  minCost() {
    return Math.min(this._data.cost, this._data.woocost);
  }

  maxCost() {
    return Math.max(this._data.cost, this._data.woocost);
  }

  getStockColor() {
    if(this.isOver()) {
      return colors.red["500"];
    } else {
      if(this.isRiskyStock()) {
        return colors.orange["500"];
      } else {
        return colors.green["500"];
      }
    }
  }
}

/**
 * @return {ProductWrapper}
 * @param {Product} data 
 */
export function wrap(data) {
  return new ProductWrapper(data);
}

export default meta;
