import React from "react";
import {
    EntityBaseComponentBuilder, 
    render, 
    ComposerProps, 
    ReactComponent, 
    makeGridRenderer,
    EntityRendererProps,
    makePaginator,
    wrapEdit
} from "js-react-components";
import {EntityService, HttpOperation, ObserverPool} from "js-generic-utilities";
import ProductCostMeta, {
    ProductCost, 
    ProductCostAttributes, 
    ProductCostIndices, 
    ProductCostSorters
} from "../../domain/product-cost";
import CategoryChipDelayedRenderer from "../category/entity-renderers/category-chip-delayed-renderer.component";
import LoadingComponent from "../auxiliary/loading.component";
import { Box, TextField } from "@material-ui/core";
import ProductCostCollectionRenderer from "./product-cost-collection-renderer";
import ProductCostEditor from "./product-cost-editor";
import DefaultLoader from "../auxiliary/default-loader.component";

/**
 * @param {ComposerProps} param0 
 */
const Composer = ({collection, loader}) => <Box position="relative">
    {collection}
    {loader}
</Box>;

/**
 * @typedef {"grid"} ProductCostViews
 * @typedef {EntityBaseComponentBuilder<"refresh", ProductCost, ProductCostViews, ProductCostIndices, ProductCostSorters, ProductCostAttributes>} ProductCostBaseComponentBuilder
 * @param {{
 *      componentService: ObserverPool<"refresh">,
 *      service: EntityService<HttpOperation>
 * }} param0 
 */
export default function makeComponent({service, componentService, alertService}) {
    /**
     * @type {ProductCategoryBaseComponentBuilder}
     */
    let builder = render(service);

    builder
        .withMeta(ProductCostMeta)
        .withGrid({
            Collection: ProductCostCollectionRenderer,
            Entity: ProductCostEditor
        })
        .withFilter({
            attribute: "product_id"
        })
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .withService(componentService)
        .withDefaultView("grid")
        .withComponents({
            Alert: () => null,
            Loader: DefaultLoader,
            Filterer: () => null,
            Sorter: () => null,
            Paginator: makePaginator({}, "product-cost-paginator", {defaultItemsPerPage: 99999})
        })
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere",
        })
        .withBounds({
            start: 0, 
            end: 99999
        });

        builder.withCallbacks({
            onError: e => alertService.show({
                message: e,
                severity: "error"
            }),
            onAfterSave: e => alertService.show({
                message: e.message || "Costo modificato.",
                severity: e.message ? "warning" : "success"
            })
        });
    
    const Component = builder.compose(Composer);
    return ({productId}) => <Component filterValue={productId} />;
}
