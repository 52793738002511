import React, { useState } from "react";
import ComponentsContext from "../../../contexts/components.context";
import {inject, ReactComponent, HTMLCarousel, CarouselService, CustomCarouselService} from "js-react-components";
import {Product} from "../../../domain/product";
import BasketProductMeta from "../../../domain/basket-product";
import {Box, Container, Grid, Typography, Divider} from "@material-ui/core";
import {EntityWrapper} from "js-generic-utilities";
import BasketProductEditor from "./basket-product-editor.component";
import ComponentSwitcher from "../../auxiliary/component-switcher/component-switcher.component";
import ComponentSwitcherService from "../../auxiliary/component-switcher/component-switcher.service";

const makeElement = data => BasketProductMeta.Constructor(data);

/**
 * @type {ComponentSwitcher}
 */
const service = ComponentSwitcherService.make();
const SearchWrapper = ({onSelect, Component}) => <Container maxWidth="md">
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography component="header" variant="h2">Selezione prodotto</Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={12}>
            <Component onSelect={onSelect} />
        </Grid>
    </Grid>
</Container>;

const Component = inject(
    ComponentsContext,
    ["BasketProductSelectSearch"],
    /**
   * @param {{
    *      BasketProductSelectSearch: ReactComponent<{onSelect: (p: Product) => void}>,
    *      basketId: number,
    *      width: number,
    *      onSave: (basketProduct: BasketProduct) => void
    * }}
    */
    ({BasketProductSelectSearch, basketId, width, onSave}) => {
        const [element, setElement] = useState(makeElement({cart_id: basketId}));
  
        /**
         * @param {Product} p 
         */
        const setProduct = p => {
            setElement(e => {
                service.next();
                return EntityWrapper.wrap({entity: e, ...BasketProductMeta}).set("product_id", p.id).unwrap();
            });
        }
  
        return <Box position="relative">
            <ComponentSwitcher
                 service={service}
                 initialSelected={0}
                 items={[
                     {
                         Component: SearchWrapper,
                         ComponentProps: {
                             onSelect: setProduct,
                             Component: BasketProductSelectSearch
                         }
                     },
                     {
                         Component: BasketProductEditor,
                         ComponentProps: {
                             element,
                             onSave: onSave
                         }
                     }
                 ]}
             />
        </Box>;
    }
);

export default Component;