import React, {createContext, useState, useEffect, ReactChild} from "react";
import HorizontalCollapse from "../../auxiliary/horizontal-collapse.component";
import {Basket, wrap} from "../../../domain/basket";
import {
    Box, List, Grid, Typography, Divider, 
    Container, Button, withStyles, CircularProgress, 
    Avatar, makeStyles, ButtonGroup
} from "@material-ui/core";
import ComponentsContext from "../../../contexts/components.context";
import { inject } from "js-react-components";
import {ObserverPool, notify} from "js-generic-utilities";
import {ShoppingBasket, FormatListNumberedRtlOutlined} from "@material-ui/icons";
import BasketProductListRenderer from "../entity-renderer/basket-product-list-renderer.component";
import BasketSideViewService from "./basket-side-view.service";
import EntityValuePrinter from "../../auxiliary/entity-value-printer.component";
import AsyncEntityLoader from "../../auxiliary/async-entity-loader/async-entity-loader.component";
import ServicesContext from "../../../contexts/services.context";
import servicesContext from "../../../contexts/services.context";
import WindowService from "../../../services/auxiliary/window-service";
import { round } from "../../../utils/misc-utils-functions";
import BasketSideViewRenderer from "./basket-side-view-renderer.component";
import componentsContext from "../../../contexts/components.context";
import {DiscountContext} from "../entity-renderer/auxiliary/basket-product-list-inner-renderer.component";

const hash = () => Math.random()*999999+1;

const useStyles = makeStyles(theme => ({
    missingBasketAvatar: {
        width: "150px",
        height: "150px",
        marginBottom: "1em",
        opacity: 0.5
    },
    missingBasketAvatarIcon: {
        height: "75%",
        width: "75%"
    },
    missingBasketTexts: {
        margin: "1em 0",
        color: "rgb(112, 112, 112)",
        textAlign: "center"
    }
}))

/**
 * @param {{
 *      service: BasketSideViewService
 * }} param0 
 */
const BasketSideViewProvider = inject(
    ServicesContext,
    ["basketProduct"],
    ({service, children, basketProduct}) => {

        const [basket, setBasket] = useState(null);
        const [products, setProducts] = useState([]);

        const reload = (noHash, basket) => setBasket(b => ({...(basket || b), hash: noHash ? b.hash : hash()}));
        const refresh = basket => reload(false, basket);

        useEffect(() => {
            service.onRefresh(refresh);
            service.onReload(reload);
            return () => {
                service.remove(refresh);
                service.remove(reload);
            };
        }, []);

        useEffect(() => {
            if(basket && basket.id) {
                basketProduct
                    .serve("get", null, {filterAttribute: "cart_id", filterValue: basket.id})
                    .then(setProducts);
            } else {
                setProducts([]);
            }
        }, [basket]);
    
        //console.log(basket);
        return <BasketSideViewContext.Provider value={{basket, products}}>
            {children}
        </BasketSideViewContext.Provider>
    }
);


/**
 * @type {{
 *      basket: Basket,
 *      products: BasketProduct[]
 * }}
 */
const stuff = {basket: null, products: FormatListNumberedRtlOutlined};
const BasketSideViewContext = createContext(stuff);

const BasketSideViewRendererWrapper = inject(
    ServicesContext,
    ["basketSideView", "basketComponent"],
    ({element, onOperation, basketSideView, basketComponent}) => {
        const [discount, setDiscount] = useState(null);
        return <DiscountContext.Provider value={discount}>
            <Container maxWidth={false}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button 
                            fullWidth
                            onClick={() => basketSideView.refresh({})}
                            variant="outlined" 
                            color="secondary"
                        >
                            Rimuovi carrello attivo
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <BasketProductListRenderer
                            element={element}
                            onOperation={(a, b) => {
                                setDiscount(null);
                                onOperation(a, b);
                            }}
                            BasketRenderer={BasketSideViewRenderer} 
                            defaultShown={true}
                            onSetDiscount={v => {
                                element.discount = v;
                                setDiscount(v);
                            }}
                            onAfterSave={() => {
                                basketSideView.reload(true);
                                notify(basketComponent).of("refresh").withNull();
                            }}
                            innerList
                        />
                    </Grid>
                </Grid>
            </Container>
        </DiscountContext.Provider>;
    }
);

const MissingBasketRenderer = inject(
    servicesContext,
    ["windowService"],
    inject(
        componentsContext,
        ["Gate"],
        /**
         * @type {WindowService}
         */
        ({windowService, Gate}) => {
            const classes = useStyles();
            return  <Container maxWidth={false} style={{height: "100%"}}>
                <Gate
                    role="admin"
                    onAuthorized={() => <Box 
                        data-testid="basket-side-view-missing" 
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center"
                        flexDirection="column"
                        height="100%"
                    >
                        <Avatar className={classes.missingBasketAvatar}>
                            <ShoppingBasket className={classes.missingBasketAvatarIcon}/>
                        </Avatar>
                        <Typography 
                            component="p" 
                            variant="h2"
                            className={classes.missingBasketTexts}
                        >
                            Nessun carrello selezionato.
                        </Typography>
                        <Button 
                            type="button" 
                            variant="outlined" 
                            fullWidth 
                            color="primary" 
                            onClick={() => windowService.href("/basket")}
                        >
                            Vai ai carrelli
                        </Button>
                    </Box>}
                />
            </Container>;
        }
    )
);
/*
const Component = () => <BasketSideViewContext.Consumer>
    {({basket}) => wrap(basket).valid() ? <BasketRenderer basket={basket} /> : <MissingBasketRenderer />}
</BasketSideViewContext.Consumer>;*/

const Component = inject(
    componentsContext,
    ["BasketSideEditor"],
    ({BasketSideEditor}) => <BasketSideViewContext.Consumer>
        {({basket}) => wrap(basket).valid() ? <BasketSideEditor 
            filterAttribute="id"
            filterValue={basket.id}
            key={basket.hash}
        /> : <MissingBasketRenderer />}
    </BasketSideViewContext.Consumer>
);

export {Component as BasketSideView, BasketSideViewProvider, BasketSideViewContext, BasketSideViewRendererWrapper};