import React, {Fragment, useState} from "react";
import {ReactComponent, EntityDetailRendererProps} from "js-react-components";
import {Modal, Paper, Dialog, Box, Container} from "@material-ui/core";
import LoadingComponent from "../loading.component";

const Loader = () => <LoadingComponent label="Caricamento..." size="3em" />;
/**
 * @template Entity
 * @param {{
 *      Editor: ReactComponent<EntityDetailRendererProps<Entity>>,
 *      Trigger: ReactComponent<{onClick: function}>,
 *      onSave: (entity: Entity) => void
 * }} param0 
 */
const Component = ({
    Editor, Trigger, onSave, meta, EditorProps, element, alertService
}) => {
    const [state, setState] = useState({open: false, loading: false});

    const toggleLoading = () => setState(s => ({...s, loading: !s.loading}));
    const toggleOpen = () => setState(s => ({...s, open: !s.open}));
    const resetState = () => setState({open: false, loading: false});

    const onSaveOperation = stuff => {
        toggleLoading();
        onSave(stuff).then(resetState).catch(toggleLoading);
    };
    const {open, loading} = state;

    return <Fragment>
        <Trigger onClick={toggleOpen} />
        <Dialog open={open} onBackdropClick={resetState} maxWidth="lg">
            <Paper style={{padding: "1em 0", position: "relative"}}>
                {loading && <Box zIndex={99}><Loader /></Box>}
                <Container maxWidth={false}>
                    <Editor {...(EditorProps || {})} onSave={onSaveOperation} element={element || {}} />
                </Container>
            </Paper>
        </Dialog>
    </Fragment>;
};

export default Component;