import {EntityService, HttpOperation} from "js-generic-utilities";
import {render, EntityBaseComponentBuilder} from "js-react-components";
import BasketStatMeta, {
    BasketStat, 
    BasketStatIndices, 
    BasketStatAttributes, 
    BasketStatSorters
} from "../../domain/basket-stat";
import BasketStatCollectionRenderer from "./collection-renderers/basket-stat-collection-renderer.component";
import LoadingComponent from "../auxiliary/loading.component";
import { Fragment } from "react";
import { Box } from "@material-ui/core";

const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />

/**
 * @typedef {EntityBaseComponentBuilder<"refresh", BasketStat, "list", BasketStatIndices, BasketStatAttributes, BasketStatSorters>} BasketStatBaseComponentBuilder
 * @typedef {EntityService<HttpOperation>} BasketStatService
 * @param {{
 *      service: BasketStatService
 * }} param0 
 */
export default function makeBasketStatBaseComponent({service}) {
    /**
     * @type {BasketStatBaseComponentBuilder}
     */
    const builder = render(service);

    return builder
        .withMeta(BasketStatMeta)
        .withGrid({
            Collection: BasketStatCollectionRenderer,
            Entity: () => null
        })
        .withDefaultView("grid")
        .withComponents({Loader})
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Caricamento dati"
        })
        .compose(({collection, loader}) => <Box position="relative">
            {loader}
            {collection}
        </Box>);

}