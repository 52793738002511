import {ObserverPool, notify, observe, UpdateFunction, remove} from "js-generic-utilities";

/**
 * @typedef {ObserverPool<"show" | "hide", void>} AlertObserverPool
 */

export default class AlertService {

    /**
     * @type {AlertObserverPool}
     */
    _pool;

    static make() {
        /**
         * @type {AlertObserverPool}
         */
        let pool = new ObserverPool();
        return new AlertService(pool);
    }

    constructor(pool) {
        this._pool = pool;
    }

    /**
     * @param {{message: string, severity: "warning" | "success" | "error"}} stuff 
     */
    show(stuff) {
        notify(this._pool).of("show").with(stuff);
    }

    hide() {
        notify(this._pool).of("hide").withNull();
    }

    /**
     * @param {UpdateFunction<"show", void>} listener 
     */
    onShow(listener) {
        observe(this._pool).on("show").with(listener);
    }

    /**
     * @param {UpdateFunction<"hide", void>} listener 
     */
    onHide(listener) {
        observe(this._pool).on("hide").with(listener);
    }

    /**
     * @param {"show" | "hide"} event 
     * @param {UpdateFunction<"show" | "hide", void>} listener 
     */
    removeListener(event, listener) {
        remove(listener).from(this._pool).on(event).do();
    }
}