import React, {Fragment} from "react";
import {
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  Button,
  Avatar
} from "@material-ui/core";
import {Label} from "@material-ui/icons";
import {Attribute} from "../../../domain/attribute";
import {EntityRendererProps, ReactComponent} from "js-react-components";
import AttributeSearchContext from "../search/attribute-search.context";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold"
  },
  contentText: {
    textAlign: "center"
  }
}));

/**
 * @type {ReactComponent<EntityRendererProps<Attribute>>}
 */
const AttributeSearchListItem = ({ element, onOperation, baseId }) => {
  const classes = useStyles();
  baseId = baseId || "attribute-search-card";
  console.log(element);
  return (
    <AttributeSearchContext.Consumer>
      {({selected}) => <ListItem key={element.id}>
            <ListItemText 
                primary={element.name}
                secondary={`${element.valuesNumber || "-"} valori`}
            />
            <ListItemSecondaryAction>
                <Button 
                  variant="text" 
                  color="primary" 
                  onClick={() => onOperation("select", element)}
                  disabled={(selected && (selected.indexOf(element.id) !== -1)) || !element.valuesNumber}
                >
                    {element.valuesNumber ? "Seleziona" : "Attributo senza valori"}
                </Button>
            </ListItemSecondaryAction>
        </ListItem>}
    </AttributeSearchContext.Consumer>
  );
};

export default AttributeSearchListItem;
