import {handle, serve} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeBasketStatService({requestManager}) {
    const handlers = [
        handle("get").with({
            method: "GET", 
            path: (d, e) => `/stats/global`
        }).build(),
        handle("count").with({
            method: "GET", 
            path: (d, e) => `/stats/global/count`
        }).build()
    ];

    return serve(handlers).using(requestManager).build();
}