import React, { Fragment, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Button,
  CardActions,
  Avatar,
  ButtonGroup,
  Badge,
  Box,
  Chip,
  Container,
  CircularProgress,
  Tooltip,
  Link
} from "@material-ui/core";
import EntitySideMenu from "../../auxiliary/entity-side-menu.component";
import { MoreVert, AccountBox, Warning, Check } from "@material-ui/icons";
import {CollectionUtilityWrapper} from "js-generic-utilities";
import {Product, ProductWrapper} from "../../../domain/product";
import ProductCostMeta, {ProductCost} from "../../../domain/product-cost";
import {EntityDetailRendererProps, ReactComponent, inject} from "js-react-components";
import servicesContext from "../../../contexts/services.context";
import {BasketSideViewContext} from "../../../components/basket/side-view/basket-side-view.component";
import { BasketWrapper, wrap } from "../../../domain/basket";
import { imgPlaceholder } from "../../../constants";
import { round, shorten } from "../../../utils/misc-utils-functions";
import AsyncEntityLoader from "../../auxiliary/async-entity-loader/async-entity-loader.component";
import ProductCostPrinter from "./auxiliary/product-cost-printer.component";

const useStyles = makeStyles((theme) => ({
  subheader: {
    fontWeight: "bold"
  },
  title: {
    fontWeight: "bold"
  },
  imageWrapper: {
  },
  image: {
    display: "block",
    maxWidth: "calc(min(100%, 150px))",
    height: "150px",
    margin: "auto"
  },
  stock: {
    position: "absolute",
    zIndex: 2,
    right: 0,
    bottom: "0.5em"
  },
  cost: {
    position: "absolute",
    zIndex: 2,
    left: 0,
    top: "0.5em"
  },
  contentText: {
    textAlign: "center"
  }
}));

const wrapBasketProducts = prods => CollectionUtilityWrapper.wrap(prods, (a, b) => a.product_id === b.product_id);
const printBody = element => {
  switch(element.type) {
    case "simple":
      return "Prodotto semplice";
    case "variable":
      return `Prodotto variabile con ${element.variantsCount || "?"} varianti`;
    default:
      return `Variante`;
  }
}
const getAvatar = element => {
  switch(element.type) {
    case "simple":
      return "S";
    case "variant":
      return "V";
    default:
      return "B";
  }
}

/**
 * 
 * @param {{element: Product}} param0 
 */
const ProductCard = inject(
  servicesContext,
  ["windowService"],
  ({ windowService, element, onSelect, onDelete, onOperation }) => {
    const classes = useStyles();
    const [menuTarget, setMenuTarget] = useState(null);
    const wrapped = ProductWrapper.wrap(element);
    const variable = wrapped.is("variable");
    const basketable = wrapped.isBasketable();
    const Icon = (wrapped.isOver() || wrapped.isRiskyStock()) ? Warning : Check;
    const img = <img
      className={classes.image}
      src={(element.image && (element.image.url || element.image)) || imgPlaceholder}
      alt={element.name}
      title={element.name}
    />;
    const avatar = <Avatar>
      {getAvatar(element)}
    </Avatar>;
    return (
      <BasketSideViewContext.Consumer>
        {({basket, products}) => {
          const isInActiveBasket = products && wrapBasketProducts(products).has({product_id: element.id});
          const showBasketOpt = basketable && wrap(basket).valid();
          return <Fragment key={element.id}>
            <EntitySideMenu
              baseId={`product-card-${element.id}-side-menu`}
              key={`${element.id}-side-menu`}
              title={element.name}
              MenuProps={{
                anchorEl: menuTarget,
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "top"
                }
              }}
              onClose={() => setMenuTarget(null)}
              open={Boolean(menuTarget)}
              operations={[
                { label: "Visualizza", onClick: () => onSelect(element) },
                { label: "Cancella", onClick: () => onOperation("delete", element) }
              ]
              .concat(showBasketOpt && basketable ? [
                { label: "Aggiungi al carrello", onClick: () => onOperation("addToActiveCart", {cart_id: basket.id, product_id: element.id, quantity: 1})}
              ] : [])
              .concat(wrapped.is("variable") ? [
                {label: "Aggiungi variante", onClick: () => onOperation("addVariant", element)}
              ] : [])}
            />
            <Card data-testid={`product-card-${element.id}`} key={`${element.id}-card`}>
              <CardHeader
                avatar={
                  <Tooltip title={printBody(element)}>
                    {wrapped.isOnWoocommerce() ? <Badge badgeContent="W" color="primary">
                      {avatar}
                    </Badge> : avatar}
                  </Tooltip>
                }
                title={shorten(element.name, 60)}
                titleTypographyProps={{
                  variant: "h5",
                  style: {color: "rgb(97, 97, 97)", fontWeight: "bold"},
                  "data-testid": `product-card-${element.id}-name-text`
                }}
                subheader={printBody(element)}
                subheaderTypographyProps={{
                  variant: "body1",
                  "data-testid": `product-card-${element.id}-stock-text`
                }}
                action={
                  <IconButton 
                    data-testid={`product-card-${element.id}-menu-trigger`} 
                    onClick={(evt) => setMenuTarget(evt.target)}
                  >
                    <MoreVert />
                  </IconButton>
                }
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box position="relative">
                      {img}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Container maxWidth={false} disableGutters>
                      <Grid container spacing={1} justify="center">
                          <Grid item>
                            <Chip 
                              label={<Typography 
                                component="span" 
                                variant="body1"
                              >
                                {element.stock} in stock
                              </Typography>} 
                              style={{
                                backgroundColor: wrapped.getStockColor(),
                                color: "white"
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Chip
                              label={<Typography 
                                component="span" 
                                variant="body2"
                              >
                                Da {round(wrapped.minCost(), 2)} &euro;
                              </Typography>}
                              color="primary"
                            />
                          </Grid>
                        </Grid>
                    </Container>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActionArea>
                <CardActions>
                  <ButtonGroup fullWidth>
                    {/*<Button
                      key="edit"
                      variant="text"
                      color="primary"
                      onClick={() => windowService.href(`/product/${element.id}`)}
                      data-testid={`product-card-${element.id}-edit-button`}
                    >
                      Visualizza
                    </Button>*/}
                    <Button
                        key="fast_edit"
                        variant="text"
                        color="primary"
                        data-testid={`product-card-${element.id}-fast-edit-button`}
                        onClick={() => onSelect(element)}
                      >
                        Mod. rapida
                    </Button>
                    {showBasketOpt && basketable && <Button
                        key="cart"
                        variant="text"
                        color="primary"
                        onClick={() => onOperation("addToActiveCart", {cart_id: basket.id, product_id: element.id, quantity: 1})}
                        data-testid={`product-card-${element.id}-cart-button`}
                        disabled={isInActiveBasket}
                      >
                        <Badge 
                          color={isInActiveBasket ? "primary" : undefined} 
                          badgeContent={isInActiveBasket && wrapBasketProducts(products).get({product_id: element.id}).stock}
                        >
                          Carrello
                        </Badge>
                      </Button>}
                    {variable && <Button
                        key="variants"
                        variant="text"
                        color="primary"
                        onClick={() => windowService.href(`/variable_product/${element.id}/variants`)}
                        data-testid={`product-card-${element.id}-variants-button`}
                      >
                        <Badge badgeContent={element.variantsCount} color="primary">
                          Varianti
                        </Badge>
                      </Button> }
                  </ButtonGroup>
                </CardActions>
              </CardActionArea>
            </Card>
          </Fragment>;
        }}
      </BasketSideViewContext.Consumer>
    );
  }
);

export default ProductCard;
