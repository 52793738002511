const data = [
    
    /** most sold base products */
    {name: "top_variable", label: "Product1", value: 11},
    {name: "top_variable", label: "Product2", value: 9},
    {name: "top_variable", label: "Product3", value: 21},
    {name: "top_variable", label: "Product4", value: 12},

    /** most sold variants */
    {name: "top_variant", label: "Variant1", value: 11},
    {name: "top_variant", label: "Variant2", value: 9},
    {name: "top_variant", label: "Variant3", value: 21},
    {name: "top_variant", label: "Variant4", value: 12},

    /** top customers */
    {name: "top_customer", label: "Customer1", value: 222},
    {name: "top_customer", label: "Customer2", value: 333},
    {name: "top_customer", label: "Customer3", value: 444},
    {name: "top_customer", label: "Customer4", value: 555},

    /** sales */
    {name: "sales", month: "09-2020", value: 1122},
    {name: "sales", month: "10-2020", value: 1900},
    {name: "sales", month: "11-2020", value: 2100},
    {name: "sales", month: "12-2020", value: 1200},
];

export default data;