import {
    Add, Edit, Close, GridOn, Search, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowLeft,
    RemoveCircle, ExpandLess, ArrowLeft, ArrowRight, MoreVert, DeleteForever, Refresh, FirstPage, LastPage,
    KeyboardArrowRightOutlined, SearchOutlined, KeyboardArrowUp, List as ListIcon, ArrowRightAlt, ChevronLeft, ChevronRight
} from "@material-ui/icons";

import { 
    IconButton, Button, Typography, Menu, TextField, CircularProgress, InputAdornment, List, Fade,
    FormControlLabel, FormLabel, Box, Paper, Divider, ListItem, Grid, Checkbox, ButtonGroup, MenuItem,
    ListItemText, ListItemSecondaryAction, ListItemIcon, Dialog, DialogActions, DialogContent, DialogTitle,
    Table, TableRow, TableCell, TableHead, TableBody, FormControl, Tooltip
} from "@material-ui/core";
import {Component} from "react";
import {Link, BrowserRouter, Route, Redirect, Switch} from "react-router-dom";

const Components = {
    Table,
    TableRow, 
    TableCell, 
    TableHead,
    TableBody,
    Tooltip,
    View: ({style, className, children, onClick, testID}) => <div style={style} className={className} onClick={onClick} testID={testID}>{children}</div>,
    Loader: () => <CircularProgress />,
    TextField: TextField,
    ListItem: ListItem,
    MenuItem: MenuItem,
    Dialog: Dialog,
    DialogActions: DialogActions,
    DialogContent: DialogContent,
    DialogTitle: DialogTitle,
    List: List,
    ListItemText: ListItemText,
    ListItemSecondaryAction: ListItemSecondaryAction,
    ListItemIcon: ListItemIcon,
    ButtonGroup: ButtonGroup,
    Typography: Typography,
    Grid: Grid,
    Checkbox: Checkbox,
    InputAdornment: InputAdornment,
    FormControlLabel: FormControlLabel,
    FormLabel: FormLabel,
    Box: Box,
    Divider: Divider,
    Paper: Paper,
    Menu: Menu,
    FormControl: FormControl,
    IconButton: (props) => <IconButton 
        {...props}
        onClick={props.onClick || props.onPress}
    >
        {props.icon || props.children}
    </IconButton>,
    Text: (props) => {
        const {className, variant, component, style, children, testID} = props;
        return <Typography 
            testID={testID}
            className={className} 
            variant={variant || "body2"} 
            component={component || "span"} 
            data-testid={props["data-testid"]}
            style={style}
        >{children}</Typography>;
    },
    Icon: ({name, style, className}) => {
        switch(name) {
            case "keyboard-arrow-left":
            case "arrow-left":
            case "pagination-left":
                return <ChevronLeft style={style} className={className} />;
            case "keyboard-arrow-right":
            case "arrow-right":
            case "pagination-right":
                return <ChevronRight style={style} className={className} />;
            case "pagination-first":
                return <FirstPage style={style} className={className} />;
            case "pagination-last":
                return <LastPage style={style} className={className} />;
            case "keyboard-arrow-down":
            case "arrow-down":
                return <KeyboardArrowDown style={style} className={className} />;
            case "search":
                return <Search />;
            case "more-vert":
                return <MoreVert />;
            case "edit":
                return <Edit />;
            case "delete":
                return <DeleteForever />;
            case "refresh":
                return <Refresh />;
            /*case "pages":
                return <PageviewSharp />;*/
            default:
                return null;
        }
    },
    Button: (props) => <Button {...props} startIcon={props.icon} onClick={props.onClick || props.onPress}>
        {props.label || props.title || props.children}
    </Button>,
    Picker: class extends Component {
        static Item = (props) => <option {...props}>{props.children}</option>
        render() {
            const {selectedValue, onValueChange, children, label, disabled} = this.props;
            return <TextField
                select
                label={label}
                variant="outlined"
                style={{backgroundColor: "white", paddingLeft: 5, borderRadius: 5}}
                defaultValue={selectedValue} 
                onChange={(e) => onValueChange(e.target.value)}
                data-testid={this.props["data-testid"]}
                disabled={disabled}
            >
                {children}
            </TextField>
        }
    },
    Link: (props) => <Link style={props.style} to={props.to} className={props.className}>
        <Typography component="span" variant="subtitle1">{props.children}</Typography>
    </Link>,
    Router: BrowserRouter,
    Route: Route,
    Redirect: Redirect,
    Switch: Switch,
    Overlay: Menu,
    Fade: Fade,
    Icons: {
        Add: Add,
        Edit: Edit,
        Close: Close,
        GridOn: GridOn,
        List: ListIcon,
        Search: Search,
        RemoveCircle: RemoveCircle,
        ExpandLess: ExpandLess,
        KeyboardArrowLeft: KeyboardArrowLeft,
        KeyboardArrowRight: KeyboardArrowRight,
        KeyboardArrowRightOutlined: KeyboardArrowRightOutlined,
        SearchOutlined: SearchOutlined,
        KeyboardArrowUp: KeyboardArrowUp,
        KeyboardArrowDown: KeyboardArrowDown
    },
    withStyles: (stuff) => {
        return function(Component) {
            return Component;
        }
    },
    KeyboardArrowLeft: KeyboardArrowLeft,
    KeyboardArrowRight: KeyboardArrowRight
};  

export default Components;