import React, { Component, useCallback } from "react";
import { EntityRendererProps, inject } from "js-react-components";
import { Customer } from "../../../domain/customer";
import servicesContext from "../../../contexts/services.context";
import {
    ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, ButtonGroup, Button, Avatar
} from "@material-ui/core";
import {
    Person
} from "@material-ui/icons";

/**
 * @param {EntityRendererProps<Customer>} param0 
 */
function CustomerListRenderer({ element, onSelect, onDelete, onOperation, selectOnly, deleteDialogService, windowService }) {
    
    const {id, name, code} = element;
    const onClickSelect = useCallback(() => onSelect(element), [element, onSelect]);
    const onClickDelete = useCallback(
        () => deleteDialogService.show({
            message: `Confermare la cancellazione del cliente ${element.name}?`,
            onConfirm: () => onDelete(element)
        }), 
        [element, onDelete, deleteDialogService]
    );
    const onShowBaskets = useCallback(() => onOperation("baskets", element), [element, onOperation]);
    
    return <ListItem key={id}>
        <ListItemAvatar>
            <Avatar>
                <Person />
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary={name} secondary={`Codice ${code}`} />
        <ListItemSecondaryAction>
            <ButtonGroup>
                <Button key="Modifica" type="button" variant="text" color="primary" onClick={onClickSelect}>
                    Modifica
                </Button>
                <Button key="Cancella" type="button" variant="text" color="secondary" onClick={onClickDelete}>
                    Cancella
                </Button>
                <Button key="carrelli" type="button" variant="text" color="primary" onClick={onShowBaskets}>
                    Mostra carrelli
                </Button>
            </ButtonGroup>
        </ListItemSecondaryAction>
    </ListItem>;
}

export default inject(
    servicesContext,
    ["deleteDialogService", "windowService"],
    CustomerListRenderer
)