import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeProductStatService({requestManager}) {
    const handlers = [
        handle("get").with({
            method: "GET", 
            path: (d, e) => (e.filterAttribute === "product") && e.filterValue ? `/entity/${e.filterValue}/stats` : `/product_stat`
        }).build(),
        handle("count").with(
            {method: "GET", 
            path: (d, e) => (e.filterAttribute === "product") && e.filterValue ? `/entity/${e.filterValue}/stats/count` : `/product_stat/count`
        }).build()
    ];

    return serve(handlers).using(requestManager).build();
}