import {Container, Grid, Box, withStyles, Avatar, Typography} from "@material-ui/core";
import clsx from "clsx";

const Component = withStyles({
    avatar: {
        height: "250px",
        width: "250px",
        opacity: 0.5
    },
    icon: {
        height: "65%",
        width: "65%"
    },
    text: {
        textAlign: "center",
        color: "grey"
    },
    bold: {
        fontWeight: "bold"
    }
})(
    ({classes, Icon, title, body, avatarSize}) => {
        return <Container maxWidth={false} disableGutters style={{margin: "2em 0"}}>
            <Grid container spacing={3} justify="center">
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <Avatar className={classes.avatar} style={avatarSize ? {height: `${avatarSize}px`, width: `${avatarSize}px`} : {}}>
                            <Icon className={classes.icon} />
                        </Avatar>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="p" variant="h3" className={clsx([classes.text, classes.bold])}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="p" variant="body1" className={classes.text}>
                        {body}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    }
);

export function wrapRenderer (Renderer, {title, body, avatarSize, Icon}) {
    return (props) => props.elements && props.elements.length ? <Renderer {...props} /> : <Component
        title={title} body={body} avatarSize={avatarSize} Icon={Icon}
    />;
}

export default Component;