import React from "react";
import { Grid, withStyles, Box, Container, Divider, Typography, Fab, Button } from "@material-ui/core";
import {Add} from "@material-ui/icons";

const InlineTrigger = withStyles({
    fab: {
        position: "sticky",
        right: "1em",
        bottom: "1em",
        zIndex: 99,
        minWidth: "75px"
    }
})(
    ({onClick, classes}) => <Button
        fullWidth
        color="primary" 
        variant="contained"
        onClick={onClick} 
        className={classes.fab}
        data-testid="entity-add-fab"
    >
    Aggiungi
</Button>
);

const FabTrigger = withStyles({
    fullHeight: {
        height: "100%"
    }
})(
    ({onClick, classes}) => <Fab 
        variant="round"
        onClick={onClick}
        color="primary"
    >
        <Add />
    </Fab>
)

export {InlineTrigger, FabTrigger};