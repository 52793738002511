import {
    attributeValues,
    attributes,
    productCategories,
    productImages,
    products,
    customers,
    images,
    categories,
    variableProducts,
    productVariants,
    baskets,
    basketProducts,
    productQuantityAlterations,
    productStats,
    basketStats,
    productCosts
} from "./fake-data";

import {Attribute} from "../domain/attribute";
import {Product} from "../domain/product";
import {AttributeValue} from "../domain/attribute-value";
import {rand} from "../utils/misc-utils-functions";

let vProdRegex = new RegExp(/^(\/variable_product\/)([0-9]{1,})$/);
let catRegex = new RegExp(/^(\/category-type\/1\/category\/)([0-9]{1,})$/);
let custRegex = new RegExp(/^(\/customer\/)([0-9]{1,})$/);
let imgRegex = new RegExp(/^(\/image\/)([0-9]{1,})$/);
let bRegex = new RegExp(/^(\/cart\/)([0-9]{1,})$/);
let bStatRegex = new RegExp(/^(\/basket\/stat)((\/count){0,1})$/);
let bProdVarGenRegex = new RegExp(/^(\/variable_product\/)([0-9]{1,})(\/variant_generation)$/);
let bProdRegex = new RegExp(/^(\/cart\/)[0-9]{1,}(\/product)((\/count)|(\/[0-9]{1,})){0,1}$/);
let pCatRegex = new RegExp(/^(\/product\/)[0-9]{1,}(\/category)((\/count)|(\/[0-9]{1,})){0,1}$/);
let pQtyRegex = new RegExp(/^(\/product\/)[0-9]{1,}(\/quantity_alteration)((\/count)|(\/[0-9]{1,})){0,1}$/);
let pImgRegex = new RegExp(/^(\/product\/)[0-9]{1,}(\/image)((\/count)|(\/[0-9]{1,})){0,1}$/);
let pVarRegex = new RegExp(/^((\/variable_product\/)|(\/product\/))[0-9]{1,}(\/variant)((\/count)|(\/[0-9]{1,})){0,1}$/);
let aValRegex = new RegExp(/^(\/variant_feature\/)[0-9]{1,}(\/value)((\/count)|(\/[0-9]{1,})){0,1}$/);
let pStatRegex = new RegExp(/^(\/product\/)[0-9]{1,}(\/stat)((\/count)|(\/[0-9]{1,})){0,1}$/);
let pCostRegex = new RegExp(/^(\/product\/)[0-9]{1,}(\/cost)((\/count)|(\/[0-9]{1,})){0,1}$/);

const getDeleteData = (url) => {
    let a = url.split("/");
    if(a.length === 5) {
        let out = {};
        out[`${a[1]}_id`] = parseInt(a[2]);
        out[`${a[3]}_id`] = parseInt(a[4]);
        return out;
    } else {
        return {id: parseInt(a[2])};
    }
}

/**
 * 
 * @param {number} pid 
 * @param {Attribute[]} attributes 
 */
const generateProductVariants = (pid, attributes) => {
    return products.onGet({filterAttribute: "id", filterValue: pid}).then(p => {
        /**
         * @type {Promise<{attribute: Attribute, values: AttributeValue[]}>[]}
         */
        const promises = [];
        attributes.forEach(attribute => {
            promises.push(
                attributeValues
                    .onGet({filterAttribute: "attribute_id", filterValue: attribute.id})
                    .then(values => Promise.resolve({values, attribute}))
            )
        });
        Promise.all(promises).then((valArrs) => {
            
        });
    });
}

/**
 * @param {any} data 
 * @param {number} status 
 * @param {number} delay
 */
function makeReply(data, status, delay) {
    delay = delay || 50;
    status = status || 200;
    let out = {status};
    if(status < 400) {
        out.data = data;
    } else {
        out.message = data;
    }
    return delay ? (new Promise((res, rej) => {
        setTimeout(() => res(out), delay);
    })) : Promise.resolve(out);
}

function getSubId(url) {
    return parseInt(url.split("/")[2]);
}

/**
 * 
 * @param {string} url 
 * @param {string} newUrl 
 */
function processUrl(url, newUrl) {
    if(url.indexOf("count") !== -1) {
        return `${newUrl}/count`;
    }
    return newUrl;
}

/**
 * @return {[string, any]}
 * @param {string} url 
 * @param {any} args 
 */
function processArgs(url, args) {
    if(pCatRegex.test(url)) {
        return [
            processUrl(url, "/product_category"), 
            {filterAttribute: "product_id", filterValue: getSubId(url), ...(args || {})}
        ]
    }
    if(pImgRegex.test(url)) {
        return [
            processUrl(url, "/product_image"), 
            {filterAttribute: "product_id", filterValue: getSubId(url), ...(args || {})}
        ]
    }
    if(pQtyRegex.test(url)) {
        return [
            processUrl(url, "/product_quantity_alteration"), 
            {filterAttribute: "product_id", filterValue: getSubId(url), ...(args || {})}
        ]
    }
    if(bProdVarGenRegex.test(url)) {
        return [
            processUrl(url, "/variant_generation"), 
            {product_id: getSubId(url)}
        ]
    }
    if(bProdRegex.test(url)) {
        return [
            processUrl(url, "/basket_product"), 
            {filterAttribute: "cart_id", filterValue: getSubId(url), ...(args || {})}
        ]
    }
    if(pStatRegex.test(url)) {
        return [
            processUrl(url, "/product_stat"), 
            {filterAttribute: "product_id", filterValue: getSubId(url), ...(args || {})}
        ]
    }
    if(pCostRegex.test(url)) {
        return [
            processUrl(url, "/product_cost"), 
            {filterAttribute: "product_id", filterValue: getSubId(url), ...(args || {})}
        ]
    }
    if(imgRegex.test(url)) {
        return [
            processUrl(url, "/image"), 
            {}
        ]
    }
    if(bRegex.test(url)) {
        return [
            processUrl(url, "/cart"), 
            {}
        ]
    }
    if(bStatRegex.test(url)) {
        return [
            processUrl(url, "/basket_stat"), 
            {}
        ]
    }
    if(catRegex.test(url)) {
        return [
            processUrl(url, "/category"), 
            {}
        ]
    }
    if(custRegex.test(url)) {
        return [
            processUrl(url, "/customer"), 
            {}
        ]
    }
    if(pVarRegex.test(url)) {
        return [
            processUrl(url, "/product_variant"), 
            {filterAttribute: "variable_product_id", filterValue: getSubId(url), ...(args || {})}
        ]
    }
    if(aValRegex.test(url)) {
        return [
            processUrl(url, "/attribute_value"), 
            {filterAttribute: "attribute_id", filterValue: getSubId(url), ...(args || {})}
        ]
    }
    if(vProdRegex.test(url)) {
        return [processUrl(url, "/variable_product"), {}]
    }
    return [url, args || {}];
}

function checkError(data, op) {
    if(data.name === "exists") {
        return Promise.reject("element_exists");
    } else {
        return op();
    }
}

export default class FakeRequestManager {

    get(url, args) {
        console.log(`url = ${url}, args = ${JSON.stringify(args)}`);
        const [pUrl, pArgs] = processArgs(url, args);
        console.log(`processed get url = ${pUrl}, processed args = ${JSON.stringify(pArgs)}`);
        switch(pUrl) {

            /* fetch */
            case "/variant_feature":
                return makeReply(attributes.onGet(pArgs));
            case "/variant_feature_value":
                return makeReply(attributeValues.onGet(pArgs));
            case "/category-type/1/category":
                return makeReply(categories.onGet(pArgs));
            case "/attribute_value":
                return makeReply(attributeValues.onGet(pArgs));
            case "/variable_product":
                return makeReply(variableProducts.onGet(pArgs));
            case "/product":
                return makeReply(products.onGet(pArgs));
            case "/product_stat":
                return makeReply(productStats.onGet(pArgs));
            case "/product_cost":
                return makeReply(productCosts.onGet(pArgs));
            case "/customer":
                return makeReply(customers.onGet(pArgs));
            case "/cart":
                return makeReply(baskets.onGet(pArgs));
            case "/basket_stat":
                return makeReply(basketStats.onGet(pArgs));
            case "/image":
                return makeReply(images.onGet(pArgs));
            case "/product_category":
                return makeReply(productCategories.onGet(pArgs));
            case "/product_image":
                return makeReply(productImages.onGet(pArgs));
            case "/product_variant":
                return makeReply(productVariants.onGet(pArgs));
            case "/product_quantity_alteration":
                return makeReply(productQuantityAlterations.onGet(pArgs));
            case "/basket_product":
                return makeReply(basketProducts.onGet(pArgs));

            /* count */
            case "/variant_feature/count":
                return makeReply(attributes.onCount(pArgs));
            case "/variant_feature_value/count":
                return makeReply(attributeValues.onCount(pArgs));
            case "/customer/count":
                return makeReply(customers.onCount(pArgs));
            case "/image/count":
                return makeReply(images.onCount(pArgs));
            case "/cart/count":
                return makeReply(baskets.onCount(pArgs));
            case "/category-type/1/category/count":
                return makeReply(categories.onCount(pArgs));
            case "/product_cost/count":
                return makeReply(productCosts.onCount(pArgs));
            case "/basket_stat/count":
                return makeReply(basketStats.onCount(pArgs));
            case "/product/count":
                return makeReply(products.onCount(pArgs));
            case "/variable_product/count":
                return makeReply(variableProducts.onCount(pArgs));
            case "/attribute_value/count":
                return makeReply(attributeValues.onCount(pArgs));
            case "/product_category/count":
                return makeReply(productCategories.onCount(pArgs));
            case "/product_image/count":
                return makeReply(productImages.onCount(pArgs));
            case "/product_quantity_alteration/count":
                return makeReply(productQuantityAlterations.onCount(pArgs));
            case "/product_variant/count":
                return makeReply(productVariants.onCount(pArgs));
            case "/basket_product/count":
                return makeReply(basketProducts.onCount(pArgs));

            default:
                return makeReply("shitfucks", 404);
        }
    }

    post(url, data, params) {
        console.log(`url = ${url}, data = ${JSON.stringify(data)}`);
        const [pUrl, pArgs] = processArgs(url);
        console.log(`processed post url = ${pUrl}`);
        data = {...data};
        if(typeof(data.id) !== "number") {
            data.id = Math.floor(Math.random()*9999999+1);
        }
        switch(pUrl) {
            case "/variant_generation":
                return generateProductVariants(pArgs.product_id, data.attributes);
            case "/variant_feature":
                return checkError(data, () => makeReply(attributes.onSave(data)));
            case "/customer":
                return checkError(data, () => makeReply(customers.onSave(data)));
            case "/cart":
                return makeReply(baskets.onSave(data));
            case "/basket_product":
                return makeReply(basketProducts.onSave(data));
            case "/attribute_value":
                return makeReply(attributeValues.onSave(data));
            case "/category-type/1/category":
                return checkError(data, () => makeReply(categories.onSave(data)));
            case "/image":
                return checkError(data, () => makeReply(images.onSave(data)));
            case "/product_category":
                let out = makeReply(productCategories.onSave(data));
                this.get(url).then(data => data.stuff).then(stuff => console.log(stuff));
                return out;
            case "/product_image":
                return makeReply(productImages.onSave(data));
            case "/product_cost":
                return makeReply(productCosts.onSave(data));
            case "/product_variant":
                products.onSave(data);
                return makeReply(productVariants.onSave(data));
            case "/variable_product":
                return checkError(data, () => {
                    products.onSave(data);
                    return makeReply(variableProducts.onSave(data));
                });
            case "/product_quantity_alteration":
                return makeReply(productQuantityAlterations.onSave(data));
            default:
                return makeReply("fuckshits", 404);
        }
    }

    delete(url, args) {
        console.log(`url = ${url} with data = ${JSON.stringify(args)}`); 
        const [pUrl, pArgs] = processArgs(url);
        let data = getDeleteData(url);
        let jf;
        console.log(`processed delete url = ${pUrl} with data = ${JSON.stringify(data)}`); 
        switch(pUrl) {
            case "/variable_product":
                return makeReply(variableProducts.onDelete(data));
            case "/product_stat":
                return makeReply(productStats.onDelete(data));
            case "/product":
                return makeReply(products.onDelete(data));
            case "/customer":
                return makeReply(customers.onDelete(data));
            case "/cart":
                return makeReply(baskets.onDelete(data));
            case "/basket_product":
                return makeReply(basketProducts.onDelete(data));
            case "/product_category":
                return makeReply(productCategories.onDelete(data));
            case "/product_quantity_alteration":
                return makeReply(productQuantityAlterations.onDelete(data));
            case "/product_variant":
                jf = url.split("/");
                data = {id: parseInt(jf[4])};
                products.onDelete(data);
                return makeReply(productVariants.onDelete(data));
            case "/product_image":
                return makeReply(productImages.onDelete(data));
            case "/product_cost":
                return makeReply(productCosts.onDelete(data));
            case "/image":
                return makeReply(images.onDelete(data));
            case "/category":
                jf = url.split("/");
                data = {id: parseInt(jf[4])};
                return makeReply(categories.onDelete(data));
            case "/variant_feature":
                return makeReply(attributes.onDelete(data));
            case "/variant_feature_value":
                return makeReply(attributeValues.onDelete(data));
            default:
                return makeReply("fuckshits", 404);
        }
    }
}