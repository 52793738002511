import React, {createContext, useState, useEffect, ReactChild} from "react";
import HorizontalCollapse from "../../auxiliary/horizontal-collapse.component";
import {Basket, wrap} from "../../../domain/basket";
import {
    Box, List, Grid, Typography, Divider, 
    Container, Button, withStyles, CircularProgress, 
    Avatar, makeStyles, ButtonGroup
} from "@material-ui/core";
import ComponentsContext from "../../../contexts/components.context";
import { inject } from "js-react-components";
import {ObserverPool, notify} from "js-generic-utilities";
import {ShoppingBasket, FormatListNumberedRtlOutlined} from "@material-ui/icons";
import BasketProductListRenderer from "../entity-renderer/basket-product-list-renderer.component";
import BasketSideViewService from "./basket-side-view.service";
import EntityValuePrinter from "../../auxiliary/entity-value-printer.component";
import AsyncEntityLoader from "../../auxiliary/async-entity-loader/async-entity-loader.component";
import ServicesContext from "../../../contexts/services.context";
import servicesContext from "../../../contexts/services.context";
import WindowService from "../../../services/auxiliary/window-service";
import { round } from "../../../utils/misc-utils-functions";


/**
 * @param {{basket: Basket}} param0 
 */
const BasketSideViewRenderer = withStyles({
    title: {
        color: "rgb(111, 111, 111)"
    }
})(
    inject(
        ServicesContext,
        ["customer", "basketSideView"],
        ({basket, onOperation, onSetShown, classes, customer, basketSideView}) => {
            const labelProps = {variant: "h4", className: ""};
            return <Container maxWidth={false} disableGutters>
                <Grid container spacing={3}>
                    <Grid item container xs={12} justify="space-between" alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography component="header" variant="h4" className={classes.title}>Dati</Typography>
                        </Grid>
                        <Grid item>
                            <ButtonGroup>
                                <Button 
                                    type="button" 
                                    variant="outlined" 
                                    color="primary"
                                    onClick={() => basketSideView.refresh({})}
                                >
                                    Rimuovi
                                </Button>
                                <Button 
                                    type="button" 
                                    variant="outlined" 
                                    color="secondary"
                                    onClick={() => onOperation("close", basket)}
                                >
                                    Chiudi
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={6}>
                                <EntityValuePrinter label="Data" value={basket.date} LabelProps={labelProps} />
                            </Grid>
                            <Grid item xs={6}>
                                <AsyncEntityLoader
                                    id={basket.customer_id}
                                    LoadingComponent={CircularProgress}
                                    onGet={i => customer.serve("get", null, {filterAttribute: "id", filterValue: i})}
                                    Renderer={({entity}) => entity && <EntityValuePrinter label="Cliente" value={entity.name} LabelProps={labelProps} />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <EntityValuePrinter label="Stato" value={basket.status} LabelProps={labelProps} />
                            </Grid>
                            <Grid item xs={6}>
                                <EntityValuePrinter label="Costo" value={round(basket.cost, 3)} LabelProps={labelProps} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="header" variant="h4" className={classes.title}>Prodotti</Typography>
                    </Grid>
                </Grid>
            </Container>;
        }
    )
);

export default BasketSideViewRenderer;