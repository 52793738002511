/**
 * @type {{
 *      category: string,
 *      attribute: string,
 *      attributeValue: string,
 *      product: string,
 *      variableProduct: string,
 *      productVariant: string,
 *      basket: string,
 *      image: string,
 *      customer: string,
 *      alert: string
 * }}
 */
const endpoints = {
    category: "/category-type/1/category",
    attribute: "/variant-attribute",
    attributeValue: "/attribute_value",
    product: "/product",
    variableProduct: "/product",
    productVariant: "/product_variant",
    basket: "/cart",
    image: "/image",
    alert: "/user/activity",
    customer: "/customer"
};

export default endpoints;