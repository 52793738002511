export default class DocumentService {

    /**
     * @type {Document}
     */
    _document;

    constructor(document) {
        this._document = document;
    }

    /**
     * @param {string} id 
     * @return {HTMLElement}
     */
    id(id) {
        return this._document.getElementById(id);
    }
}